import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Mobileheader from '../components/Mobileheader';
import axios from 'axios';

import { Outlet } from 'react-router-dom';

export default function MainLayout() {
  const [user, setUser] = useState({});
  let userData = '';

  // 현재 주소 체크
  const currentUrl = window.location.href;

  userData = {
    mb_id: 'hansi',
    mb_name: 'Localhost | 한시관리자',
    mb_level: '2',
    type: 'hansi',
    mb_2: '연구',
  };

  const checkUser = async () => {
    try {
      if (process.env.REACT_APP_SERVER_URL !== 'http://localhost:8080') {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/users/login_check`,
          {
            withCredentials: true,
          },
        );

        userData = response.data.user ?? {
          mb_id: 'hansi',
          mb_name: 'Localhost | 한시관리자',
          mb_level: '2',
          type: 'hansi',
        };

        const { type, mb_level } = userData;

        console.log(mb_level);

        if (type !== 'hansi') {
          alert('접근 권한이 없습니다.');
          window.location = process.env.REACT_APP_HANSI_URL;
          return;
        }

        if (mb_level !== '10') {
          alert('관리자만 접근 가능합니다.');
          window.location = process.env.REACT_APP_HANSI_URL;
          return;
        }
      }

      setUser(userData);
    } catch (error) {
      console.log(error);
      alert('로그인이 필요합니다.');
      window.location = process.env.REACT_APP_HANSI_URL;
    }
  };

  // 주소 변경시 체크
  useEffect(() => {
    checkUser();
  }, [currentUrl]);

  return (
    <>
      <header>
        <Header userName={user.mb_name}></Header>
        <Mobileheader></Mobileheader>
      </header>
      <div className='container'>
        <Outlet context={user} />
      </div>
    </>
  );
}

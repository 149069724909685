import React from 'react';
import { useLocation } from 'react-router-dom';
import '../styles/page.scss';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1,
  );
  const location = useLocation();
  // 한 번에 보여줄 페이지 버튼 개수 설정
  const pageSize = 9;
  const startIndex = Math.max(currentPage - Math.floor(pageSize / 2), 1);
  const endIndex = Math.min(startIndex + pageSize - 1, totalPages);

  const visiblePageNumbers = pageNumbers.slice(startIndex - 1, endIndex);

  const handlePageChange = (pageNumber) => {
    onPageChange(pageNumber);
    const url = `${location.pathname}?page=${pageNumber}`;
    window.history.pushState({ path: url }, '', url);
    window.scrollTo({ top: 0 });
  };

  return (
    <div className='pagination'>
      {currentPage > 5 && (
        <button
          onClick={() => handlePageChange(1)}
          className={
            currentPage === 1 ? 'first page-btn disable' : 'first page-btn'
          }
        >
          First
        </button>
      )}
      {visiblePageNumbers.length > 1 &&
        visiblePageNumbers.map((pageNumber) => (
          <button
            key={pageNumber}
            onClick={() => handlePageChange(pageNumber)}
            className={
              pageNumber === currentPage ? 'page-btn active' : 'page-btn'
            }
          >
            {pageNumber}
          </button>
        ))}
      {totalPages - 4 > currentPage && (
        <button
          onClick={() => handlePageChange(totalPages)}
          className={
            currentPage === totalPages
              ? 'last page-btn disable'
              : 'last page-btn'
          }
        >
          Last
        </button>
      )}
    </div>
  );
};

export default Pagination;

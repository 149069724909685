const SmBookComponent = ({ info, setInfo }) => {
  const bkObj = [
    { name: "SM_Books_Publish", label: "출판사명" },
    { name: "SM_Books_Name", label: "단행본명" },
  ];

  return (
    <div className="division">
      {/* 단행본 항목을 map 함수를 이용하여 반복 출력 */}
      {bkObj.map((item, index) => (
        <div key={index} className="data-box">
          <label>{item.label}</label>
          <input
            type="text"
            className="data-in-box"
            value={info[item.name]}
            onChange={(e) => {
              setInfo({
                ...info,
                [item.name]: e.target.value,
              });
            }}
          />
        </div>
      ))}
      {/* 반복 출력 종료 */}
    </div>
  );
};

export default SmBookComponent;

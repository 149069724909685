import { useState, useEffect } from 'react';
import Detailbtn from '../../components/search/search_debtn';
import axios from 'axios';
import DetailSearchResult from '../../components/search/detail_result';
import BubsearchMenu from '../../components/search/menu';
import Loading from '../../components/default/Loading';
import { useLocation, useOutletContext } from 'react-router-dom';

const Bubdetailsearch = () => {
  const user = useOutletContext();
  const [searchText, setSearchText] = useState('');
  const [data, setData] = useState(null); // 받아온 데이터를 저장할 상태
  const [yjData, setyjData] = useState(null);
  const [currentId, setCurrentId] = useState('');
  const [episodeId, setEpisodeId] = useState('');
  const [chapterId, setChapterId] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [currentCate, setCurrentCate] = useState('');
  const [yjId, setyjId] = useState(null);
  const [episodeData, setEpisodeData] = useState([]);
  const [chapterData, setChapterData] = useState([]);

  const itemsPerPage = 10;

  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const [checkLocation, setCheckLocation] = useState(false);

  const [keyWord, setKeyWord] = useState('');

  console.log(keyWord);

  useEffect(() => {
    const regex = /^\/search/;

    const match = location.pathname.match(regex);
    if (match) {
      setCheckLocation(true);
    } else {
      setCheckLocation(false);
    }
  }, [location]);

  useEffect(() => {
    // 스크롤 이벤트 리스너 등록
    const handleScroll = () => {
      const scrollY = window.scrollY;

      // 스크롤 위치를 부모 창으로 전달
      window.parent.postMessage({ type: 'scroll', scrollY }, '*');
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setLoading(true);
    if (currentCate && currentId) {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/bub/search/detail?cate=${currentCate}&${currentId}`,
        )
        .then((res) => {
          const key = currentId.split('_')[0];
          let dataArray = []; // Declare and initialize the dataArray variable

          if (key === 'subtitle') {
            dataArray = res.data.yj_para_list;
          } else if (key === 'chapter') {
            dataArray = res.data.yj_subtitle_list;
            const yjdataArray = res.data.yj_para_list;
            const subdataArray = res.data.yj_subtitle_list;
            setyjData(yjdataArray);
            setEpisodeId(res.data.yj_subtitle_list[0].episode_id);
            setChapterId(res.data.yj_subtitle_list[0].chapter_id);
            setyjId(subdataArray[0].id);
          } else if (key === 'title') {
            dataArray = res.data.hgg_para_list;
          }
          setData(dataArray);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/bub/yj/episode`)
      .then((response) => {
        const episodeData = response.data.yjEpiList.filter(
          (episode) => episode.use_yn !== 'N',
        ); // 첫 번째 요소에 있는 카테고리 정보
        setEpisodeData(episodeData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });

    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/bub/yj/chapter`)
      .then((response) => {
        const chapterData = response.data.yjChapterList.filter(
          (chapter) => chapter.use_yn !== 'N',
        ); // 첫 번째 요소에 있는 카테고리 정보
        setChapterData(chapterData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
      });
  }, [currentCate, currentId]);

  const handleCateChange = (searchCategory, searchId) => {
    setCurrentCate(searchCategory);
    setCurrentId(searchId);
    setSearchText('');
    setCurrentPage(1);
  };

  // 카테고리 변경 시 이벤트 처리
  const cateChange = (cateData) => {
    setCurrentCate(cateData);
    setSearchText('');
    setCurrentPage(1);
  };

  const searchChange = (searchValue) => {
    setCurrentId(searchValue);
    setSearchText('');
    setCurrentPage(1);
  };

  // 현재 페이지에 해당하는 데이터 계산
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentData = data ? data.slice(indexOfFirstItem, indexOfLastItem) : [];
  const yjCurrentData = yjData
    ? yjData.slice(indexOfFirstItem, indexOfLastItem)
    : [];

  //console.log(currentData);

  // 페이지 변경 시 이벤트 처리
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // 페이지 번호 목록 생성
  const pageNumbers = data
    ? Array.from(
        { length: Math.ceil(data.length / itemsPerPage) },
        (_, index) => index + 1,
      )
    : [];

  const yjpageNumbers = yjData
    ? Array.from(
        { length: Math.ceil(yjData.length / itemsPerPage) },
        (_, index) => index + 1,
      )
    : [];

  const matchedEpisode = episodeData.find(
    (episode) => episode.id === episodeId,
  );
  const episodeName = matchedEpisode ? matchedEpisode.episode_shortName : '';

  const matchedChapter = chapterData.find(
    (chapter) => chapter.id === chapterId,
  );
  const chapterName = matchedChapter ? matchedChapter.chapter : '';

  const yjSubview = (id) => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/bub/search/detail?cate=${currentCate}&subtitle_id=${id}`,
      )
      .then((res) => {
        const yjdataArray = res.data.yj_para_list;
        setyjData(yjdataArray);
        setyjId(id);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleSearch = () => {
    const key = currentId.split('_')[0];
    if (key === 'subtitle' || key === 'title') {
      axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/bub/search?cate=${currentCate}&${currentId}`,
          {
            search: searchText,
          },
        )
        .then((res) => {
          let dataArray = []; // Declare and initialize the dataArray variable

          if (key === 'subtitle') {
            dataArray = res.data.yj_para_list;
            setData(dataArray);
            //console.log(res.data);
          } else if (key === 'title') {
            dataArray = res.data.hgg_para_list;
            setData(dataArray);
            //console.log(res.data);
          }
          setKeyWord(searchText ? searchText : '');
          setLoading(false);
        })
        .catch((err) => {
          if (err.response.status === 400) {
            alert('검색어를 입력해주세요.');
          }
          setLoading(false);
        });
    } else {
      axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/bub/search?cate=${currentCate}&subtitle_id=${yjId}&${currentId}`,
          { search: searchText },
        )
        .then((res) => {
          const yjdataArray = res.data.yj_para_list;
          setyjData(yjdataArray);
          setLoading(false);
          setKeyWord(searchText ? searchText : '');
        })
        .catch((err) => {
          if (err.response.status === 400) {
            alert('검색어를 입력해주세요.');
          }
          setLoading(false);
        });
    }
  };

  return (
    <>
      {loading ? <Loading /> : null}
      <div className='search'>
        <div className='search-inner'>
          {checkLocation ? null : (
            <div>
              <p className='page-title'>법문검색</p>
              <BubsearchMenu />
            </div>
          )}
          <div
            className={`search-box bub-detail-search-wrap ${
              checkLocation ? 'delete-border' : ''
            }`}
          >
            <div className='search-inner-box'>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSearch(); // 검색 버튼 클릭 시 검색 요청 보내기
                }}
              >
                <div className='input-wrap'>
                  <div className='search-box__input'>
                    <input
                      type='text'
                      value={searchText}
                      placeholder='무엇을 찾고 계세요?'
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                  <div className='search-box__button'>
                    <button type='submit'>검색</button>
                  </div>
                </div>
              </form>

              <Detailbtn
                detailChange={handleCateChange}
                oncateChange={cateChange}
                onSearchChange={searchChange}
              />
            </div>

            <p className='comment bottom-search'>
              ※ 검색 단어로 여러 단어 입력이 가능합니다. 예: 법문 법회
            </p>
          </div>
          {data && data.length > 0 && (
            <div>
              {data && data[0].episode_id && (
                <div>
                  <div className='bub_result_header'>
                    <h3 className='category-label'>
                      {episodeName}편{' '}
                      <span className='ep-title'>{chapterName}</span>
                      <span className='comment'>※ 아래 항목을 선택하세요.</span>
                    </h3>
                    <div className='bub-select-btns'>
                      {data.map((item, index) => (
                        <button
                          key={index}
                          className={`select-btn${
                            index === 0 ? ' select-btn-active' : ''
                          }`}
                          onClick={(e) => {
                            yjSubview(item.id);
                            setSearchText('');
                            setCurrentPage(1);

                            document
                              .querySelectorAll('.select-btn')
                              .forEach((btn) =>
                                btn.classList.remove('select-btn-active'),
                              );
                            e.currentTarget.classList.add('select-btn-active');
                          }}
                        >
                          {item.subtitle}
                        </button>
                      ))}
                    </div>
                  </div>
                  {yjData && yjData.length > 0 && (
                    <div className='bub_result_content'>
                      <div className='result-box'>
                        {yjData && yjData[0].subtitle && (
                          <h3 className='bub-subtitle'>
                            {yjData[0].subtitle
                              ? yjData[0].subtitle
                              : yjData[0].title}
                          </h3>
                        )}
                        <DetailSearchResult
                          searchList={yjCurrentData}
                          user_id={user.mb_no}
                          type='yj'
                          marker={keyWord}
                        />
                      </div>
                    </div>
                  )}
                  {yjData.length === 0 && <p>데이터가 없습니다.</p>}
                </div>
              )}
              {data && !data[0].episode_id && (
                <div className='result-box'>
                  <h3 className='bub-subtitle'>
                    {data[0].subtitle ? data[0].subtitle : data[0].title}
                  </h3>
                  <DetailSearchResult
                    searchList={currentData}
                    user_id={user.mb_no}
                    type='bub'
                    marker={keyWord}
                  />
                </div>
              )}
            </div>
          )}
          {data && data.length === 0 && <p>데이터가 없습니다.</p>}
          {/* 페이지 번호 목록 출력 */}
          <div className='pagination'>
            {data &&
              !yjData &&
              pageNumbers.map((pageNumber) => (
                <button
                  key={pageNumber}
                  onClick={() => handlePageChange(pageNumber)}
                  className={pageNumber === currentPage ? 'active' : ''}
                >
                  {pageNumber}
                </button>
              ))}

            {yjData &&
              yjpageNumbers.map((pageNumber) => (
                <button
                  key={pageNumber}
                  onClick={() => handlePageChange(pageNumber)}
                  className={pageNumber === currentPage ? 'active' : ''}
                >
                  {pageNumber}
                </button>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Bubdetailsearch;

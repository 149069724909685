import React, { useState } from 'react';

const TextWithToggle = ({ originalText, page }) => {
  if (!originalText) {
    return null;
  }

  // '^' 문자를 기준으로 분리된 배열을 상태로 관리
  const textParts = originalText.split('^');
  // 현재 펼침/접힘 상태를 나타내는 상태
  const [isExpanded, setIsExpanded] = useState(true);

  // 버튼 클릭 시 펼치기/접기 상태를 토글
  const handleToggleClick = () => {
    setIsExpanded(!isExpanded);
  };

  if (textParts.length > 1) {
    return (
      <div>
        <div>
          {isExpanded ? textParts.slice(0, -1).join('^') : ''}
          {isExpanded ? '' : textParts[0] + textParts[1]}
        </div>
        <div>
          <button onClick={handleToggleClick}>
            {isExpanded ? '▼' : '▲ : P.' + (Number(page) + 1)}
          </button>
        </div>
      </div>
    );
  }

  return originalText; // 혹은 다른 처리를 할 수 있습니다.
};

export default TextWithToggle;

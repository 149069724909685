const SmJournalComponent = ({ info, setInfo }) => {
  return (
    <>
      <div className='data-box chked-box'>
        <div className='chk-box'>
          <label htmlFor='area_1' className='checkbox'>
            <input
              type='radio'
              name='area'
              id='area_1'
              value='국내'
              checked={info.SM_Journal_Area === '국내'}
              onChange={(e) => {
                setInfo({
                  ...info,
                  SM_Journal_Area: e.target.value,
                });
              }}
            />
            <i></i>
            <span>국내</span>
          </label>
        </div>
        <div className='chk-box'>
          <label htmlFor='area_2' className='checkbox'>
            <input
              type='radio'
              name='area'
              id='area_2'
              value='국외'
              checked={info.SM_Journal_Area === '국외'}
              onChange={(e) => {
                setInfo({
                  ...info,
                  SM_Journal_Area: e.target.value,
                });
              }}
            />
            <i></i>
            <span>국외</span>
          </label>
        </div>
      </div>
      <div className='division'>
        <div className='data-box'>
          <label>학술지명</label>
          <input
            type='text'
            className='data-in-box'
            value={info.SM_Journal_Name}
            onChange={(e) => {
              setInfo({
                ...info,
                SM_Journal_Name: e.target.value,
              });
            }}
          />
        </div>
        <div className='data-box'>
          <label>학술지 권 번호</label>
          <input
            type='text'
            className='data-in-box'
            value={info.SM_Journal_Volume}
            onChange={(e) => {
              setInfo({
                ...info,
                SM_Journal_Volume: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className='division'>
        <div className='data-box'>
          <label>학술지 호 번호</label>
          <input
            type='text'
            className='data-in-box'
            value={info.SM_Journal_Issue}
            onChange={(e) => {
              setInfo({
                ...info,
                SM_Journal_Issue: e.target.value,
              });
            }}
          />
        </div>
        <div className='data-box'>
          <label>발행 연구기관명</label>
          <input
            type='text'
            className='data-in-box'
            value={info.SM_Journal_Research_Institution}
            onChange={(e) => {
              setInfo({
                ...info,
                SM_Journal_Research_Institution: e.target.value,
              });
            }}
          />
        </div>
      </div>
    </>
  );
};

export default SmJournalComponent;

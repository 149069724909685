const SmThesisComponent = ({ info, setInfo }) => {
  return (
    <>
      <div className='data-box chked-box'>
        <div className='chk-box'>
          <label htmlFor='area_1' className='checkbox'>
            <input
              type='radio'
              name='area'
              id='area_1'
              value='국내'
              checked={info.SM_Thesis_Area === '국내'}
              onChange={(e) => {
                setInfo({
                  ...info,
                  SM_Thesis_Area: e.target.value,
                });
              }}
              {...(info.SM_Thesis_Area === '국내'
                ? { defaultChecked: true }
                : {})}
            />
            <i />
            <span>국내</span>
          </label>
        </div>
        <div className='chk-box'>
          <label htmlFor='area_2' className='checkbox'>
            <input
              type='radio'
              name='area'
              id='area_2'
              value='해외'
              checked={info.SM_Thesis_Area === '해외'}
              onChange={(e) => {
                setInfo({
                  ...info,
                  SM_Thesis_Area: e.target.value,
                });
              }}
              {...(info.SM_Thesis_Area === '해외'
                ? { defaultChecked: true }
                : {})}
            />
            <i />
            <span>해외</span>
          </label>
        </div>
      </div>
      <div className='division'>
        <div className='data-box'>
          <label>학위</label>
          <input
            type='text'
            className='data-in-box'
            value={info.SM_Thesis_Degree}
            onChange={(e) => {
              setInfo({
                ...info,
                SM_Thesis_Degree: e.target.value,
              });
            }}
          />
        </div>
        <div className='data-box'>
          <label>논문이 제출된 대학</label>
          <input
            type='text'
            className='data-in-box'
            value={info.SM_Thesis_University}
            onChange={(e) => {
              setInfo({
                ...info,
                SM_Thesis_University: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className='data-box'>
        <label>논문 링크</label>
        <input
          type='text'
          className='data-in-box'
          value={info.SM_1}
          onChange={(e) => {
            setInfo({
              ...info,
              SM_1: e.target.value,
            });
          }}
        />
      </div>
    </>
  );
};

export default SmThesisComponent;

import axios from 'axios';
import { useState, useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';

import BubsearchMenu from '../../components/search/menu';

const SutrasListView = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const location = useLocation();
  const [checkLocation, setCheckLocation] = useState(false);

  useEffect(() => {
    const regex = /^\/search/;

    const match = location.pathname.match(regex);
    if (match) {
      setCheckLocation(true);
    } else {
      setCheckLocation(false);
    }
  }, [location]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/bub/sutras/detail/${id}`)
      .then((res) => {
        const dataArray = res.data;
        const sutrasUser = dataArray.sutrasCopying;
        setData(sutrasUser);
        console.log(dataArray);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // console.log(data)

  const sutrasDelete = (e) => {
    e.preventDefault();
    axios
      .delete(`${process.env.REACT_APP_SERVER_URL}/bub/sutras/${id}`)
      .then((res) => {
        console.log(res);
        alert('삭제되었습니다.');
        window.location.href = '/sutras';
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className='search sutras'>
      <div className='search-inner'>
        {checkLocation ? null : (
          <div>
            <p className='page-title'>법문검색</p>
            <BubsearchMenu />
          </div>
        )}
        <div className='sutras-table-wrap'>
          <div>등록날짜 : {data.createAt}</div>
          <div>법문단락 : {data.contents}</div>
          <div>사경후 올라온 맘 : {data.feeling}</div>
        </div>
        <div className='sutras_btn'>
          <Link to={`/sutras_edit/${id}`} className='btn btn-191'>
            수정하기
          </Link>
          <button className='btn btn-191' onClick={(e) => sutrasDelete(e)}>
            삭제하기
          </button>
          <Link to='/sutras' className='btn btn-191'>
            목록으로
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SutrasListView;

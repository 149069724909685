import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { Outlet } from 'react-router-dom';

const CheckUsers = () => {
  const [user, setUser] = useState({});
  let userData = '';

  // 현재 주소 체크
  const currentUrl = window.location.href;

  userData = {
    mb_id: 'hansi',
    mb_name: 'Localhost | 한시관리자',
    mb_level: '2',
    type: 'hansi',
    mb_2: '연구',
  };

  const checkUser = async () => {
    try {
      if (process.env.REACT_APP_SERVER_URL !== 'http://localhost:8080') {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/users/login_check`,
          {
            withCredentials: true,
          },
        );

        userData = response.data.user ?? {
          mb_id: 'hansi',
          mb_name: 'Localhost | 한시관리자',
          mb_level: '2',
          type: 'hansi',
        };

        const { type } = userData;

        if (type !== 'hansi') {
          alert('접근 권한이 없습니다.');
          window.location = process.env.REACT_APP_HANSI_URL;
          return;
        }
      }

      setUser(userData);
    } catch (error) {
      console.log(error);
      alert('로그인이 필요합니다.');
      window.location = process.env.REACT_APP_HANSI_URL;
    }
  };

  // 주소 변경시 체크
  useEffect(() => {
    checkUser();
  }, [currentUrl]);

  return (
    <>
      <Outlet context={user} />
    </>
  );
};

export default CheckUsers;

import React from 'react';
import Smitem from './sm_item';

const SmSearchResult = ({ searchList, currentPage, categoryMapping }) => {

  return (
    <div className="item-result">
        {currentPage === '' ? (
    searchList.list.slice(0, 3).map((searchData, index) => (
        <Smitem key={index} sm={searchData} categoryMapping={categoryMapping} />
    ))
) : (
    searchList.list.map((searchData, index) => (
        <Smitem key={index} sm={searchData} categoryMapping={categoryMapping} />
    ))
)}
    </div>
  );
};

export default SmSearchResult;

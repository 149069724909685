import React, { useState } from 'react';

const SmModalJournal = ({ onSubmit }) => {
  let body = document.querySelector('body');
  const [formData, setFormData] = useState({
    SM_Journal_Area: '',
    SM_Journal_Name: '',
    first_opt: 'or',
    SM_Journal_Volume: '',
    second_opt: 'or',
    SM_Journal_Issue: '',
    third_opt: 'or',
    SM_Journal_Research_Institution: '',
    fourth_opt: 'or',
  });

  const {
    SM_Journal_Area,
    SM_Journal_Name,
    first_opt,
    SM_Journal_Volume,
    second_opt,
    SM_Journal_Issue,
    third_opt,
    SM_Journal_Research_Institution,
    fourth_opt,
  } = formData;

  const onChange = (e) => {
    if (e.target.name !== '') {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
    body.classList.remove('on');
  };

  // 초기화 버튼 실행시 리셋
  const onReset = (e) => {
    e.preventDefault();
    setFormData({
      SM_Journal_Area: '',
      SM_Journal_Name: '',
      first_opt: 'or',
      SM_Journal_Volume: '',
      second_opt: 'or',
      SM_Journal_Issue: '',
      third_opt: 'or',
      SM_Journal_Research_Institution: '',
      fourth_opt: 'or',
    });
  };

  return (
    <React.Fragment>
      <div className='sm-input-form-wrap'>
        <div className='search-wrap'>
          <div className='data-box chked-box'>
            <div className='chk-box'>
              <label htmlFor='area_1' className='checkbox'>
                <input
                  type='radio'
                  id='area_1'
                  name='SM_Journal_Area'
                  value='국내'
                  onChange={onChange}
                />
                <i></i>
                <span>국내</span>
              </label>
            </div>
            <div className='chk-box'>
              <label htmlFor='area_2' className='checkbox'>
                <input
                  type='radio'
                  id='area_2'
                  name='SM_Journal_Area'
                  value='해외'
                  onChange={onChange}
                />
                <i></i>
                <span>국외</span>
              </label>
            </div>
          </div>
          <div className='select-box'>
            <select value={first_opt} name='first_opt' onChange={onChange}>
              <option value='or'>or</option>
              <option value='and'>and</option>
            </select>
          </div>
        </div>
        <div className='divide-box'>
          <div className='search-wrap'>
            <div className='input-value-box'>
              <span className='label'>이름</span>
              <div className='input-box'>
                <input
                  type='text'
                  value={SM_Journal_Name}
                  name='SM_Journal_Name'
                  onChange={onChange}
                />
              </div>
            </div>
            <div className='select-box'>
              <select value={second_opt} name='second_opt' onChange={onChange}>
                <option value='or'>or</option>
                <option value='and'>and</option>
              </select>
            </div>
          </div>
          <div className='search-wrap'>
            <div className='input-value-box'>
              <span className='label'>권 번호</span>
              <div className='input-box'>
                <input
                  type='text'
                  value={SM_Journal_Volume}
                  name='SM_Journal_Volume'
                  onChange={onChange}
                />
              </div>
            </div>
            <div className='select-box'>
              <select value={third_opt} name='third_opt' onChange={onChange}>
                <option value='or'>or</option>
                <option value='and'>and</option>
              </select>
            </div>
          </div>
        </div>
        <div className='divide-box'>
          <div className='search-wrap'>
            <div className='input-value-box'>
              <span className='label'>호 번호</span>
              <div className='input-box'>
                <input
                  type='text'
                  value={SM_Journal_Issue}
                  name='SM_Journal_Issue'
                  onChange={onChange}
                />
              </div>
            </div>
            <div className='select-box'>
              <select value={fourth_opt} name='fourth_opt' onChange={onChange}>
                <option value='or'>or</option>
                <option value='and'>and</option>
              </select>
            </div>
          </div>
          <div className='search-wrap'>
            <div className='input-value-box'>
              <span className='label'>연구기관</span>
              <div className='input-box'>
                <input
                  type='text'
                  value={SM_Journal_Research_Institution}
                  name='SM_Journal_Research_Institution'
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='box-wrap'>
        <div className='btns-inner'>
          <button onClick={onReset} className='btns wh-border'>
            초기화
          </button>
          <button
            type='submit'
            className='btns search-orange'
            onClick={handleSubmit}
          >
            검색하기
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SmModalJournal;

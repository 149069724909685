import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import Pagination from "../../components/Pagination";

const SmconferenceList = () => {
  const [data, setData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  // URL에서 페이지 번호 파라미터를 추출
  const queryParams = new URLSearchParams(window.location.search);
  const currentPage = Number(queryParams.get('page')) || 1;

  const itemsPerPage = 10;

  const fetchData = (pageNumber) => {
    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/sm/conference?page=${pageNumber}`
      ) // API 엔드포인트 URL
      .then((response) => {
        const dataArray = response.data;
        const smConference = dataArray.conference_list;
        const dataCount = dataArray.conference_count[0];
        setData(smConference);
        setTotalItems(dataCount.count);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    fetchData(currentPage);
  }, [currentPage]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // 페이지 변경 시 이벤트 처리
  const handlePageChange = (pageNumber) => {
    // URL을 업데이트하여 페이지 번호를 변경
    window.location.search = `?page=${pageNumber}`;
  };

  // 페이지 번호 목록 생성
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const handleDeleteSelected = () => {
    const selectedIds = selectedItems.map((item) => item.SM_Document_Id);
    if (window.confirm("정말로 삭제하시겠습니까?")) {
      Promise.all(
        selectedIds.map((item) => {
          axios.delete(`${process.env.REACT_APP_SERVER_URL}/sm/delete/${item}`);
        })
      )
        .then((responses) => {
          console.log("선택 삭제 성공:", responses);

          setSelectedItems([]);

          // 데이터를 다시 불러옴
          axios
            .get(`${process.env.REACT_APP_SERVER_URL}/sm/conference`)
            .then((response) => {
              const dataArray = response.data;
              const smConference = dataArray.conference_list;
              setData(smConference);
              window.location.reload();
            })
            .catch((error) => {
              console.error("Error fetching data:", error);
            });
        })
        .catch((error) => {
          console.error("선택 삭제 실패:", error);
        });
    }
  };

  const handleCheckboxChange = (item) => {
    // 선택된 항목의 상태를 토글합니다.
    setSelectedItems((prevSelected) => {
      const isSelected = prevSelected.some(
        (selectedItem) =>
          selectedItem.SM_Conference_Id === item.SM_Conference_Id
      );
      // console.log(prevSelected)
      if (isSelected) {
        // 이미 선택된 항목이라면 제거합니다.
        return prevSelected.filter(
          (selectedItem) =>
            selectedItem.SM_Conference_Id !== item.SM_Conference_Id
        );
      } else {
        // 선택되지 않은 항목이라면 추가합니다.
        return [...prevSelected, item];
      }
    });
  };
console.log(data)

  return (
    <div className="content">
      <div className="logo-text">한마음과학원</div>
      <div className="category-titles">
        <span>논문관리</span>
        <span className="status-title">학술대회자료집 관리</span>
      </div>
      <div className="table-wrap category paper">
        <div className="tbl thead">
          <ul>
            <li className="chk-box"></li>
            {/* <th>ID</th> */}
            <li className="tb_category">문서의 유형</li>
            <li className="tb_category">문서의 제목</li>
            <li className="tb_category">저자</li>
            <li className="tb_category">발행일자</li>
            <li className="tb_category">학회명</li>
            <li className="tb_category">학회 장소</li>
            <li className="tb_category">개최일자</li>
            <li className="set">관리</li>
          </ul>
        </div>
        <div className="tbl tbody">
          {data.map((item) => (
            <ul key={item.SM_Conference_Id}>
              <li className="chk-box">
                <label className="checkbox">
                  <input
                    type="checkbox"
                    checked={selectedItems.some(
                      (selectedItem) =>
                        selectedItem.SM_Conference_Id === item.SM_Conference_Id
                    )}
                    onChange={() => handleCheckboxChange(item)}
                  />
                  <i></i>
                </label>
              </li>
              {/* <td>{item.id}</td> */}
              <li className="paper-cate-title">
                <p>{item.SM_Cate_Name}</p>
              </li>
              <li className="">
                <p>{item.SM_Title}</p>
              </li>
              <li className="">
                <p>{item.SM_Author}</p>
              </li>
              <li className="">
                <p>{item.SM_CreateAt}</p>
              </li>
              <li className="">
                <p>{item.SM_Conference_Name}</p>
              </li>
              <li className="">
                <p>{item.SM_Conference_Location}</p>
              </li>
              <li className="">
                <p>{item.SM_Conference_Date}</p>
              </li>
              <li className="set">
                <Link
                  to={`/sm_conference_view/${item.SM_Conference_Id}`}
                  className="detail-btn"
                >
                  상세보기
                </Link>
              </li>
            </ul>
          ))}
        </div>
      </div>
      <div className="button_wrap">
        <button onClick={handleDeleteSelected} className="delete-btn btn wh">
          삭제하기
        </button>
        <Link to="/sm_write/conference" className="btn create-btn m-c-c-b">
          <span>등록하기</span>
        </Link>
      </div>

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default SmconferenceList;

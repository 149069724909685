import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
//import Print from './Print';

const Print = ({ printRef, print, title }) => {
  //const { printRef } = printRef;
  return (
    <div ref={printRef} className='print'>
      <div className='print-inner'>
        <p className='print-title'>{print?.title || print.subtitle}</p>
        <div className='print-opts'>
          <p className='cate-title'>{title}</p>
          {print.bdate ? (
            <p className='dday'>
              <span className='label'>발행날짜 : </span>
              <span>{print.bdate}</span>
            </p>
          ) : null}
        </div>
        <p className='print-paragraph'>{print.paragraph}</p>
      </div>
    </div>
  );
};

const ScrapView = ({ item, openDetail, closeDetail, cate }) => {
  const location = useLocation();
  const [checkLocation, setCheckLocation] = useState(false);
  const [smData, setSMdata] = useState(null);
  const [getData, setGetData] = useState({});

  useEffect(() => {
    const regex = /^\/search/;

    const match = location.pathname.match(regex);
    if (match) {
      setCheckLocation(true);
    } else {
      setCheckLocation(false);
    }
  }, [location]);

  const myRef = useRef(null);

  const onClickEvent = () => {
    handlePrint();
  };

  /* print */
  const handlePrint = useReactToPrint({
    content: () => myRef.current,
  });

  useEffect(() => {
    if (item[0]?.SM_Books_Id) {
      let books_id = item[0].SM_Books_Id;

      fetch(`${process.env.REACT_APP_SERVER_URL}/sm/books/${books_id}`)
        .then((response) => response.json())
        .then((data) => {
          setSMdata(data.books[0]);
        })
        .catch((error) => {
          console.log('등록 실패:', error);
        });
    } else if (item[0]?.SM_Conference_Id) {
      let conference_id = item[0].SM_Conference_Id;

      fetch(
        `${process.env.REACT_APP_SERVER_URL}/sm/conference/${conference_id}`,
      )
        .then((response) => response.json())
        .then((data) => {
          setSMdata(data.conference[0]);
        })
        .catch((error) => {
          console.log('등록 실패:', error);
        });
    } else if (item[0]?.SM_Journal_Id) {
      let journal_id = item[0].SM_Journal_Id;

      fetch(`${process.env.REACT_APP_SERVER_URL}/sm/journal/${journal_id}`)
        .then((response) => response.json())
        .then((data) => {
          setSMdata(data.journal[0]);
        })
        .catch((error) => {
          console.log('등록 실패:', error);
        });
    } else if (item[0]?.SM_Thesis_Id) {
      let thesis_id = item[0].SM_Thesis_Id;

      fetch(`${process.env.REACT_APP_SERVER_URL}/sm/thesis/${thesis_id}`)
        .then((response) => response.json())
        .then((data) => {
          setSMdata(data.thesis[0]);
        })
        .catch((error) => {
          console.log('등록 실패:', error);
        });
    }
  }, [item]);

  useEffect(() => {
    setGetData(() => {
      if (smData) {
        return {
          ...smData,
          Tags: smData.Tags ? smData.Tags.split(',') : [],
        };
      } else {
        return {}; // or whatever default value you want when smData is null or undefined
      }
    });
  }, [smData]);

  const handleDownload = (e) => {
    e.preventDefault();

    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/sm/download/${smData.SM_Document_Id}`,
        {
          responseType: 'json',
        },
      )
      .then((response) => {
        const { fileData } = response.data;
        const dataUrl = 'data:application/pdf;base64,' + fileData;
        const a = document.createElement('a');
        a.href = dataUrl;
        a.download = getData.SM_Attachment_Source;
        a.click();
      })
      .catch((error) => {
        console.error('파일 다운로드 중 오류 발생:', error);
      });
  };

  if (!openDetail) return null;

  const title_span = item[0]?.category_name
    ? `[${item[0].category_name} Vol.${item[0].title_vol} P.${item[0].paragraph_page}]`
    : `${item[0].navigation}`;

  //console.log(item[0]);

  return (
    <>
      {cate === 'yj' ? (
        <div className='modal'>
          <div className='detail_view'>
            <div className='detail_view__content detail'>
              <div className='detail_view__content__header fordetail-header'>
                <h3>{item[0]?.title || item[0].subtitle}</h3>
                <ul className='header-info-list'>
                  <li>
                    <span>{title_span}</span>
                  </li>
                  {item[0].bdate ? (
                    <li className='day'>
                      <span>발행날짜</span>
                      <span>{item[0].bdate}</span>
                    </li>
                  ) : null}
                </ul>
                <a onClick={onClickEvent} className='print-btn'>
                  인쇄하기
                </a>
                <Print printRef={myRef} print={item[0]} title={title_span} />

                <button onClick={closeDetail} className='close-btn'>
                  X
                </button>
              </div>
              <div className='detail_view__content__body fordetail-body'>
                <ul className='detail-text'>
                  <li>
                    <p>{item[0].paragraph}</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className={`${
              checkLocation
                ? 'detail_view__overlay_search'
                : 'detail_view__overlay'
            }`}
            onClick={closeDetail}
          ></div>
        </div>
      ) : cate === 'hgg' ? (
        <div className='modal'>
          <div className='detail_view'>
            <div className='detail_view__content detail'>
              <div className='detail_view__content__header fordetail-header'>
                <h3>{item[0]?.title || item[0].subtitle}</h3>
                <ul className='header-info-list'>
                  {item[0].bdate ? (
                    <li className='day'>
                      <span>발행날짜</span>
                      <span>{item[0].bdate}</span>
                    </li>
                  ) : null}
                </ul>
                <a onClick={onClickEvent} className='print-btn'>
                  인쇄하기
                </a>
                <Print printRef={myRef} print={item[0]} title={title_span} />

                <button onClick={closeDetail} className='close-btn'>
                  X
                </button>
              </div>
              <div className='detail_view__content__body fordetail-body'>
                <ul className='detail-text'>
                  <li>
                    <p>{item[0].paragraph}</p>
                    <br />
                    <span>
                      [{item[0].category_name} Vol.{item[0].title_vol} P.
                      {item[0].paragraph_page}]
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className={`${
              checkLocation
                ? 'detail_view__overlay_search'
                : 'detail_view__overlay'
            }`}
            onClick={closeDetail}
          ></div>
        </div>
      ) : (
        <div className='modal'>
          <div className='detail_view'>
            <div className='detail_view__content detail'>
              <div className='detail_view__content__header fordetail-header'>
                <h3 className='title-name'>{item[0].SM_Title}</h3>
                <ul className='header-info-list'>
                  <li className='day'>
                    <span>발행년월</span>
                    <span>
                      {item[0].SM_Publication_YY}.{item[0].SM_Publication_MM}
                    </span>
                  </li>
                  <li className='day'>
                    <span>저자</span>
                    <span>{item[0].SM_Author}</span>
                  </li>
                </ul>
                {item[0].SM_Books_Id ? (
                  <ul className='header-info-list'>
                    <li className='day'>
                      <span>출판사명</span>
                      <span>{smData.SM_Books_Publish}</span>
                    </li>
                    <li className='day'>
                      <span>단행본명</span>
                      <span>{smData.SM_Books_Name}</span>
                    </li>
                  </ul>
                ) : smData.SM_Cate_Name === '학술대회자료집' ? (
                  <ul className='header-info-list'>
                    <li className='day'>
                      <span>학회</span>
                      <span>{smData.SM_Conference_Name}</span>
                    </li>
                    <li className='day'>
                      <span>장소</span>
                      <span>{smData.SM_Conference_Location}</span>
                    </li>
                    <li className='day'>
                      <span>개최일자</span>
                      <span>{smData.SM_Conference_Date}</span>
                    </li>
                  </ul>
                ) : smData.SM_Cate_Name === '학술지' ? (
                  <ul className='header-info-list'>
                    <li className='day'>
                      <span>학술지명</span>
                      <span>{smData.SM_Journal_Name}</span>
                    </li>
                    <li className='day'>
                      <span>권</span>
                      <span>{smData.SM_Journal_Volume}</span>
                    </li>
                    <li className='day'>
                      <span>호</span>
                      <span>{smData.SM_Journal_Issue}</span>
                    </li>
                    <li className='day'>
                      <span>연구기관</span>
                      <span>{smData.SM_Journal_Research_Institution}</span>
                    </li>
                  </ul>
                ) : smData.SM_Cate_Name === '학위논문' ? (
                  <ul className='header-info-list'>
                    <li className='day'>
                      <span>학위</span>
                      <span>{smData.SM_Thesis_Degree}</span>
                    </li>
                    <li className='day'>
                      <span>대학</span>
                      <span>{smData.SM_Thesis_University}</span>
                    </li>
                    <li className='day'>
                      <span>링크</span>
                      <span>{smData.SM_1}</span>
                    </li>
                  </ul>
                ) : null}
                <button onClick={closeDetail} className='close-btn'>
                  X
                </button>
              </div>
              <div className='detail_view__content__body fordetail-body'>
                <ul className='detail-text'>
                  <li>
                    <p>{smData.SM_Abstract}</p>
                  </li>
                  <li className='label-content-wrap'>
                    <span className='label'>주제어</span>
                    <div className='in-data tags'>
                      {smData.Tags && Array.isArray(smData.Tags)
                        ? smData.Tags.map((item, index) => (
                            <span key={index} className='tag'>
                              {item}
                            </span>
                          ))
                        : null}
                    </div>
                  </li>
                  {smData.SM_Attachment_Source ? (
                    <>
                      (
                      <li className='label-content-wrap'>
                        <span className='label preview'>미리보기</span>
                        <div className='in-data pdf' onClick={handleDownload}>
                          {smData?.SM_Attachment_Source}
                        </div>
                      </li>
                      )
                    </>
                  ) : null}
                </ul>
              </div>
            </div>
          </div>
          <div
            className={`${
              checkLocation
                ? 'detail_view__overlay_search'
                : 'detail_view__overlay'
            }`}
            onClick={closeDetail}
          ></div>
        </div>
      )}
    </>
  );
};

export default ScrapView;

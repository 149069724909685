import { Link, useLocation } from 'react-router-dom';
import User from './User';
import '../styles/Header.scss';
import open_btn from '../imgs/icon/ico-open.png';
import close_btn from '../imgs/icon/ico-x.png';
import logo from '../imgs/icon/m_logo_wh.png';

const Mobileheader = () => {
  // 현재 페이지 URL 가져오기
  const location = useLocation();

  // 현재 URL과 해당 링크의 URL 비교 함수
  const isActiveLink = (linkPath) => {
    return location.pathname === linkPath;
  };

  let body = document.querySelector('body');

  const linkActive = (e) => {
    document
      .querySelector('.mobile-header .user-gnb-layer.layer-active')
      .classList.remove('layer-active');
    body.classList.remove('on');
  };

  const gnbLayerActive = (e) => {
    let btns = document.querySelectorAll('.mobile-header .gnb-btn'),
      layer = document.querySelector('.mobile-header .user-gnb-layer');
    btns.forEach((btn, i) => {
      if (btn == e.currentTarget) {
        layer.classList.toggle('layer-active');
        body.classList.toggle('on');
      }
    });
  };

  return (
    <>
      <div className='common-header mobile-header'>
        <div className='header-inner'>
          <div className='header-top'>
            <Link to='/' alt='한마음선원 관리자'>
              <img className='logo-img' src={logo} alt='로고' />
            </Link>
            <button
              type='button'
              className='gnb-btn open'
              onClick={gnbLayerActive}
            >
              <img src={open_btn} alt='로고' />
            </button>
          </div>
          <div className='user-gnb-layer'>
            <div className='gnb-inner'>
              <div className='gnb-top'>
                <User />
                <button
                  type='button'
                  className='gnb-btn close'
                  onClick={gnbLayerActive}
                >
                  <img src={close_btn} alt='로고' />
                </button>
              </div>
              <div className='mobile-column-gnb'>
                <div className='gnb-depth'>
                  <h3>
                    <strong>법문관리</strong>
                  </h3>
                  <ul className='sub-depth'>
                    <li>
                      <p className='sub-title'>
                        <strong>허공을 걷는길</strong>
                      </p>
                      <ol className='sub-gnb-list-box'>
                        <li>
                          <Link
                            to='/cate_list'
                            className={
                              isActiveLink('/cate_list') ? 'active' : ''
                            }
                            onClick={linkActive}
                          >
                            <span>카테고리 관리</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/title_list'
                            className={
                              isActiveLink('/title_list') ? 'active' : ''
                            }
                            onClick={linkActive}
                          >
                            <span>제목 관리</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/para_list'
                            className={
                              isActiveLink('/para_list') ? 'active' : ''
                            }
                            onClick={linkActive}
                          >
                            <span>단락 관리</span>
                          </Link>
                        </li>
                      </ol>
                    </li>
                  </ul>
                  <ul className='sub-depth'>
                    <li>
                      <p className='sub-title'>
                        <strong className='sub-title'>한마음 요전</strong>
                      </p>
                      <ol className='sub-gnb-list-box'>
                        <li>
                          <Link
                            to='/episode_list'
                            className={
                              isActiveLink('/episode_list') ? 'active' : ''
                            }
                            onClick={linkActive}
                          >
                            <span>편 관리</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/chapter_list?page=1'
                            className={
                              isActiveLink('/chapter_list') ? 'active' : ''
                            }
                            onClick={linkActive}
                          >
                            <span>장 관리</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/subtitle_list?page=1'
                            className={
                              isActiveLink('/subtitle_list') ? 'active' : ''
                            }
                            onClick={linkActive}
                          >
                            <span>소제목 관리</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/yjpara_list?page=1'
                            className={
                              isActiveLink('/yjpara_list') ? 'active' : ''
                            }
                            onClick={linkActive}
                          >
                            <span>단락 관리</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/bub_search?cate=&page='
                            className={
                              isActiveLink('/user_list') ? 'active' : ''
                            }
                            onClick={linkActive}
                          >
                            <span>법문검색</span>
                          </Link>
                        </li>
                      </ol>
                    </li>
                  </ul>
                </div>

                <div className='gnb-depth'>
                  <h3>
                    <strong>논문관리</strong>
                  </h3>
                  <ul className='sub-depth'>
                    <li>
                      <p className='sub-title'>
                        <strong>논문관리</strong>
                      </p>
                      <ol className='sub-gnb-list-box'>
                        <li>
                          <Link
                            to='/sm_thesis_list'
                            className={
                              isActiveLink('/sm_thesis_list') ? 'active' : ''
                            }
                            onClick={linkActive}
                          >
                            <span>학위논문 관리</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/sm_journal_list'
                            className={
                              isActiveLink('/sm_journal_list') ? 'active' : ''
                            }
                            onClick={linkActive}
                          >
                            <span>학술지 관리</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/sm_conference_list'
                            className={
                              isActiveLink('/sm_conference_list')
                                ? 'active'
                                : ''
                            }
                            onClick={linkActive}
                          >
                            <span>학술대회자료집 관리</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/sm_book_list'
                            className={
                              isActiveLink('/sm_books_list') ? 'active' : ''
                            }
                            onClick={linkActive}
                          >
                            <span>단행본 관리</span>
                          </Link>
                        </li>
                        <li>
                          <Link
                            to='/sm_search'
                            className={
                              isActiveLink('/sm_search') ? 'active' : ''
                            }
                            onClick={linkActive}
                          >
                            <span>논문검색</span>
                          </Link>
                        </li>
                      </ol>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Mobileheader;

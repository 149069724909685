import React from 'react';
import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';

const CateView = () => {
    const { id } = useParams();
    const [data, setData] = useState(null);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_SERVER_URL}/bub/cate/${id}`) // API 엔드포인트 URL
            .then((response) => {
                const dataArray = response.data; // API에서 받아온 데이터를 배열로 변환
                const bubCateView = dataArray.bubCate[0];
                setData(bubCateView);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, [id]);

    if (!data) {
        // 데이터를 아직 불러오지 못한 경우 로딩 상태를 표시할 수도 있습니다.
        return <div>Loading...</div>;
    }

    const handleDelete = () => {
        if (window.confirm('정말로 삭제하시겠습니까?')) {
            axios
                .delete(`${process.env.REACT_APP_SERVER_URL}/bub/cate/${id}`)
                .then((response) => {
                    console.log('삭제 성공:', response.data);
                    window.location.pathname = '/cate_list';
                })
                .catch((error) => {
                    console.error('삭제 실패:', error);
                });
        } else {
            return;
        }
    };

    return (
        <div className="content">

            <div className="logo-text">한마음과학원</div>
            <div className="category-titles">
                <span>법문관리</span>
                <span>허공을 걷는 길</span>
                <span className="status-title">카테고리 관리</span>
            </div>
            <div className="page-head">
                <span>{data.category_name}</span>
            </div>
            <div className="data-form">
                <div className="form-inner">
                    <div className="data-box">
                        <label>카테고리 이름</label>
                        <div className="data-in-box readonly">{data.category_name}</div>
                    </div>
                    <div className="data-box">
                        <label>카테고리 설명</label>
                        <div className="data-in-box readonly">{data.category_shortName}</div>
                    </div>
                    <div className="data-box">
                        <label>출판사</label>
                        <div className="data-in-box readonly">{data.publisher}</div>
                    </div>
                    <div className="data-box">
                        <label>저자</label>
                        <div className="data-in-box readonly">{data.author}</div>
                    </div>
                    <div className="data-box">
                        <label>출판일</label>
                        <div className="data-in-box readonly">{data.publication_date}</div>
                    </div>
                    <div className="data-box">
                        <label>사용 여부</label>
                        <div className="data-in-box readonly">{data.use_yn}</div>
                    </div>
                </div>
            </div>
            <div className="button_wrap">
                <Link to={`/cate_edit/${id}`} className="btn wh">
                    수정하기
                </Link>
                <button onClick={handleDelete} className="btn wh">
                    삭제하기
                </button>
                <Link to="/cate_list" className="btn blk">
                    목록보기
                </Link>
            </div>
        </div>
    );
};

export default CateView;

import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Useyn from '../../components/Useyn';
import arrowImg from '../../imgs/icon/ico-arrow-select.png';

const ParaWrite = () => {
  const { id } = useParams();
  const [cateId, setCateId] = useState('');
  const [cateName, setCateName] = useState('');
  const [paraTitle, setparaTitle] = useState('');
  const [para, setpara] = useState('');
  const [paraSeq, setparaSeq] = useState('');
  const [paraPage, setparaPage] = useState('');
  const [paraPageSeq, setpageSeq] = useState('');
  const [paraUse, setparaUse] = useState('N'); // 카테고리 이름을 저장하는 state
  const [cateList, setCateList] = useState([]); // 카테고리 데이터를 저장하는 state
  const [titleList, setTitleList] = useState([]); // 카테고리 데이터를 저장하는 state
  const [titleOpts, setTitleOpts] = useState([]);
  const [titleSeq, setTitleSeq] = useState('');

  // 등록할 때, 셀렉트 박스에 넣을 데이터를 불러옴
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/bub/cate`)
      .then((response) => {
        const dataArray = response.data;
        const bubCate = dataArray.bubCateList.filter(
          (item) => item.use_yn !== 'N',
        );
        setCateList(bubCate);
        console.log(bubCate);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/bub/title`)
      .then((response) => {
        const dataArray = response.data;
        const bubTitle = dataArray.bubTitleList.filter(
          (item) => item.use_yn !== 'N',
        );
        setTitleList(bubTitle);
        setTitleSeq(bubTitle[0].title_seq);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // 수정일 때만 실행
  useEffect(() => {
    if (id) {
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/bub/para/${id}`)
        .then((response) => {
          const paraData = response.data.bubPara[0]; // 첫 번째 요소에 있는 카테고리 정보
          setCateId(paraData.category_id);
          setCateName(paraData.category_name);
          setparaTitle(paraData.title);
          setpara(paraData.paragraph);
          setparaSeq(paraData.paragraph_seq);
          setTitleSeq(paraData.title_seq);
          setparaPage(paraData.paragraph_page);
          setpageSeq(paraData.page_seq);
          setparaUse(paraData.use_yn);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [id]);

  // 카테고리가 변경될 때마다 해당 카테고리에 속하는 제목만 셀렉트 박스에 넣음
  useEffect(() => {
    if (titleList.length === 0) return;
    setTitleOpts(
      titleList.filter(
        (item) =>
          item.category_name ===
          (cateName ? cateName : cateList[0].category_name),
      ),
    );
  }, [titleList]);

  // 카테고리가 변경될 때마다 해당 카테고리의 id를 저장
  const handleCateChange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const category_id = Number(selectedOption.id);

    setCateName(e.target.value);

    // 카테고리에 속하는 제목만 셀렉트 박스에 넣음
    setTitleOpts(
      titleList.filter((item) => {
        return item.category_id === category_id;
      }),
    );

    const cate = cateList.filter((item) => {
      return item.id === category_id;
    });

    setCateId(cate[0].id);
  };

  // 제목이 변경될 때마다 해당 제목의 seq를 저장
  const handleTitleChange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const title_id = Number(selectedOption.id);

    setparaTitle(e.target.value);
    const matchedTitleSeq = titleOpts.filter((item) => {
      return item.id === title_id;
    });

    setTitleSeq(matchedTitleSeq[0].title_seq);
  };

  // 사용여부가 변경될 때마다 해당 값을 저장
  const handleUseChange = (e) => {
    setparaUse(e.target.value);
  };

  // 등록 또는 수정 버튼 클릭 시
  const handleSubmit = (e) => {
    e.preventDefault();

    const postData = {
      category_id: cateId,
      title_seq: titleSeq,
      paragraph: para,
      paragraph_seq: paraSeq,
      paragraph_page: paraPage,
      page_seq: paraPageSeq,
      use_yn: paraUse,
    };

    if (id) {
      if (window.confirm('정말로 수정하시겠습니까?')) {
        axios
          .patch(`${process.env.REACT_APP_SERVER_URL}/bub/para/${id}`, postData)
          .then((response) => {
            console.log('수정 성공:', response.data);
            window.location.pathname = `/para_view/${id}`;
          })
          .catch((error) => {
            console.error('수정 실패:', error);
          });
      }
    } else {
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}/bub/para`, postData)
        .then((response) => {
          console.log('등록 성공:', response.data);
          window.location.pathname = '/para_list';
        })
        .catch((error) => {
          console.error('등록 실패:', error);
        });
    }
  };

  return (
    <div className='content'>
      <div className='logo-text'>한마음과학원</div>
      <div className='category-titles'>
        <span>법문관리</span>
        <span>허공을 걷는 길</span>
        <span className='status-title'>단락 관리</span>
      </div>
      <div className='page-head'>
        <span>{id ? '단락 수정' : '단락 등록'}</span>
      </div>
      <form onSubmit={handleSubmit} className='data-form'>
        <div className='form-inner'>
          <div className='division'>
            <div className='data-box'>
              <label>카테고리 이름</label>
              <div className='select-box'>
                <select value={cateName} onChange={handleCateChange}>
                  {cateList.map((item) => (
                    <option
                      key={item.id}
                      id={item.id}
                      value={item.category_name}
                    >
                      {item.category_name}
                    </option>
                  ))}
                </select>
                <span className='iconArrow'>
                  <img src={arrowImg} alt='arrow image' />
                </span>
              </div>
            </div>
            <div className='data-box'>
              <label>제목 이름</label>
              <div className='select-box'>
                <select value={paraTitle} onChange={handleTitleChange}>
                  {titleOpts.map((item) => (
                    <option key={item.id} id={item.id} value={item.title}>
                      {item.title}
                    </option>
                  ))}
                </select>
                <span className='iconArrow'>
                  <img src={arrowImg} alt='arrow image' />
                </span>
              </div>
            </div>
          </div>
          <div className='data-box'>
            <label>단락</label>
            {/* <input type="text" value={para} onChange={(e) => setpara(e.target.value)} /> */}
            <textarea
              defaultValue={para}
              placeholder='내용을 입력해주세요.'
              onChange={(e) => setpara(e.target.value)}
            />
          </div>
          <div className='division'>
            <div className='data-box'>
              <label>단락 순서</label>
              <input
                type='tel'
                className='data-in-box'
                value={paraSeq}
                onChange={(e) => {
                  // 입력값이 숫자인지 확인하는 정규표현식을 사용하여 검증
                  const newValue = e.target.value.replace(/\D/g, ''); // 숫자가 아닌 문자를 제거
                  setparaSeq(newValue);
                }}
              />
            </div>
            <div className='data-box'>
              <label>페이지</label>
              <input
                type='input'
                className='data-in-box'
                value={paraPage}
                onChange={(e) => {
                  const newValue = e.target.value.replace(/[^0-9-]/g, '');
                  setparaPage(newValue);
                }}
              />
            </div>
          </div>
          <div className='division'>
            <div className='data-box'>
              <label>페이지 단락순서</label>
              <input
                type='tel'
                className='data-in-box'
                value={paraPageSeq}
                onChange={(e) => {
                  const newValue = e.target.value.replace(/\D/g, ''); // 숫자가 아닌 문자를 제거
                  setpageSeq(newValue);
                }}
              />
            </div>
            <Useyn value={paraUse} onChange={handleUseChange} />
          </div>
        </div>
        <div className='button_wrap'>
          <Link to='/para_list' className='btn wh'>
            취소하기
          </Link>
          <button type='submit' className='btn blk'>
            {id ? '수정하기' : '등록하기'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ParaWrite;

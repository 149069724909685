import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Pagination from '../../components/Pagination';

const ChapterList = () => {
    const [data, setData] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [episodeData, setEpisodeData] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    // URL에서 페이지 번호 파라미터를 추출
  const queryParams = new URLSearchParams(window.location.search);
  const currentPage = Number(queryParams.get('page')) || 1;

    const itemsPerPage = 20;

    const fetchData = (pageNumber) => {
        axios
            .get(`${process.env.REACT_APP_SERVER_URL}/bub/yj/chapter?page=${pageNumber}`) // API 엔드포인트 URL
            .then((response) => {
                const dataArray = response.data;
                const bubChapter = dataArray.yjChapterList;
                const dataCount = dataArray.count[0];
                setData(bubChapter);
                setTotalItems(dataCount.count);
            })
            .catch((error) => {
                console.error('Error fetching data1:', error);
            });

        axios
            .get(`${process.env.REACT_APP_SERVER_URL}/bub/yj/episode`) // API 엔드포인트 URL
            .then((response) => {
                const dataEpisode = response.data;
                const yjepilist = dataEpisode.yjEpiList;
                setEpisodeData(yjepilist);
            })
            .catch((error) => {
                console.error('Error fetching data2:', error);
            });
    };

    // 페이지가 변경되면 데이터를 다시 불러옴
    useEffect(() => {
        fetchData(currentPage);
    }, [currentPage]);

    const totalPages = Math.ceil(totalItems / itemsPerPage);

    // 페이지 변경 시 이벤트 처리
    const handlePageChange = (pageNumber) => {
        // URL을 업데이트하여 페이지 번호를 변경
        window.location.search = `?page=${pageNumber}`;
    };

    // 페이지 번호 목록 생성
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    // 선택된 아이템들을 삭제하는 함수
    const handleDeleteSelected = async () => {
        try {
            // 선택된 아이템들을 순회하며 서버에서 삭제하는 API 호출
            if (window.confirm('정말로 삭제하시겠습니까?')) {
                for (const id of selectedItems) {
                    await axios
                        .delete(`${process.env.REACT_APP_SERVER_URL}/bub/yj/chapter/${id}`)
                        .then((response) => {
                            console.log('삭제 성공:', response.data);
                        })
                        .catch((error) => {
                            console.error('삭제 실패:', error);
                        });
                }

                // 데이터를 다시 불러옴
                await axios
                    .get(`${process.env.REACT_APP_SERVER_URL}/bub/yj/chapter?page=${currentPage}`)
                    .then((response) => {
                        const dataArray = response.data;
                        const bubChapter = dataArray.yjChapterList;
                        setData(bubChapter);
                    })
                    .catch((error) => {
                        console.error('Error fetching data:', error);
                    });

                // 선택 상태 초기화
                setSelectedItems([]);
            } else {
                return;
            }
        } catch (error) {
            console.error('데이터 삭제 중 오류가 발생했습니다.', error);
        }
    };

    // 체크박스 선택 시 처리
    const handleCheckboxChange = (id) => {
        setSelectedItems((prevIds) => {
            // 현재 선택 상태 확인
            const isSelected = prevIds.includes(id);

            // 체크박스가 선택되었을 경우, id를 배열에 추가
            // 체크박스가 해제되었을 경우, id를 배열에서 제거
            return isSelected ? prevIds.filter((prevId) => prevId !== id) : [...prevIds, id];
        });
    };

    return (
        <div className="content">
            <div className="logo-text">한마음과학원</div>
            <div className="category-titles">
                <span>법문관리</span>
                <span>한마음요전</span>
                <span className="status-title">장 관리</span>
            </div>
            <div className="table-wrap chapter">
                <div className="tbl thead">
                    <ul>
                        <li className="chk-box"></li>
                        {/* <li>ID</li> */}
                        <li className="tb_ep">편 이름</li>
                        <li className="tb_ch">장 이름</li>
                        <li className="set">관리</li>
                    </ul>
                </div>
                <div className="tbl tbody">
                    {data.map((item) => {
                        const matchedEpisode = episodeData.find((episode) => episode.id === item.episode_id);
                        // data에서 episode_id와 일치하는 episode의 episode_name을 찾아서 episodeName에 저장
                        const episodeName = matchedEpisode ? matchedEpisode.episode : '';
                        return (
                            <ul key={item.id}>
                                <li className="chk-box">
                                    <label className="checkbox">
                                        <input
                                            type="checkbox"
                                            checked={selectedItems.includes(item.id)}
                                            onChange={() => handleCheckboxChange(item.id)}
                                        />
                                        <i></i>
                                    </label>
                                </li>
                                <li className="tb_ep m-title">
                                    <Link to={`/chapter_view/${item.id}`}>
                                        <span>{episodeName}</span>
                                    </Link>
                                </li>
                                <li className="tb_ch m-content">
                                    <p>{item.chapter}</p>
                                </li>
                                <li className="set">
                                    <Link to={`/chapter_view/${item.id}`} className="detail-btn">
                                        상세보기
                                    </Link>
                                </li>
                            </ul>
                        );
                    })}
                </div>
            </div>
            <div className="button_wrap">
                <button onClick={handleDeleteSelected} className="delete-btn btn wh">
                    삭제하기
                </button>
                <Link to="/chapter_write" className="btn create-btn m-c-c-b">
                    <span>등록하기</span>
                </Link>
            </div>

            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
        </div>
    );
};

export default ChapterList;

import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';

const SmSearchMenus = () => {
  const location = useLocation();
  const isActiveLink = (linkPath) => {
    return location.pathname === linkPath;
  };
  return (
    <div className='smmenu'>
      <ul>
        <li>
          <Link
            to='/sm_search'
            className={isActiveLink('/sm_search') ? 'active' : ''}
          >
            논문검색
          </Link>
        </li>
        {/* <li>
          <Link
            to='/sm_detail_search'
            className={isActiveLink('/sm_detail_search') ? 'active' : ''}
            onClick={() => {
              let body = document.querySelector('body');
              body.classList.add('on');
            }}
          >
            상세검색
          </Link>
        </li> */}
        <li>
          <Link
            to='/scrap_list?page=1&type=sm'
            className={isActiveLink('/scrap_list') ? 'active' : ''}
          >
            스크랩
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default SmSearchMenus;

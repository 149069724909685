import React, { useState } from 'react';

const SmModalConference = ({ onSubmit }) => {
  let body = document.querySelector('body');
  const [formData, setFormData] = useState({
    SM_Conference_Name: '',
    first_opt: 'or',
    SM_Conference_Location: '',
    second_opt: 'or',
    SM_Conference_Date: '',
  });

  const {
    SM_Conference_Name,
    first_opt,
    SM_Conference_Location,
    second_opt,
    SM_Conference_Date,
  } = formData;

  const onChange = (e) => {
    if (e.target.name !== '') {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
    body.classList.remove('on');
  };

  // 초기화 버튼 실행시 리셋
  const onReset = (e) => {
    e.preventDefault();
    setFormData({
      SM_Conference_Name: '',
      first_opt: 'or',
      SM_Conference_Location: '',
      second_opt: 'or',
      SM_Conference_Date: '',
    });
  };

  return (
    <React.Fragment>
      <div className='sm-input-form-wrap'>
        <div className='search-wrap'>
          <div className='input-value-box'>
            <span className='label'>이름</span>
            <div className='input-box'>
              <input
                type='text'
                value={SM_Conference_Name}
                name='SM_Conference_Name'
                onChange={onChange}
              />
            </div>
          </div>
          <div className='select-box'>
            <select value={first_opt} name='first_opt' onChange={onChange}>
              <option value='or'>or</option>
              <option value='and'>and</option>
            </select>
          </div>
        </div>
        <div className='divide-box'>
          <div className='search-wrap'>
            <div className='input-value-box'>
              <span className='label'>장소</span>
              <div className='input-box'>
                <input
                  type='text'
                  value={SM_Conference_Location}
                  name='SM_Conference_Location'
                  onChange={onChange}
                />
              </div>
            </div>
            <div className='select-box'>
              <select value={second_opt} name='second_opt' onChange={onChange}>
                <option value='or'>or</option>
                <option value='and'>and</option>
              </select>
            </div>
          </div>
          <div className='search-wrap'>
            <div className='input-value-box'>
              <span className='label'>년도</span>
              <div className='input-box'>
                <input
                  type='text'
                  value={SM_Conference_Date}
                  name='SM_Conference_Date'
                  onChange={onChange}
                />
              </div>
            </div>
            {/* <div className="select-box">
                            <select value={} onChange={set(e.target.value)}>
                                <option value="or">or</option>
                                <option value="and">and</option>
                            </select>
                        </div> */}
          </div>
        </div>
      </div>
      <div className='box-wrap'>
        <div className='btns-inner'>
          <button onClick={onReset} className='btns wh-border'>
            초기화
          </button>
          <button
            type='submit'
            className='btns search-orange'
            onClick={handleSubmit}
          >
            검색하기
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SmModalConference;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const SutrasView = ({ item, openSutras, closeSutras }) => {
  const [content, setcontent] = useState('');
  const [feeling, setfeeling] = useState('');
    const [data, setData] = useState([]);
    const [yjData, setYjData] = useState([]);
    const [bubData, setBubData] = useState([]);

  const location = useLocation();
  const [checkLocation, setCheckLocation] = useState(false);

  useEffect(() => {
    const regex = /^\/search/;

    const match = location.pathname.match(regex);
    if (match) {
      setCheckLocation(true);
    } else {
      setCheckLocation(false);
    }
  }, [location]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/bub/sutras/detail/${item.id}`)
      .then((res) => {
        const dataArray = res.data;
        const sutrasUser = dataArray.sutrasCopying;
        setData(sutrasUser);
        setcontent(sutrasUser.contents);
        setfeeling(sutrasUser.feeling);
        console.log(dataArray);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [item]);

  if (item.type === 'yj') {
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_SERVER_URL}/bub/yj/para/${item.paragraph_id}`) // API 엔드포인트 URL
            .then((response) => {
                const dataArray = response.data; // API에서 받아온 데이터를 배열로 변환
                const bubyjParaView = dataArray.yjPara[0];
                setYjData(bubyjParaView);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
        }, []);
  } else if (item.type === 'bub') {
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_SERVER_URL}/bub/para/${item.paragraph_id}`) // API 엔드포인트 URL
            .then((response) => {
                const dataArray = response.data; // API에서 받아온 데이터를 배열로 변환
                const bubParaView = dataArray.bubPara[0];
                setBubData(bubParaView);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
        }, []);
  }

  if (!openSutras) return null;

  const handleSubmit = (e) => {
    e.preventDefault();

    const postData = {
      contents: content,
      feeling: feeling,
    };
    if (window.confirm('정말로 수정하시겠습니까?')) {
    axios
      .patch(`${process.env.REACT_APP_SERVER_URL}/bub/sutras/${item.id}`, postData)
      .then((response) => {
        console.log('수정 성공:', response.data);
        closeSutras();
      })
      .catch((error) => {
        console.error('수정 실패:', error);
        alert(error.response.data.message);
      });
    }
  };

  const sutrasDelete = (e) => {
    e.preventDefault();
    if (window.confirm('정말로 삭제하시겠습니까?')) {
    axios
      .delete(`${process.env.REACT_APP_SERVER_URL}/bub/sutras/${item.id}`)
      .then((res) => {
        console.log(res);
        alert('삭제되었습니다.');
        closeSutras();
      })
      .catch((err) => {
        console.log(err);
      });
    }
  };

  return (
    <div className='modal'>
      <div className='detail_view'>
        <div className='detail_view__content han'>
          <div className='detail_view__content__header fordetail-header'>
            <h3>온라인 사경</h3>
            <p className='fordetail-header-text'>{(item.type === 'yj' ? yjData.paragraph : item.type === 'bub' ? bubData.paragraph : '')}</p>
            <button onClick={closeSutras} className='close-btn'>
              X
            </button>
          </div>
          <div className='detail_view__content__body'>
            <form onSubmit={handleSubmit}>
              <div className='form-wrap'>
                <textarea
                  defaultValue={data.contents}
                  onChange={(e) => setcontent(e.target.value)}
                  className='textarea-191'
                ></textarea>
                <p className='comment'>
                사경 후 느낀점을 작성해주세요. ({feeling.length}/100자)
                  <span>총 6명이 위의 법문을 사경하였습니다.</span>
                </p>
                <textarea
                  maxLength='99'
                  defaultValue={data.feeling}
                  onChange={(e) => setfeeling(e.target.value)}
                  className='textarea-83'
                ></textarea>
              </div>
              <div className='box-wrap'>
                <a onClick={closeSutras} className='btns wh-border'>
                  취소하기
                </a>
                <button type='submit' className='btns search-orange'>
                  수정하기
                </button>
                <button className='btns wh-border' onClick={(e) => sutrasDelete(e)}>
            삭제하기
          </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        className={`${
          checkLocation ? 'detail_view__overlay_search' : 'detail_view__overlay'
        }`}
        onClick={closeSutras}
      ></div>
    </div>
  );
};

export default SutrasView;

import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DetailSearch from './detail_search';

const Detailbtn = ({ oncateChange, onSearchChange }) => {
  const location = useLocation();
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  let body = document.querySelector('body');

  useEffect(() => {
    setIsSearchOpen(true); // 페이지 로드 시 모달 띄우기
  }, []);

  const openSearch = () => {
    setIsSearchOpen(true);

    body.classList.add('on');
  };

  const closeSearch = () => {
    setIsSearchOpen(false);
    body.classList.remove('on');
  };

  const handleSearch = (data) => {
    const { cateData, searchValue } = data;
    oncateChange(cateData);
    onSearchChange(searchValue);
    const url = `${location.pathname}?cate=${cateData}&${searchValue}`;
    window.history.pushState({ path: url }, '', url);
  };

  return (
    <div className='detail_btn'>
      <button className='detail_btn___btn' onClick={openSearch}>
        <span>상세검색</span>
      </button>
      <DetailSearch
        openSearch={isSearchOpen}
        closeSearch={closeSearch}
        onSearch={handleSearch}
      />
    </div>
  );
};

export default Detailbtn;

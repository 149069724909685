import axios from 'axios';
import { useState, useEffect } from 'react';
import { useParams, Link, useLocation } from 'react-router-dom';

import BubsearchMenu from '../../components/search/menu';

const SutrasEdit = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [contents, setContents] = useState(data.contents);
  const [feeling, setFeeling] = useState(data.feeling);
  const location = useLocation();
  const [checkLocation, setCheckLocation] = useState(false);

  useEffect(() => {
    const regex = /^\/search/;

    const match = location.pathname.match(regex);
    if (match) {
      setCheckLocation(true);
    } else {
      setCheckLocation(false);
    }
  }, [location]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/bub/sutras/detail/${id}`)
      .then((res) => {
        const dataArray = res.data;
        const sutrasUser = dataArray.sutrasCopying;
        setData(sutrasUser);
        console.log(dataArray);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  // console.log(data)

  const sutrasEdit = (e) => {
    e.preventDefault();
    const postData = {
      contents: contents,
      feeling: feeling,
    };
    if (window.confirm('정말로 수정하시겠습니까?')) {
      axios
        .patch(`${process.env.REACT_APP_SERVER_URL}/bub/sutras/${id}`, postData)
        .then((response) => {
          console.log('수정 성공:', response.data);
          window.location.pathname = `/sutras_view/${id}`;
        })
        .catch((error) => {
          console.error('수정 실패:', error);
          alert(error.response.data.message);
        });
    }
  };

  return (
    <div className='search sutras'>
      <div className='search-inner'>
        {checkLocation ? null : (
          <div>
            <p className='page-title'>법문검색</p>
            <BubsearchMenu />
          </div>
        )}
        <div className='sutras-table-wrap'>
          <div>
            법문단락 :
            <textarea
              className='textarea-191'
              defaultValue={data.contents}
              onChange={(e) => setContents(e.target.value)}
            ></textarea>
          </div>
          <div>
            사경후 올라온 맘 :
            <textarea
              className='textarea-191'
              defaultValue={data.feeling}
              onChange={(e) => setFeeling(e.target.value)}
            ></textarea>
          </div>
        </div>
        <div className='sutras_btn'>
          <button className='btn btn-191' onClick={(e) => sutrasEdit(e)}>
            수정하기
          </button>
          <Link to='/sutras' className='btn btn-191'>
            취소하기
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SutrasEdit;

const SmConfComponent = ({ info, setInfo }) => {
  return (
    <>
      <div className="division">
        <div className="data-box">
          <label>학회명</label>
          <input
            type="text"
            className="data-in-box"
            value={info.SM_Conference_Name}
            onChange={(e) => {
              setInfo({
                ...info,
                SM_Conference_Name: e.target.value,
              });
            }}
          />
        </div>
        <div className="data-box">
          <label>학회 장소</label>
          <input
            type="text"
            className="data-in-box"
            value={info.SM_Conference_Location}
            onChange={(e) => {
              setInfo({
                ...info,
                SM_Conference_Location: e.target.value,
              });
            }}
          />
        </div>
      </div>
      <div className="data-box">
        <label>학회 개최일자</label>
        <input
          type="text"
          className="data-in-box"
          value={info.SM_Conference_Date}
          onChange={(e) => {
            setInfo({
              ...info,
              SM_Conference_Date: e.target.value,
            });
          }}
          placeholder="예. 2023-01-01"
        />
      </div>
    </>
  );
};

export default SmConfComponent;

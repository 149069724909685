import React from 'react';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import axios from 'axios';
import Pagination from '../../components/Pagination';
import 'react-tooltip/dist/react-tooltip.css';

//검색 import
import ListSearch from '../../components/list/HggSearchComponent';

const ParaList = () => {
  const location = useLocation();

  const [data, setData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const itemsPerPage = 20;

  // URL에서 페이지 번호 파라미터를 추출
  const queryParams = new URLSearchParams(window.location.search);
  const currentPage = Number(queryParams.get('page')) || 1;
//   const currentSearch = queryParams.get('search') || '';
const currentSection = queryParams.get('section') || '';
const currentCategory = queryParams.get('category_id') || '';
const currentTitle = queryParams.get('title_seq') || '';
const currentSearch = queryParams.get('search') || '';

  //console.log('currentPage:', currentPage);

  // 페이지가 변경되면 데이터를 다시 불러옴
  useEffect(() => {
    if (!currentSection) {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/bub/para?page=${currentPage}`) // API 엔드포인트 URL
      .then((response) => {
        const dataArray = response.data;
        const bubPara = dataArray.bubParaList;
        const dataCount = dataArray.count[0];
        setData(bubPara);
        setTotalItems(dataCount.count);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      })
    } else {
        axios
        .get(`${process.env.REACT_APP_SERVER_URL}/bub/search/list?section=${currentSection}&category_id=${currentCategory}&title_seq=${currentTitle}&search=${currentSearch}&page=${currentPage}`)
        .then((response) => {
          const dataArray = response.data;
          const bubPara = dataArray.bubSearchList;
          const dataCount = dataArray.count[0];
          setData(bubPara);
          setTotalItems(dataCount.count);
          console.log('검색결과:', dataArray);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [currentPage]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // 페이지 변경 시 이벤트 처리 (pagination)
  const handlePageChange = (pageNumber) => {
    // URL을 업데이트하여 페이지 번호를 변경
    if (!currentSection) {
      window.location.search = `?page=${pageNumber}`;
    } else {
    window.location.search = `?section=${currentSection}&category_id=${currentCategory}&title_seq=${currentTitle}&search=${currentSearch}&page=${pageNumber}`;
    }
  };

  //   function handleItemClick(item) {
  //     // 정렬 상태 변경
  //     const newSortOrder = sortOrder === `${item}` ? `${item}_r` : `${item}`;

  //     // 변경된 정렬 상태를 어딘가로 보내는 로직을 추가
  //     window.location.search = `?page=${currentPage}&sort=${newSortOrder}&date=${dateOrder}`;
  //   }

  //   function handleDateChange(item) {
  //     // 정렬 상태 변경
  //     window.location.search = `${location.pathname}?page=${currentPage}&sort=${sortOrder}&date=${item}`;
  //   }

  // 페이지 번호 목록 생성
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  const handleCheckboxChange = (id) => {
    setSelectedItems((prevIds) => {
      // 현재 선택 상태 확인
      const isSelected = prevIds.includes(id);

      // 체크박스가 선택되었을 경우, id를 배열에 추가
      // 체크박스가 해제되었을 경우, id를 배열에서 제거
      return isSelected
        ? prevIds.filter((prevId) => prevId !== id)
        : [...prevIds, id];
    });
  };

  const handleDeleteSelected = async () => {
    try {
      // 선택된 아이템들을 순회하며 서버에서 삭제하는 API 호출
      if (window.confirm('정말로 삭제하시겠습니까?')) {
        for (const id of selectedItems) {
          await axios
            .delete(`${process.env.REACT_APP_SERVER_URL}/bub/para/${id}`)
            .then((response) => {
              console.log('삭제 성공:', response.data);
            })
            .catch((error) => {
              console.error('삭제 실패:', error);
            });
        }

        // 데이터를 다시 불러옴
        await axios
          .get(
            `${process.env.REACT_APP_SERVER_URL}/bub/para?page=${currentPage}`,
          )
          .then((response) => {
            const dataArray = response.data;
            const bubPara = dataArray.bubParaList;
            setData(bubPara);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });

        // 선택 상태 초기화
        setSelectedItems([]);
      } else {
        return;
      }
    } catch (error) {
      console.error('데이터 삭제 중 오류가 발생했습니다.', error);
    }
  };

    // 툴팁 스타일
    const tooltipStyle = {
        maxWidth: '500px',
        width: 'auto',
        padding: '10px 20px',
        lineHeight: '1.5',
    };

    // 검색 post api
    //const [search, setSearch] = useState('');

    const handleSearch = (data) => {
        const { cateId, titleSeq, input } = data;
        const searchSection = '허공단락';
        const searchWrap = {
            section: encodeURIComponent(searchSection),
            category_id: cateId,
            title_seq: titleSeq,
            search: encodeURIComponent(input),
          };


          console.log('검색어:', searchWrap);

      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/bub/search/list?section=${searchWrap.section}&category_id=${searchWrap.category_id}&title_seq=${searchWrap.title_seq}&search=${searchWrap.search ? searchWrap.search : ''}&page=1`)
        .then((response) => {
          const dataArray = response.data;
          const bubPara = dataArray.bubSearchList;
          const dataCount = dataArray.count[0];
          setData(bubPara);
          setTotalItems(dataCount.count);
          console.log('검색결과:', dataArray);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });

        //window.location.search = `${location.pathname}?search=${searchLink2}&page=${currentPage}`;
        const url = `${location.pathname}?section=${searchWrap.section}&category_id=${searchWrap.category_id}&title_seq=${searchWrap.title_seq}&search=${searchWrap.search ? searchWrap.search : ''}&page=1`;
        window.history.pushState({ path: url }, '', url);
    };

    const hadleReset = () => {
        window.location.search = `${location.pathname}`;
    }

  return (
    <div className='content'>
      <div className='logo-text'>한마음과학원</div>
      <div className='category-titles'>
        <span>법문관리</span>
        <span>허공을 걷는 길</span>
        <span className='status-title'>단락 관리</span>
      </div>
      <ListSearch
      hgg='para'
currentCategory={currentCategory}
currentTitle={currentTitle}
currentSearch={currentSearch}
onSearch={handleSearch}
onReset={hadleReset} />
      <div className='table-wrap'>
        <div className='tbl thead'>
          <ul>
            <li className='chk-box'></li>
            <li className='tb_title'>제목 이름</li>
            <li className='tb_content'>단락</li>
            <li className='tb_para_seq'>단락 순서</li>
            <li className='tb_page'>페이지 순서</li>
            <li className='tb_page_seq'>페이지 단락순서</li>
            <li className='set'>관리</li>
          </ul>
        </div>
        <div className='tbl tbody'>
          {data.map((item) => (
            <ul key={item.id}>
              <li className='chk-box'>
                <label className='checkbox'>
                  <input
                    type='checkbox'
                    checked={selectedItems.includes(item.id)}
                    onChange={() => handleCheckboxChange(item.id)}
                  />
                  <i></i>
                </label>
              </li>
              {/* <li>
                  <span className="label">ID</span>
                  <span className="c">{item.id}</span>
                </li> */}
              <li className='tb_title m-title'>
                <p className='c'>{item.title}</p>
              </li>
              <li className='tb_content m-content'>
                <p
                  data-tooltip-id='my-tooltip'
                  className='c'
                  data-tooltip-content={item.paragraph}
                >
                  {item.paragraph}
                </p>
                <Tooltip id='my-tooltip' style={tooltipStyle} />
              </li>
              <li className='tb_para_seq'>
                <p>{item.paragraph_seq}</p>
              </li>
              <li className='tb_page'>
                <p>{item.paragraph_page}</p>
              </li>
              <li className='tb_page_seq'>
                <p>{item.page_seq}</p>
              </li>
              <li className='mobile-text m-list'>
                <ol>
                  <li>
                    <span className='label'>단락순서</span>
                    <p>{item.paragraph_seq}</p>
                  </li>
                  <li>
                    <span className='label'>페이지</span>
                    <p>{item.paragraph_page}</p>
                  </li>
                  <li>
                    <span className='label'>페이지 단락순서</span>
                    <p>{item.page_seq}</p>
                  </li>
                </ol>
              </li>
              <li className='set'>
                <Link to={`/para_view/${item.id}`} className='detail-btn c'>
                  상세보기
                </Link>
              </li>
            </ul>
          ))}
        </div>
      </div>
      <div className='button_wrap'>
        <button onClick={handleDeleteSelected} className='delete-btn btn wh'>
          삭제하기
        </button>
        <Link to='/para_write' className='btn create-btn m-c-c-b'>
          <span>등록하기</span>
        </Link>
      </div>

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default ParaList;

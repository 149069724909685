import React from 'react';
import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';

const ChapterView = () => {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [episodeData, setEpisodeData] = useState([]);

    // 데이터를 불러올 함수
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_SERVER_URL}/bub/yj/chapter/${id}`) // API 엔드포인트 URL
            .then((response) => {
                const dataArray = response.data; // API에서 받아온 데이터를 배열로 변환
                const bubChapterView = dataArray.yjChapter[0];
                setData(bubChapterView);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });

        axios
            .get(`${process.env.REACT_APP_SERVER_URL}/bub/yj/episode`) // API 엔드포인트 URL
            .then((response) => {
                const dataEpisode = response.data;
                const yjepilist = dataEpisode.yjEpiList;
                setEpisodeData(yjepilist);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, [id]);

    if (!data) {
        // 데이터를 아직 불러오지 못한 경우 로딩 상태를 표시할 수도 있습니다.
        return <div>Loading...</div>;
    }

    // 삭제하기 버튼 클릭 시
    const handleDelete = () => {
        if (window.confirm('정말로 삭제하시겠습니까?')) {
            axios
                .delete(`${process.env.REACT_APP_SERVER_URL}/bub/yj/chapter/${id}`)
                .then((response) => {
                    console.log('삭제 성공:', response.data);
                    window.location.pathname = '/chapter_list';
                })
                .catch((error) => {
                    console.error('삭제 실패:', error);
                });
        }
    };

    const matchedEpisode = episodeData.find((episode) => episode.id === data.episode_id);
    const episodeName = matchedEpisode ? matchedEpisode.episode : '';

    return (
        <div className="content">
            <div className="logo-text">한마음과학원</div>
            <div className="category-titles">
                <span>법문관리</span>
                <span>한마음 요전</span>
                <span className="status-title">장 관리</span>
            </div>
            <div className="page-head">
                <span>{episodeName}</span>
            </div>
            <div className="data-form">
                <div className="form-inner">
                    <div className="division">
                        <div className="data-box">
                            <label>편 이름</label>
                            <div className="data-in-box readonly">{episodeName}</div>
                        </div>
                        <div className="data-box">
                            <label>장 이름</label>
                            <div className="data-in-box readonly">{data.chapter}</div>
                        </div>
                    </div>
                    <div className="data-box">
                        <label>사용 여부</label>
                        <div className="data-in-box readonly">{data.use_yn}</div>
                    </div>
                </div>
            </div>
            <div className="button_wrap">
                <Link to={`/chapter_edit/${id}`} className="btn wh">
                    수정하기
                </Link>
                <button onClick={handleDelete} className="btn wh">
                    삭제하기
                </button>
                <Link to="/chapter_list" className="btn blk">
                    목록보기
                </Link>
            </div>
        </div>
    );
};

export default ChapterView;

import React, { useState } from 'react';

const SmModalBooks = ({ onSubmit }) => {
  let body = document.querySelector('body');
  const [formData, setFormData] = useState({
    SM_Books_Name: '',
    first_opt: 'or',
    SM_Books_Publish: '',
  });

  const { SM_Books_Name, first_opt, SM_Books_Publish } = formData;

  const onChange = (e) => {
    if (e.target.name !== '') {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
    body.classList.remove('on');
  };

  // 초기화 버튼 실행시 리셋
  const onReset = (e) => {
    e.preventDefault();
    setFormData({
      SM_Books_Name: '',
      first_opt: 'or',
      SM_Books_Publish: '',
    });
  };

  return (
    <React.Fragment>
      <div className='sm-input-form-wrap'>
        <div className='divide-box'>
          <div className='search-wrap'>
            <div className='input-value-box'>
              <span className='label'>단행본</span>
              <div className='input-box'>
                <input
                  type='text'
                  value={SM_Books_Name}
                  name='SM_Books_Name'
                  onChange={onChange}
                />
              </div>
            </div>
            <div className='select-box'>
              <select value={first_opt} name='first_opt' onChange={onChange}>
                <option value='or'>or</option>
                <option value='and'>and</option>
              </select>
            </div>
          </div>
          <div className='search-wrap'>
            <div className='input-value-box'>
              <span className='label'>출판사</span>
              <div className='input-box'>
                <input
                  type='text'
                  value={SM_Books_Publish}
                  name='SM_Books_Publish'
                  onChange={onChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='box-wrap'>
        <div className='btns-inner'>
          <button onClick={onReset} className='btns wh-border'>
            초기화
          </button>
          <button
            type='submit'
            className='btns search-orange'
            onClick={handleSubmit}
          >
            검색하기
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SmModalBooks;

import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Useyn from '../../components/Useyn';
import arrowImg from '../../imgs/icon/ico-arrow-select.png';

const ChapterWrite = () => {
  const { id } = useParams();
  const [episodeId, setEpisodeId] = useState('');
  const [episodeName, setEpisodeName] = useState('');
  const [chapterName, setChapterName] = useState('');
  const [chapterUse, setChapterUse] = useState('N'); // 카테고리 이름을 저장하는 state
  const [episodeData, setEpisodeData] = useState([]);

  // 등록할 때, 데이터를 불러올 함수
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/bub/yj/episode`)
      .then((response) => {
        const episodeData = response.data.yjEpiList.filter(
          (episode) => episode.use_yn !== 'N',
        ); // 첫 번째 요소에 있는 카테고리 정보
        setEpisodeData(episodeData);
        setEpisodeId(episodeData[0].id);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // 수정할 때, 데이터를 불러올 함수
  useEffect(() => {
    if (id) {
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/bub/yj/chapter/${id}`)
        .then((response) => {
          const chapterData = response.data.yjChapter[0]; // 첫 번째 요소에 있는 카테고리 정보
          console.log(chapterData);
          setEpisodeId(chapterData.episode_id);
          setEpisodeName(chapterData.episode_shortName);
          setChapterName(chapterData.chapter);
          setChapterUse(chapterData.use_yn);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [id]);

  // 편 이름이 변경되었을 때
  const handleSelectChange = (e) => {
    setEpisodeName(e.target.value);
    const newEpisodeName = e.target.value;
    const matchedEpisode = episodeData.find(
      (episode) => episode.episode_shortName === newEpisodeName,
    );

    if (matchedEpisode) {
      setEpisodeId(matchedEpisode.id);
    } else {
      setEpisodeId(''); // 또는 적절한 기본값 설정
    }
  };

  // 등록 또는 수정 버튼을 눌렀을 때
  const handleSubmit = (e) => {
    e.preventDefault();

    const postData = {
      episode_id: episodeId,
      chapter: chapterName,
      use_yn: chapterUse,
    };

    if (id) {
      if (window.confirm('정말로 수정하시겠습니까?')) {
        axios
          .patch(
            `${process.env.REACT_APP_SERVER_URL}/bub/yj/chapter/${id}`,
            postData,
          )
          .then((response) => {
            console.log('수정 성공:', response.data);
            // 수정 성공 후, 상세보기 페이지로 이동
            window.location.pathname = `/chapter_view/${id}`;
          })
          .catch((error) => {
            alert(error.response.data.message);
            console.error('수정 실패:', error);
          });
      }
    } else {
      // 새로운 카테고리 등록
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}/bub/yj/chapter`, postData)
        .then((response) => {
          console.log('등록 성공:', response.data);
          // 등록 성공 후, 목록 페이지로 이동
          window.location.pathname = '/chapter_list';
        })
        .catch((error) => {
          console.error('등록 실패:', error);
          alert(error.response.data.message);
        });
    }
  };

  return (
    <div className='content'>
      <div className='logo-text'>한마음과학원</div>
      <div className='category-titles'>
        <span>법문관리</span>
        <span>한마음 요전</span>
        <span className='status-title'>장 관리</span>
      </div>
      <div className='page-head'>
        <span>{id ? '장 관리 수정' : '장 관리 등록'}</span>
      </div>
      <form onSubmit={handleSubmit} className='data-form'>
        <div className='form-inner'>
          <div className='division'>
            <div className='data-box'>
              <label>편 이름</label>
              <div className='select-box'>
                <select
                  value={episodeName ?? episodeData[0].episode_shortName}
                  onChange={handleSelectChange}
                >
                  {episodeData.map((episode) => (
                    <option key={episode.id} value={episode.episode_id}>
                      {episode.episode_shortName}
                    </option>
                  ))}
                </select>
                <span className='iconArrow'>
                  <img src={arrowImg} alt='arrow image' />
                </span>
              </div>
            </div>
            <div className='data-box'>
              <label>장 이름</label>
              <input
                type='text'
                className='data-in-box'
                value={chapterName}
                onChange={(e) => setChapterName(e.target.value)}
              />
            </div>
          </div>

          <Useyn
            value={chapterUse}
            onChange={(e) => setChapterUse(e.target.value)}
          />
        </div>
        <div className='button_wrap'>
          <Link to='/chapter_list' className='btn wh'>
            취소하기
          </Link>
          <button type='submit' className='btn blk'>
            {id ? '수정하기' : '등록하기'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChapterWrite;

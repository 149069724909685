import React from "react";
import arrowImg from '../imgs/icon/ico-arrow-select.png'

const Useyn = ({ value, onChange }) => {
    return (
        <div className="data-box">
          <label>사용 여부</label>
          <div className="select-box">
            <select value={value} onChange={onChange}>
              <option value="N">미사용</option>
              <option value="Y">사용</option>
            </select>
            <span className="iconArrow"><img src={arrowImg} alt="arrow image" /></span>
          </div>
        </div>
    );
    }
  

export default Useyn;
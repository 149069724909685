import { Link, redirect, useLocation } from 'react-router-dom';
import User from './User';
import '../styles/Header.scss';
import logo from '../imgs/icon/logo.png';
import { useState, useEffect } from 'react';
import axios from 'axios';

const Header = ({ userName }) => {
  // 현재 페이지 URL 가져오기
  const location = useLocation();

  // 현재 URL과 해당 링크의 URL 비교 함수
  const isActiveLink = (linkPattern) => {
    //const location = useLocation();
    const currentPath = location.pathname;

    // 현재 URL이 링크 패턴을 포함하고 있는지 확인
    return currentPath.includes(linkPattern);
  };

  function togglegnb(e) {
    let header = document.querySelector('header'),
      layer = document.querySelector('.header-box');

    function event_listener() {
      if (header && layer) {
        header.classList.toggle('toggle-active');
      }
    }

    ((_) => {
      event_listener();
    })();
  }
  function goHome(e) {
    let firstTabWrapLi = document.querySelectorAll('.tab-wrap li');
    let firstGnbWrapLi = document.querySelectorAll('.pc-header .gnb-wrap .gnb');

    firstTabWrapLi.forEach((elem) => {
      elem.classList.remove('on');
    });

    firstGnbWrapLi.forEach((elem) => {
      elem.classList.remove('on');
    });

    if (firstTabWrapLi[0]) firstTabWrapLi[0].classList.add('on');
    if (firstGnbWrapLi[0]) firstGnbWrapLi[0].classList.add('on');
  }

  function toggleIdx(e) {
    let btns = document.querySelectorAll('.tab-wrap li'),
      gnbs = document.querySelectorAll('.gnb-wrap .gnb');
    btns.forEach((btn, i) => {
      if (btn === e.currentTarget) {
        btn.classList.add('on');
        gnbs[i].classList.add('on');
      } else {
        btn.classList.remove('on');
        gnbs[i].classList.remove('on');
      }
    });
  }

  function togglebtn(e) {
    let btns = document.querySelectorAll('.gnb-subtitle');
    btns.forEach((btn, i) => {
      if (btn === e.currentTarget) {
        btn.closest('.gnb-submenu').classList.toggle('on');
      }
    });
  }

  const gnbStorage = localStorage.getItem('gnb');
  if (gnbStorage === '') {
    localStorage.setItem('gnb', 'bub');
  }

  return (
    <>
      <div className='common-header pc-header'>
        <div className='staticed-header'>
          <div className='gnb-btn-wrap'>
            <div className='gnb-btn' onClick={togglegnb}>
              <span></span>
            </div>
          </div>
          <ul className='tab-wrap'>
            <li
              className={gnbStorage === 'bub' ? 'on' : ''}
              onClick={(e) => {
                toggleIdx(e);
                localStorage.setItem('gnb', 'bub');
              }}
            >
              <button className={gnbStorage === 'bub' ? 'active' : ''}></button>
              <div className='icon'></div>
              <span className='label'>법문</span>
            </li>
            <li
              className={gnbStorage === 'sm' ? 'on' : ''}
              onClick={(e) => {
                toggleIdx(e);
                localStorage.setItem('gnb', 'sm');
              }}
            >
              <button className={gnbStorage === 'sm' ? 'active' : ''}></button>
              <div className='icon'></div>
              <span className='label'>논문</span>
            </li>
          </ul>
        </div>
        <div className='header-box'>
          <h1 onClick={goHome}>
            <Link to='/' alt='한마음선원 관리자'>
              <img src={logo} alt='로고' />
            </Link>
          </h1>
          <nav className='gnb-wrap'>
            <div className={gnbStorage === 'bub' ? 'gnb on' : 'gnb'}>
              <h3>
                <span className='gnb-title'>법문</span>
              </h3>
              <ul className='gnb-submenu on'>
                <li>
                  <button className='gnb-subtitle' onClick={togglebtn}>
                    <span>허공을 걷는 길</span>
                  </button>
                  <ol>
                    <li>
                      <Link
                        to='/cate_list'
                        className={
                          isActiveLink('/cate_view')
                            ? 'active'
                            : isActiveLink('/cate_list')
                            ? 'active'
                            : isActiveLink('/cate_write') ||
                              isActiveLink('/cate_edit')
                            ? 'active'
                            : ''
                        }
                      >
                        <span>카테고리 관리</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='/title_list'
                        className={
                          isActiveLink('/title_list')
                            ? 'active'
                            : isActiveLink('/title_write') ||
                              isActiveLink('/title_edit')
                            ? 'active'
                            : isActiveLink('/title_view')
                            ? 'active'
                            : ''
                        }
                      >
                        <span>제목 관리</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='/para_list'
                        className={
                          isActiveLink('/para_list')
                            ? 'active'
                            : isActiveLink('/para_write') ||
                              isActiveLink('/para_edit')
                            ? 'active'
                            : isActiveLink('/para_view')
                            ? 'active'
                            : ''
                        }
                      >
                        <span>단락 관리</span>
                      </Link>
                    </li>
                  </ol>
                </li>
              </ul>
              <ul className='gnb-submenu on'>
                <li>
                  <button className='gnb-subtitle' onClick={togglebtn}>
                    <span>한마음 요전</span>
                  </button>
                  <ol>
                    <li>
                      <Link
                        to='/episode_list'
                        className={
                          isActiveLink('/episode_list')
                            ? 'active'
                            : isActiveLink('/episode_write') ||
                              isActiveLink('/episode_edit')
                            ? 'active'
                            : isActiveLink('/episode_view')
                            ? 'active'
                            : ''
                        }
                      >
                        <span>편 관리</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='/chapter_list'
                        className={
                          isActiveLink('/chapter_list')
                            ? 'active'
                            : isActiveLink('/chapter_write') ||
                              isActiveLink('/chapter_edit')
                            ? 'active'
                            : isActiveLink('/chapter_view')
                            ? 'active'
                            : ''
                        }
                      >
                        <span>장 관리</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='/subtitle_list'
                        className={
                          isActiveLink('/subtitle_list')
                            ? 'active'
                            : isActiveLink('/subtitle_write') ||
                              isActiveLink('/subtitle_edit')
                            ? 'active'
                            : isActiveLink('/subtitle_view')
                            ? 'active'
                            : ''
                        }
                      >
                        <span>소제목 관리</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='/yjpara_list'
                        className={
                          isActiveLink('/yjpara_list')
                            ? 'active'
                            : isActiveLink('/yjpara_write') ||
                              isActiveLink('/yjpara_edit')
                            ? 'active'
                            : isActiveLink('/yjpara_view')
                            ? 'active'
                            : ''
                        }
                      >
                        <span>단락 관리</span>
                      </Link>
                    </li>
                  </ol>
                </li>
              </ul>
              <ul className='one-menu'>
                <li>
                  <Link
                    to='/bub_search'
                    className={
                      isActiveLink('/bub_search')
                        ? 'active'
                        : isActiveLink('/bub_detail_search')
                        ? 'active'
                        : isActiveLink('/sutras')
                        ? 'active'
                        : ''
                    }
                  >
                    <span>법문검색</span>
                  </Link>
                </li>
              </ul>
            </div>
            <div className={gnbStorage === 'sm' ? 'gnb on' : 'gnb'}>
              <h3>
                <span className='gnb-title'>논문</span>
              </h3>
              <ul className='gnb-submenu on'>
                <li>
                  <button className='gnb-subtitle' onClick={togglebtn}>
                    <span>논문관리</span>
                  </button>
                  <ol>
                    <li>
                      <Link
                        to='/sm_thesis_list'
                        className={
                          isActiveLink('/sm_thesis_list') ||
                          isActiveLink('/sm_write/thesis') ||
                          isActiveLink('/sm_thesis_view')
                            ? 'active'
                            : ''
                        }
                      >
                        <span>학위논문 관리</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='/sm_journal_list'
                        className={
                          isActiveLink('/sm_journal_list') ||
                          isActiveLink('/sm_write/journal') ||
                          isActiveLink('/sm_journal_view') ||
                          isActiveLink('/sm_edit/journal')
                            ? 'active'
                            : ''
                        }
                      >
                        <span>학술지 관리</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='/sm_conference_list'
                        className={
                          isActiveLink('/sm_conference_list') ||
                          isActiveLink('/sm_write/conference') ||
                          isActiveLink('/sm_conference_view') ||
                          isActiveLink('/sm_edit/conference')
                            ? 'active'
                            : ''
                        }
                      >
                        <span>학술대회자료집 관리</span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='/sm_book_list'
                        className={
                          isActiveLink('/sm_book_list') ||
                          isActiveLink('/sm_write/book') ||
                          isActiveLink('/sm_book_view') ||
                          isActiveLink('/sm_edit/book')
                            ? 'active'
                            : ''
                        }
                      >
                        <span>단행본 관리</span>
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        to="/sm_write"
                        className={isActiveLink("/sm_write") ? "active" : "" }
                      >
                        <span>논문등록</span>
                      </Link>
                    </li> */}
                  </ol>
                </li>
              </ul>
              <ul className='one-menu'>
                <li>
                  <Link
                    to='/sm_search'
                    className={
                      isActiveLink('/sm_search')
                        ? 'active'
                        : isActiveLink('/sm_detail_search')
                        ? 'active'
                        : ''
                    }
                  >
                    <span>논문검색</span>
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
        <User userName={userName}></User>
      </div>
    </>
  );
};

export default Header;

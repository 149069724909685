import { useLocation, useOutletContext, useParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';

import BubsearchMenu from '../../components/search/menu';
import SmSearchMenus from '../../components/search/sm_menu';
import ScrapItem from '../../components/search/scrap_item';
import Pagination from '../../components/Pagination';

const ScrapList = () => {
const location = useLocation();
  const [checkLocation, setCheckLocation] = useState(false);
    const [folders, setFolders] = useState([]);
    const [selectedItems, setSelectedItems] = useState('');
    const [findscrap, setFindscrap] = useState([]);
    const queryParams = new URLSearchParams(location.search);
  const typeParam = queryParams.get('type');

  const [sfId, setSfId] = useState('');

  const [currentPage, setCurrentPage] = useState('');
  const [totalItems, setTotalItems] = useState(0);
const itemsPerPage = 10;

  useEffect(() => {
    const regex = /^\/search/;

    const match = location.pathname.match(regex);
    if (match) {
      setCheckLocation(true);
    } else {
      setCheckLocation(false);
    }
  }, [location]);


  useEffect(() => {
    axios
    .get(`${process.env.REACT_APP_SERVER_URL}/scrap/folder/list`, {
      withCredentials: true,
    })
    .then((res) => {
      const folderlist = res.data.find_folder;
      setFolders(folderlist);
      setSfId(folderlist[0].sf_id);
    })
    .catch((err) => {
      console.log(err);
    });
}, []);

useEffect(() => {
if(selectedItems[0] === undefined) {
  axios
      .get(`${process.env.REACT_APP_SERVER_URL}/scrap/list/${sfId}?page=1`, {
        withCredentials: true,
      })
      .then((res) => {
        //console.log('스크랩 목록:', res.data);
        const scraplist = res.data.find_scrap;
        setFindscrap(scraplist);
        setCurrentPage('1'); // 현재 페이지
        setTotalItems(scraplist.length); // 전체 아이템 수
        //console.log(findscrap);
      })
      .catch((err) => {
        console.log(err);
        setFindscrap([]);
      });
}
}, [sfId]);


  const handleCheckboxChange = (id) => {
    setSelectedItems((prevIds) => {
      // 현재 선택 상태 확인
      const isSelected = prevIds.includes(id);

      // 체크박스가 선택되었을 경우, id를 배열에 추가
      // 체크박스가 해제되었을 경우, id를 배열에서 제거
      return isSelected
        ? prevIds.filter((prevId) => prevId !== id)
        : [id];
    });
    };

    const fetchScrapItems = ({pageNumber}) => {
      axios
      .get(`${process.env.REACT_APP_SERVER_URL}/scrap/list/${selectedItems}?page=${pageNumber}`, {
        withCredentials: true,
      })
      .then((res) => {
        //console.log('스크랩 목록:', res.data);
        const scraplist = res.data.find_scrap;
        setFindscrap(scraplist);
        setCurrentPage(pageNumber); // 현재 페이지
        setTotalItems(scraplist.length); // 전체 아이템 수
        //console.log(findscrap);
      })
      .catch((err) => {
        console.log(err);
        setFindscrap([]);
      });
  };

    useEffect(() => {
      fetchScrapItems({pageNumber: 1});
    }, [selectedItems]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // 페이지 변경 시 이벤트 처리
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // 페이지 번호 목록 생성
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i += 1) {
    pageNumbers.push(i);
  }

    return (
        <div className='search'>
      <div className='search-inner'>
        {checkLocation ? null : (
          <div>
            {typeParam === 'bub' ? <p className='page-title'>법문검색</p> : <p className='page-title'>논문검색</p>}
            {typeParam === 'bub' ? <BubsearchMenu/> : <SmSearchMenus/>}
          </div>
        )}
        <div className='scrap_list_wrap'>
        <div className='scrap_folder'>
            <ul>
                {folders.map((folder, index) => (
                    <li key={index}>
                    <input type="radio" id={`radio_${index}`} name='folder'
                    checked={selectedItems[0] === undefined ? index === 0 : selectedItems.includes(folder.sf_id)}
                    onChange={() => handleCheckboxChange(folder.sf_id)}
                    />
                    <label htmlFor={`radio_${index}`}>
                      <span>{folder.sf_name}</span>
                    </label>
                    </li>
                ))}
            </ul>
        </div>
        <div className="scrap_result">
    {findscrap && findscrap.length > 0 ? findscrap.map((scrapData, index) => (
        <ScrapItem key={index} data={scrapData.all_data} allType={scrapData.all_type} allId={scrapData.all_id} csName={scrapData.cs_name} csId={scrapData.cs_id}/>
    )) : <div className='no-data'>스크랩한 데이터가 없습니다.</div>}
    </div>
        </div>
        </div>

        {totalItems > 0 && currentPage && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  )}
        </div>
    );
}

export default ScrapList;
import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

const EpisodeList = () => {
    const [data, setData] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);

    // 데이터를 불러올 함수
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_SERVER_URL}/bub/yj/episode`) // API 엔드포인트 URL
            .then((response) => {
                const dataArray = response.data;
                const yjepilist = dataArray.yjEpiList;
                setData(yjepilist);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    // 삭제하기 버튼 클릭 시
    const handleDeleteSelected = () => {
        const selectedIds = selectedItems.map((item) => item.id);
        if (window.confirm('정말로 삭제하시겠습니까?')) {
            Promise.all(
                selectedIds.map((id) => axios.delete(`${process.env.REACT_APP_SERVER_URL}/bub/yj/episode/${id}`)),
            )
                .then((responses) => {
                    console.log('선택 삭제 성공:', responses);

                    setSelectedItems([]);

                    // 데이터를 다시 불러옴
                    axios
                        .get(`${process.env.REACT_APP_SERVER_URL}/bub/yj/episode`)
                        .then((response) => {
                            const dataArray = response.data;
                            const yjepilist = dataArray.yjEpiList;
                            setData(yjepilist);
                        })
                        .catch((error) => {
                            console.error('Error fetching data:', error);
                        });
                })
                .catch((error) => {
                    console.error('선택 삭제 실패:', error);
                });
        }
    };

    // 선택된 항목 변경 시
    const handleCheckboxChange = (item) => {
        // 선택된 항목의 상태를 토글합니다.
        setSelectedItems((prevSelected) => {
            const isSelected = prevSelected.some((selectedItem) => selectedItem.id === item.id);

            if (isSelected) {
                // 이미 선택된 항목이라면 제거합니다.
                return prevSelected.filter((selectedItem) => selectedItem.id !== item.id);
            } else {
                // 선택되지 않은 항목이라면 추가합니다.
                return [...prevSelected, item];
            }
        });
    };

    return (
        <div className="content">
            <div className="logo-text">한마음과학원</div>
            <div className="category-titles">
                <span>법문관리</span>
                <span>한마음요전</span>
                <span className="status-title">편 관리</span>
            </div>
            <div className="table-wrap">
                <div className="tbl thead">
                    <ul>
                        <li className="chk-box"></li>
                        {/* <li>ID</li> */}
                        <li className="tb_ep">편 이름</li>
                        <li className="set">관리</li>
                    </ul>
                </div>
                <div className="tbl tbody">
                    {data.map((item) => (
                        <ul key={item.id}>
                            <li className="chk-box">
                                <label className="checkbox">
                                    <input
                                        type="checkbox"
                                        checked={selectedItems.some((selectedItem) => selectedItem.id === item.id)}
                                        onChange={() => handleCheckboxChange(item)}
                                    />
                                    <i></i>
                                </label>
                            </li>
                            {/* <td>{item.id}</td> */}
                            <li className="tb_ep m-title">
                                <p>{item.episode}</p>
                            </li>
                            <li className="app-text m-content">
                                <p>{item.episode_shortName}</p>
                            </li>
                            <li className="set">
                                <Link to={`/episode_view/${item.id}`} className="detail-btn c">
                                    상세보기
                                </Link>
                            </li>
                        </ul>
                    ))}
                </div>
            </div>
            <div className="button_wrap">
                <button onClick={handleDeleteSelected} className="delete-btn btn wh">
                    삭제하기
                </button>
                <Link to="/episode_write" className="btn create-btn m-c-c-b">
                    <span>등록하기</span>
                </Link>
            </div>
        </div>
    );
};

export default EpisodeList;

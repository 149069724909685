import { useState, useEffect, useReducer } from 'react';
import { Link, useParams } from 'react-router-dom';
import SmBookComponent from './components/SmBookComponent';
import SmConfComponent from './components/SmConfComponent';
import SmJournalComponent from './components/SmJournalComponent';
import SmThesisComponent from './components/SmThesisComponent';
import SmInfoComponent from './components/SmInfoComponent';
import SmRegComponent from './components/SmRegComponent';
import SmFileComponent from './components/SmFileComponent';
import axios from 'axios';

const initialState = {
  smFile: null,
  smFileName: '',
};

const fileReducer = (state, action) => {
  switch (action.type) {
    case 'SET_FILE':
      return {
        ...state,
        smFile: action.smFile,
      };
    case 'SET_FILENAME':
      return {
        ...state,
        smFileName: action.smFileName,
      };
    default:
      return state;
  }
};

// 학위논문
const SmWrite = () => {
  // 논문 id
  const { categoryUse, documentId } = useParams(null);

  // 논문 기본 정보
  const [smInfo, setSmInfo] = useState({
    SM_Cate_Name: '학위논문',
    SM_Title: '',
    SM_Author: '',
    SM_Publication_YY: '',
    SM_Publication_MM: '',
    SM_Abstract: '',
    SM_Use_Yn: 'Y',
    SM_Tags: [],
  });

  // 등재 정보
  const [smRegInfo, setSmRegInfo] = useState({
    SM_ISBN: '',
    SM_ISSN: '',
    SM_SCI: '',
    SM_SSCI: '',
    SM_AHCI: '',
    SM_SCOPUS: '',
    SM_KCI: '',
  });

  // 단행본 정보
  const [smBookInfo, setSmBookInfo] = useState({
    SM_Books_Name: '',
    SM_Books_Publish: '',
  });

  // 학술대회 정보
  const [smConfInfo, setSmConfInfo] = useState({
    SM_Conference_Name: '',
    SM_Conference_Location: '',
    SM_Conference_Date: '',
  });

  // 학술지 정보
  const [smJournalInfo, setSmJournalInfo] = useState({
    SM_Journal_Area: '국내',
    SM_Journal_Name: '',
    SM_Journal_Volume: '',
    SM_Journal_Issue: '',
    SM_Journal_Research_Institution: '',
  });

  // 학위논문 정보
  const [smThesisInfo, setSmThesisInfo] = useState({
    SM_Thesis_Degree: '',
    SM_Thesis_University: '',
    SM_1: '',
    SM_Thesis_Area: '국내',
  });

  const [fileState, fileDispatch] = useReducer(fileReducer, initialState);

  // 상세보기 => 수정하기에서 취소 버튼 클릭 시 사용할 categoryId
  const [categoryId, setCategoryId] = useState(null);

  // 최초 등록 시 카테고리 이름 설정
  useEffect(() => {
    if (categoryUse) {
      // cate 쿼리값 가져오기
      switch (categoryUse) {
        case 'book':
          setSmInfo({
            ...smInfo,
            SM_Cate_Name: '단행본',
          });
          break;
        case 'thesis':
          setSmInfo({
            ...smInfo,
            SM_Cate_Name: '학위논문',
          });
          break;
        case 'journal':
          setSmInfo({
            ...smInfo,
            SM_Cate_Name: '학술지',
          });
          break;
        case 'conference':
          setSmInfo({
            ...smInfo,
            SM_Cate_Name: '학술대회자료집',
          });
          break;
        default:
          break;
      }
    }
  }, [categoryUse]);

  // 수정 시 기존 데이터 불러오기
  useEffect(() => {
    if (documentId !== null && documentId !== undefined) {
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/sm/update/${documentId}`, {
          responseType: 'json',
        })
        .then((response) => {
          const documentData = response.data[categoryUse][0];
          // 기본정보
          setSmInfo({
            SM_Cate_Name: documentData.SM_Cate_Name ?? '학위논문',
            SM_Title: documentData.SM_Title ?? '',
            SM_Author: documentData.SM_Author ?? '',
            SM_Publication_YY: documentData.SM_Publication_YY,
            SM_Publication_MM: documentData.SM_Publication_MM,
            SM_Abstract: documentData.SM_Abstract,
            SM_UseYn: documentData.SM_Use_Yn,
            SM_Tags: documentData.Tags ? documentData.Tags.split(',') : [],
          });

          // 등재정보
          setSmRegInfo({
            SM_ISBN: documentData.SM_ISBN,
            SM_ISSN: documentData.SM_ISSN,
            SM_SCI: documentData.SM_SCI,
            SM_SSCI: documentData.SM_SSCI,
            SM_AHCI: documentData.SM_AHCI,
            SM_SCOPUS: documentData.SM_SCOPUS,
            SM_KCI: documentData.SM_KCI,
          });

          // 파일 정보
          fileDispatch({
            type: 'SET_FILENAME',
            smFileName: documentData.SM_Attachment_Source,
          });

          // 카테고리 별 정보
          switch (categoryUse) {
            case 'book':
              setSmBookInfo({
                SM_Books_Name: documentData.SM_Books_Name,
                SM_Books_Publish: documentData.SM_Books_Publish,
              });
              setCategoryId(documentData.SM_Books_Id);
              break;
            case 'conference':
              setSmConfInfo({
                SM_Conference_Name: documentData.SM_Conference_Name,
                SM_Conference_Location: documentData.SM_Conference_Location,
                SM_Conference_Date: documentData.SM_Conference_Date,
              });
              setCategoryId(documentData.SM_Conference_Id);
              break;
            case 'journal':
              setSmJournalInfo({
                SM_Journal_Area: documentData.SM_Journal_Area,
                SM_Journal_Name: documentData.SM_Journal_Name,
                SM_Journal_Volume: documentData.SM_Journal_Volume,
                SM_Journal_Issue: documentData.SM_Journal_Issue,
                SM_Journal_Research_Institution:
                  documentData.SM_Journal_Research_Institution,
              });
              setCategoryId(documentData.SM_Journal_Id);
              break;
            case 'thesis':
              setSmThesisInfo({
                SM_Thesis_Degree: documentData.SM_Thesis_Degree,
                SM_Thesis_University: documentData.SM_Thesis_University,
                SM_1: documentData.SM_1,
                SM_Thesis_Area: documentData.SM_Thesis_Area,
              });
              setCategoryId(documentData.SM_Thesis_Id);
              break;
            default:
              break;
          }
        });
    }
  }, [categoryUse, documentId]);

  // 저장 혹은 수정
  const handleSubmit = (e) => {
    e.preventDefault();

    const url = `${process.env.REACT_APP_SERVER_URL}/sm/${
      documentId ? 'update/' + documentId : 'create'
    }`;

    const formData = new FormData();
    const encodedFileName = encodeURIComponent(fileState?.smFileName);

    if (documentId) formData.append('SM_Document_Id', documentId);

    // smInfo를 순회하면서 key, value를 formData에 append
    Object.entries(smInfo).forEach(([key, value]) => {
      if (key === 'SM_Tags') {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    });

    // smRegInfo를 순회하면서 key, value를 formData에 append
    Object.entries(smRegInfo).forEach(([key, value]) => {
      if (key !== 'SM_ISBN' || key !== 'SM_ISSN') {
        if (value === 'Y') {
          value = 'N';
        }
      }
      formData.append(key, value);
    });

    // 카테고리 별 정보
    switch (smInfo.SM_Cate_Name) {
      case '단행본':
        Object.entries(smBookInfo).forEach(([key, value]) => {
          formData.append(key, value);
        });
        break;
      case '학술대회자료집':
        Object.entries(smConfInfo).forEach(([key, value]) => {
          formData.append(key, value);
        });
        break;
      case '학술지':
        Object.entries(smJournalInfo).forEach(([key, value]) => {
          formData.append(key, value);
        });
        break;
      case '학위논문':
        Object.entries(smThesisInfo).forEach(([key, value]) => {
          formData.append(key, value);
        });
        break;
      default:
        return false;
    }

    // 파일이 있을 경우
    if (fileState.smFile !== null) {
      formData.append('file', fileState.smFile, encodedFileName);
    }

    fetch(url, {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        if (response.status === 200) {
          alert(
            `논문 정보가 성공적으로 ${documentId ? '수정' : '저장'}되었습니다.`,
          );
          window.location.href = `/sm_${
            categoryUse === 'book' ? 'books' : categoryUse
          }_list`;
        } else {
          alert(`논문 정보 ${documentId ? '수정' : '저장'}에 실패했습니다.`);
        }
      })
      .catch((error) => {
        alert(`논문 정보 ${documentId ? '수정' : '저장'}에 실패했습니다.`);
        console.log(error);
      });
  };

  // 카테고리 별 정보
  let ComponentToRender = null;
  let ComponentInfo = null;
  let setComponentInfo = null;

  switch (smInfo.SM_Cate_Name) {
    case '단행본':
      ComponentToRender = SmBookComponent;
      ComponentInfo = smBookInfo;
      setComponentInfo = setSmBookInfo;
      break;
    case '학술대회자료집':
      ComponentToRender = SmConfComponent;
      ComponentInfo = smConfInfo;
      setComponentInfo = setSmConfInfo;
      break;
    case '학술지':
      ComponentToRender = SmJournalComponent;
      ComponentInfo = smJournalInfo;
      setComponentInfo = setSmJournalInfo;
      break;
    case '학위논문':
      ComponentToRender = SmThesisComponent;
      ComponentInfo = smThesisInfo;
      setComponentInfo = setSmThesisInfo;
      break;
    default:
      ComponentToRender = null;
      ComponentInfo = null;
      setComponentInfo = null;
      break;
  }

  // 출력
  return (
    <div className='content'>
      <div className='logo-text'>한마음과학원</div>
      <div className='category-titles'>
        <span>논문관리</span>
      </div>
      <div className='page-head'>
        <span>{!documentId ? '논문 정보 등록' : '논문 정보 수정'}</span>
      </div>
      <form className='data-form paper-wrap' onSubmit={handleSubmit}>
        <SmInfoComponent info={smInfo} setInfo={setSmInfo} />
        <SmRegComponent info={smRegInfo} setInfo={setSmRegInfo} />
        <div className='form-inner'>
          <ComponentToRender info={ComponentInfo} setInfo={setComponentInfo} />
        </div>
        <SmFileComponent fileState={fileState} fileDispatch={fileDispatch} />
        <div className='button_wrap'>
          <Link
            to={
              documentId
                ? `/sm_${categoryUse}_view/${categoryId}`
                : `/sm_${categoryUse}_list`
            }
            className='btn cancle-btn'
          >
            취소하기
          </Link>
          <button type='submit' className='btn blk'>
            {documentId ? '수정하기' : '등록하기'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SmWrite;

import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import TextWithToggle from './textWithToggle';
//import Print from './Print';

const Print = ({ printRef, print, title, cate, data }) => {
  //const { printRef } = printRef;
  return (
    <div ref={printRef} className='print'>
      {cate === 'yj' ? (
        <>
          <div className='print-inner'>
            <p className='print-title'>{print?.title || print.subtitle}</p>
            <div className='print-opts'>
              <p className='cate-title'>{title}</p>
              {print.bdate ? (
                <p className='dday'>
                  <span className='label'>발행날짜 : </span>
                  <span>{print.bdate}</span>
                </p>
              ) : null}
            </div>
            <p className='print-paragraph'>{print.paragraph}</p>
          </div>
        </>
      ) : (
        <>
          <div className='print-inner'>
            <p className='print-title'>{print?.title || print.subtitle}</p>
            <div className='print-opts'>
              {print.bdate ? (
                <p className='dday'>
                  <span className='label'>발행날짜 : </span>
                  <span>{print.bdate}</span>
                </p>
              ) : null}
            </div>
            <ul className='print-paragraph'>
              {data.map((bubpara, index) => {
                return (
                  <li key={index}>
                    <p>{bubpara.paragraph}</p>
                    <br />
                    <span>
                      [{bubpara.category_name} Vol.{bubpara.title_vol} P.
                      {bubpara.paragraph_page}]
                    </span>
                    <br />
                    <hr />
                  </li>
                );
              })}
            </ul>
          </div>
        </>
      )}
    </div>
  );
};

const DetailView = ({
  item,
  openDetail,
  closeDetail,
  cate,
  data,
  marker,
  id,
}) => {
  const location = useLocation();
  const [checkLocation, setCheckLocation] = useState(false);

  const [yjInfo, setYjInfo] = useState('');
  const [sInfo, setSInfo] = useState('');

  useEffect(() => {
    const regex = /^\/search/;

    const match = location.pathname.match(regex);
    if (match) {
      setCheckLocation(true);
    } else {
      setCheckLocation(false);
    }
  }, [location]);

  const targetRef = useRef(null);

  useEffect(() => {
    if (targetRef.current) {
      const windowHeight = document.querySelector(
        '.detail_view__content__body',
      ).clientHeight;
      const targetBody = document.querySelector('.detail_view__content__body');
      const targetRect = targetRef.current.getBoundingClientRect();
      const targetMiddlePosition = targetRect.top + targetRect.height / 2;
      const scrollToPosition = targetMiddlePosition - windowHeight / 1.3;

      targetBody.scrollTo({
        top: scrollToPosition,
      });
    }
  }, [targetRef, data, item, id, cate]);

  useEffect(() => {
    if (cate === 'yj') {
      const sourceDate = item.publication_date.substring(0, 4);
      const yjsourceInfo = `[출처 : ${item.publisher}, 『한마음요전 개정판』 ${item.author}(${sourceDate})]`;

      setYjInfo(yjsourceInfo);
    } else {
      const bdYear = item.publication_date.substring(0, 4);
      const sourceInfo = `[출처 : ${item.publisher}, 『허공을 걷는 길 : ${item.category_name} ${item.title_vol}권』 ${item.author}(${bdYear}), ${item.paragraph_page}쪽]`;

      setSInfo(sourceInfo);
    }
  }, [openDetail, closeDetail, item, cate, id]);

  const myRef = useRef(null);

  const sentence = item.paragraph;
  const regex = new RegExp(`(${marker})`, 'gi');
  const parts = sentence.split(regex);

  const onClickEvent = () => {
    handlePrint();
  };

  /* print */
  const handlePrint = useReactToPrint({
    content: () => myRef.current,
  });

  if (!openDetail) return null;

  const title_span = item?.category_name
    ? `[${item.category_name} Vol.${item.title_vol} P.${item.paragraph_page}]`
    : `${item.navigation}`;

  const yjhandleCopy = (event) => {
    const copiedText = window.getSelection().toString();

    // Combine the copied text and source information
    const textWithSource = `${copiedText}\n\n${yjInfo}`;

    // Write the combined text to the clipboard
    event.clipboardData.setData('text/plain', textWithSource);

    // Prevent the normal copy behavior
    event.preventDefault();
  };

  const whiteSpace = {
    whiteSpace: 'pre-wrap',
  };

  return (
    <>
      {cate === 'yj' ? (
        <div className='modal'>
          <div className='detail_view'>
            <div className='detail_view__content detail'>
              <div className='detail_view__content__header fordetail-header'>
                <h3>{item?.title || item.subtitle}</h3>
                <ul className='header-info-list'>
                  <li>
                    <span>{title_span}</span>
                  </li>
                  {item.bdate ? (
                    <li className='day'>
                      <span>발행날짜</span>
                      <span>{item.bdate}</span>
                    </li>
                  ) : null}
                </ul>
                <ul className='header-info-list'>
                  <li>
                    <span> {yjInfo}</span>
                  </li>
                </ul>
                <a onClick={onClickEvent} className='print-btn'>
                  인쇄하기
                </a>
                <Print
                  printRef={myRef}
                  print={item}
                  title={title_span}
                  cate='yj'
                  data={data}
                />

                <p className=''></p>
                <button onClick={closeDetail} className='close-btn'>
                  X
                </button>
              </div>
              <div className='detail_view__content__body fordetail-body'>
                <ul className='detail-text' onCopy={yjhandleCopy}>
                  <li>
                    <p style={whiteSpace}>
                      {marker
                        ? parts.map((part, index) =>
                            regex.test(part) ? <b key={index}>{part}</b> : part,
                          )
                        : item?.paragraph}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div
            className={`${
              checkLocation
                ? 'detail_view__overlay_search'
                : 'detail_view__overlay'
            }`}
            onClick={closeDetail}
          ></div>
        </div>
      ) : (
        <div className='modal'>
          <div className='detail_view'>
            <div className='detail_view__content detail'>
              <div className='detail_view__content__header fordetail-header'>
                <h3>{item?.title || item.subtitle}</h3>
                <ul className='header-info-list'>
                  {item.bdate ? (
                    <li className='day'>
                      <span>법문날짜</span>
                      <span>{item.bdate}</span>
                    </li>
                  ) : null}
                </ul>
                <ul className='header-info-list'>
                  <li>
                    <span> {sInfo}</span>
                  </li>
                </ul>
                <a
                  href='javascript:void(0);'
                  onClick={onClickEvent}
                  className='print-btn'
                >
                  인쇄하기
                </a>
                <Print
                  printRef={myRef}
                  print={item}
                  title={title_span}
                  cate='bub'
                  data={data}
                />

                <p className=''></p>
                <button onClick={closeDetail} className='close-btn'>
                  X
                </button>
              </div>
              <div className='detail_view__content__body fordetail-body'>
                <ul className='detail-text'>
                  {data.map((bubpara, index) => {
                    const handleCopy = (event) => {
                      const copiedText = window.getSelection().toString();

                      // Combine the copied text and source information
                      const textWithSource = `${copiedText}\n\n${sInfo}`;

                      // Write the combined text to the clipboard
                      event.clipboardData.setData('text/plain', textWithSource);

                      // Prevent the normal copy behavior
                      event.preventDefault();
                    };

                    const bubsentence = bubpara?.paragraph;
                    const bubregex = new RegExp(`(${marker})`, 'gi');
                    const bubparts = bubsentence.split(bubregex);

                    return (
                      <li
                        key={index}
                        onCopy={handleCopy}
                        ref={item.id === bubpara.id ? targetRef : null}
                        className={item.id === bubpara.id ? 'targetRef' : null}
                      >
                        <p style={whiteSpace}>
                          {marker
                            ? bubparts.map((part, index) =>
                                bubregex.test(part) ? (
                                  <b key={index}>
                                    {
                                      <TextWithToggle
                                        originalText={part}
                                        page={bubpara?.paragraph_page}
                                      />
                                    }
                                  </b>
                                ) : (
                                  <TextWithToggle
                                    originalText={part}
                                    page={bubpara?.paragraph_page}
                                  />
                                ),
                              )
                            : null}
                        </p>
                        <br />
                        <span>
                          [{bubpara.category_name} Vol.{bubpara.title_vol} P.
                          {bubpara.paragraph_page}]
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div
            className={`${
              checkLocation
                ? 'detail_view__overlay_search'
                : 'detail_view__overlay'
            }`}
            onClick={closeDetail}
          ></div>
        </div>
      )}
    </>
  );
};

export default DetailView;

import { useLocation } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Keyword from '../../components/search/keyword';
import SmSearchResult from '../../components/search/sm_result';
import SmsubMenu from '../../components/search/sm_submenu';
import SmsearchMenu from '../../components/search/sm_menu';
import Pagination from '../../components/Pagination';
// import '../../styles/search.scss';
const Smsearch = () => {
  const location = useLocation();
  const [currentCate, setCurrentCate] = useState('10000');
  const [currentPage, setCurrentPage] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [searchData, setSearchData] = useState([]);
  const itemsPerPage = 10;
  const [searchText, setSearchText] = useState('');
  const [keyWord, setKeyword] = useState('');
  const [data, setData] = useState(null); // 받아온 데이터를 저장할 상태
  const [totaldata, setTotaldata] = useState(null); // 받아온 데이터를 저장할 상태
  const searchDataLists = [];
  const items = [];

  const inputRef = useRef(null);

  const [checkLocation, setCheckLocation] = useState(false);

  useEffect(() => {
    const regex = /^\/search/;

    const match = location.pathname.match(regex);
    if (match) {
      setCheckLocation(true);
    } else {
      setCheckLocation(false);
    }
  }, [location]);

  useEffect(() => {
    // 스크롤 이벤트 리스너 등록
    const handleScroll = () => {
      const scrollY = window.scrollY;

      // 스크롤 위치를 부모 창으로 전달
      window.parent.postMessage({ type: 'scroll', scrollY }, '*');
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  // 최초 렌더링 시 데이터 요청
  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/sm/search`, {
        search: '',
      })
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // 검색어 입력 시 이벤트 처리
  const handleSearch = () => {
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/sm/search?cate=${currentCate}&page=${currentPage}`,
        {
          search: searchText,
        },
      )
      .then((res) => {
        setData(res.data);
        setTotaldata(res.data);
        setTotalItems(res.data.total_count);
        setKeyword(searchText);
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message || '검색어가 없습니다');
      });
  };
  // 검색어 입력 시 이벤트 처리
  const fetchCateData = (categoryCode, pageNumber) => {
    if (searchText) {
      axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/sm/search?cate=${categoryCode}&page=${pageNumber}`,
          {
            search: searchText,
          },
        ) // API 엔드포인트 URL
        .then((res) => {
          setData(res.data);
          setTotaldata(res.data);
          setTotalItems(res.data.total_count);
          setCurrentCate(categoryCode); // 현재 카테고리
          setCurrentPage(pageNumber); // 현재 페이지
        })
        .catch((err) => {
          console.log(err);
          alert(err.response.data.message || '검색어가 없습니다');
        });
    }
  };
  // 현재 카테고리에 따라 데이터 요청
  useEffect(() => {
    fetchCateData(currentCate, currentPage);
  }, [currentCate, currentPage]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // 페이지 변경 시 이벤트 처리
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // 전체 아이템 수 변경 시 이벤트 처리
  const handleTotalChange = (totalItems) => {
    setTotalItems(totalItems);
  };
  // 카테고리 변경 시 이벤트 처리
  const handleCateChange = (categoryCode, totalItems) => {
    setCurrentCate(categoryCode);
    //setSearchData([]);
    setTotalItems(totalItems);
  };
  // 페이지 번호 목록 생성
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  // 전체페이지 이동시 이벤트 처리
  const searchhandleCatereset = () => {
    setCurrentCate('10000');
    setCurrentPage('');
    setSearchData([]);

    const url = `${location.pathname}?cate=10000`;
    window.history.pushState({ path: url }, '', url);
  };

  // 셀렉박스 변경 시 이벤트 처리
  const handleSelectChange = (e) => {
    if (keyWord !== '') {
      setCurrentCate(e.target.value);
      setCurrentPage('1');
      if (e.target.value === '10000') {
        setCurrentPage('');
        setSearchData([]);

        const url = `${location.pathname}?cate=10000`;
        window.history.pushState({ path: url }, '', url);
      } else {
        const url = `${location.pathname}?cate=${e.target.value}&page=1`;
        window.history.pushState({ path: url }, '', url);
      }
    } else {
      setCurrentCate(e.target.value);
    }
  };

  // 카테고리 이름과 코드를 매핑한 객체 생성
  const categoryMapping = {
    10004: '학위논문',
    10003: '학술지',
    10002: '학술대회자료집',
    10001: '단행본',
    10000: '전체',
  };
  const CateChange = (categoryCode, totalItems) => {
    handleCateChange(categoryCode);
    handlePageChange(1);
    setTotalItems(totalItems);
    const url = `${location.pathname}?cate=${categoryCode}&page=1`;
    window.history.pushState({ path: url }, '', url);
  };

  // 검색어가 입력되었을 때만 요청 보내도록 설정
  useEffect(() => {
    if (data) {
      const newSearchDataLists = [];
      if (data.thesis_searchList && data.thesis_searchList.length > 0) {
        newSearchDataLists.push({
          list: data.thesis_searchList,
          listcount: data.thesis_count,
          cate: '10004',
        });
        items.push(data.thesis_count);
      }
      if (data.journal_searchList && data.journal_searchList.length > 0) {
        newSearchDataLists.push({
          list: data.journal_searchList,
          listcount: data.journal_count,
          cate: '10003',
        });
        items.push(data.journal_count);
      }
      if (data.conference_searchList && data.conference_searchList.length > 0) {
        newSearchDataLists.push({
          list: data.conference_searchList,
          listcount: data.conference_count,
          cate: '10002',
        });
        items.push(data.conference_count);
      }
      if (data.books_searchList && data.books_searchList.length > 0) {
        newSearchDataLists.push({
          list: data.books_searchList,
          listcount: data.books_count,
          cate: '10001',
        });
        items.push(data.books_count);
      }

      setSearchData(newSearchDataLists);
    }
  }, [data]);

  return (
    <div id='target' className='search'>
      <div className='search-inner'>
        {checkLocation ? null : (
          <div>
            <p className='page-title'>논문검색</p>
            <SmsearchMenu />
          </div>
        )}
        <div className={`search-box ${checkLocation ? 'delete-border' : ''}`}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSearch(); // 검색 버튼 클릭 시 검색 요청 보내기
            }}
          >
            <div className='input-wrap'>
              <div className='search-box__input search-box_flex'>
                <select onChange={handleSelectChange} value={currentCate}>
                  <option value='10000'>전체</option>
                  <option value='10004'>학위논문</option>
                  <option value='10003'>학술지</option>
                  <option value='10002'>학술대회자료집</option>
                  <option value='10001'>단행본</option>
                </select>
                <input
                  type='text'
                  value={searchText}
                  placeholder='검색어를 입력하세요.'
                  onChange={(e) => setSearchText(e.target.value)}
                  ref={inputRef}
                />
              </div>
              <div className='search-box__button'>
                <button type='submit'>검색</button>
              </div>
            </div>
            <p className='comment bottom-search'>
              ※ 검색 단어로 여러 단어 입력이 가능합니다. 예: 단행
            </p>
          </form>
        </div>
        {data && keyWord && (
          <SmsubMenu
            onCateChange={handleCateChange}
            onPageChange={handlePageChange}
            handleCatereset={searchhandleCatereset}
            ontotalChange={handleTotalChange}
            dataArray={searchData}
            totalArray={totaldata}
            currentCate={currentCate}
          />
        )}
        {data && keyWord && (
          <Keyword keyword={keyWord} keyword_count={totalItems} />
        )}
        {data &&
          searchData.length > 0 &&
          searchData.map((searchList, index) => {
            return (
              <div className='result-box' key={`searchResult-${index}`}>
                {data && !currentPage && (
                  <div className='moreview'>
                    {data &&
                      searchList &&
                      searchList.list &&
                      searchList.list[0] && (
                        <h3>
                          {categoryMapping[searchList?.cate]}
                          {keyWord && (
                            <span>{searchList.listcount[0]?.count ?? 0}</span>
                          )}
                        </h3>
                      )}
                    {data &&
                      searchList &&
                      searchList.list &&
                      searchList.list[0] &&
                      keyWord && (
                        <button
                          className='more-btn'
                          onClick={() =>
                            CateChange(
                              searchList?.cate,
                              searchList?.listcount[0].count,
                            )
                          }
                        >
                          <span>더보기</span>
                        </button>
                      )}
                  </div>
                )}
                <SmSearchResult
                  searchList={searchList}
                  currentPage={currentPage}
                  categoryMapping={searchList?.cate}
                />
              </div>
            );
          })}

        {totalItems > 0 && currentPage && (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
        {/* {totalItems === 0 && searchData.length === 0 && <p>검색어가 없습니다.</p>} */}
        {data === null ? (
          <p></p>
        ) : totalItems === 0 && searchData.length === 0 ? (
          <p>검색어가 없습니다.</p>
        ) : null}
      </div>
    </div>
  );
};
export default Smsearch;

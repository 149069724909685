const InputParagraph = ({ props }) => {
  return (
    <div className='search-input'>
      <label>{props.searchInput.para.name}</label>
      <input
        type='text'
        className='bub-paragraph-input'
        placeholder='단락'
        value={props.searchInput.para.value}
        onChange={(e) => {
          props.setSearchInput({
            ...props.searchInput,
            para: {
              ...props.searchInput.para,
              value: e.target.value,
            },
          });
        }}
      />
    </div>
  );
};

export default InputParagraph;

import React from 'react';
import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';

const TitleView = () => {
  const { id } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/bub/title/${id}`) // API 엔드포인트 URL
      .then((response) => {
        const dataArray = response.data; // API에서 받아온 데이터를 배열로 변환
        const bubTitleView = dataArray.bubTitle[0];
        setData(bubTitleView);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [id]);

  if (!data) {
    // 데이터를 아직 불러오지 못한 경우 로딩 상태를 표시할 수도 있습니다.
    return <div>Loading...</div>;
  }

  const handleDelete = () => {
    if (window.confirm('정말로 삭제하시겠습니까?')) {
      axios
        .delete(`${process.env.REACT_APP_SERVER_URL}/bub/title/${id}`)
        .then((response) => {
          console.log('삭제 성공:', response.data);
          window.location.pathname = '/title_list';
        })
        .catch((error) => {
          console.error('삭제 실패:', error);
        });
    }
  };

  console.log(data);

  return (
    <div className='content'>
      <div className='logo-text'>한마음과학원</div>
      <div className='category-titles'>
        <span>법문관리</span>
        <span>허공을 걷는 길</span>
        <span className='status-title'>제목 관리</span>
      </div>
      <div className='page-head'>
        <span>{data.title}</span>
      </div>
      <div className='data-form'>
        <div className='form-inner'>
          <div className='division'>
            <div className='data-box'>
              <label>카테고리 이름</label>
              <div className='data-in-box readonly'>{data.category_name}</div>
            </div>
            <div className='data-box'>
              <label>제목 이름</label>
              <div className='data-in-box readonly'>{data.title}</div>
            </div>
          </div>
          <div className='division'>
            <div className='data-box'>
              <label>Vol</label>
              <div className='data-in-box readonly'>{data.title_vol}</div>
            </div>
            <div className='data-box'>
              <label>단락 순서</label>
              <div className='data-in-box readonly'>{data.title_seq}</div>
            </div>
          </div>
          <div className='division'>
            <div className='data-box'>
              <label>법문날짜</label>
              <div className='data-in-box readonly'>{data.bdate}</div>
            </div>
            <div className='data-box'>
              <label>사용 여부</label>
              <div className='data-in-box readonly'>{data.use_yn}</div>
            </div>
          </div>
        </div>
      </div>

      <div className='button_wrap'>
        <Link to={`/title_edit/${id}`} className='btn wh'>
          수정하기
        </Link>
        <button onClick={handleDelete} className='btn wh'>
          삭제하기
        </button>
        <Link to='/title_list' className='btn blk'>
          목록보기
        </Link>
      </div>
    </div>
  );
};

export default TitleView;

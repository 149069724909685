import React from 'react';
import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';

const SmconferenceView = () => {
    const { conference_id } = useParams();
    const [data, setData] = useState(null);
    const [file, setFile] = useState(null);

    console.log(data);

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_SERVER_URL}/sm/conference/${conference_id}`) // API 엔드포인트 URL
            .then((response) => {
                const dataArray = response.data; // API에서 받아온 데이터를 배열로 변환
                const smConferenceView = dataArray.conference[0];
                const smConferenceFile = dataArray;
                setData(smConferenceView);
                setFile(smConferenceFile);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, [conference_id]);

    if (!data) {
        // 데이터를 아직 불러오지 못한 경우 로딩 상태를 표시할 수도 있습니다.
        return <div>Loading...</div>;
    }

    const handleDownload = (e) => {
        e.preventDefault();

        axios
            .get(`${process.env.REACT_APP_SERVER_URL}/sm/download/${data.SM_Document_Id}`, {
                responseType: 'json',
            })
            .then((response) => {
                const { fileData } = response.data;
                const dataUrl = 'data:application/pdf;base64,' + fileData;
                const a = document.createElement('a');
                a.href = dataUrl;
                a.download = data.SM_Attachment_Source;
                a.click();
            })
            .catch((error) => {
                console.error('파일 다운로드 중 오류 발생:', error);
            });
    };

  const handleDelete = () => {
    if (window.confirm("정말로 삭제하시겠습니까?")) {
      axios
        .delete(
          `${process.env.REACT_APP_SERVER_URL}/sm/delete/${data.SM_Document_Id}`
        )
        .then((response) => {
          console.log("삭제 성공:", response.data);
          window.location.pathname = "/sm_conference_list";
        })
        .catch((error) => {
          console.error("삭제 실패:", error);
        });
    }
  };
    // 등재정보 갯수 오브젝트
  const infoObj = {
    values: [
      data.SM_SCI,
      data.SM_KCI,
      data.SM_AHCI,
      data.SM_SCOPUS,
      data.SM_SSCI
    ]
  }

  const countN = infoObj.values.filter(value => value === "N").length;

  return (
    <div className="content">
      <div className="logo-text">한마음과학원</div>
      <div className="category-titles">
        <span>논문관리</span>
        <span className="status-title">{data.SM_Cate_Name} 관리</span>
      </div>
      <div className="page-head">
        {/* <span>{data.SM_Cate_Name}</span> */}
        <span className="page-title">{data.SM_Title}</span>
      </div>
      <div className="data-form paper-wrap">
      <div className="form-inner">
      <div className="division">
        <div className="data-box">
          <label>문서의 유형</label>
          <div className="data-in-box readonly">{data.SM_Cate_Name}</div>
        </div>
        <div className="data-box">
          <label>문서의 제목</label>
          <div className="data-in-box readonly">{data.SM_Title}</div>
        </div>
      </div>
      <div className="division">
        <div className="data-box">
          <label>저자</label>
          <div className="data-in-box readonly">{data.SM_Author}</div>
        </div>
        <div className="division">
          <div className="data-box">
            <label>발행일자</label>
            <div className="data-in-box readonly">{data.SM_Publication_YY}</div>
          </div>
          <div className="data-box">
            <label className="hidden">문서의 발행월</label>
            <div className="data-in-box readonly">{data.SM_Publication_MM}</div>
          </div>
        </div>
      </div>
      <div className="data-box">
        <label>태그/주제어</label>
        <div className="data-in-box readonly">{data.Tags}</div>
      </div>
      <div className="data-box">
        <label>초록</label>
        <div className="abstract-display data-in-box readonly para" dangerouslySetInnerHTML={{ __html: data.SM_Abstract.split('\n').join('<br />') }} />
      </div>
      <div className="data-box">
          <label>사용 여부</label>
          <div className="data-in-box readonly">{data.SM_Use_Yn}</div>
      </div>
    </div>
    <div className="form-inner">
      <div className="data-box">
        <label>등재정보</label>
        {countN < infoObj.values.length ? (
        <div className="detail-btn-list">
        <a
            href={`//${data.SM_SCI}`}
            target="_blank"
            className={data.SM_SCI !== 'N' ? 'sm-detail-btn' : 'sm-detail-btn button-hidden'}
        >
            SCI
        </a>
        <a
            href={`//${data.SM_SSCI}`}
            target="_blank"
            className={data.SM_SSCI !== 'N' ? 'sm-detail-btn' : 'sm-detail-btn button-hidden'}
        >
            <span>SSCI</span>
        </a>
        <a
            href={`//${data.SM_AHCI}`}
            target="_blank"
            className={data.SM_AHCI !== 'N' ? 'sm-detail-btn' : 'sm-detail-btn button-hidden'}
        >
            <span>AHCI</span>
        </a>
        <a
            href={`//${data.SM_SCOPUS}`}
            target="_blank"
            className={data.SM_SCOPUS !== 'N' ? 'sm-detail-btn' : 'sm-detail-btn button-hidden'}
        >
            <span>SCOPUS</span>
        </a>
        <a
            href={`//${data.SM_KCI}`}
            target="_blank"
            className={data.SM_KCI !== 'N' ? 'sm-detail-btn' : 'sm-detail-btn button-hidden'}
        >
            <span>KCI</span>
        </a>
    </div>
      ) : (
        <p style={{fontSize:"15px"}}>등록된 정보가 없습니다.</p>
      )}
      </div>
      <div className="division">
            <div className="data-box">
              <label>ISBN</label>
              <div className="data-in-box readonly">{data.SM_ISBN}</div>
            </div>
            <div className="data-box">
              <label>ISSN</label>
              <div className="data-in-box readonly">{data.SM_ISSN}</div>
            </div>
          </div>
    </div>
    <div className="form-inner">
        <div className="division">
          <div className="data-box">
            <label>학회명</label>
            <div className="data-in-box readonly">{data.SM_Conference_Name}</div>
          </div>
          <div className="data-box">
            <label>학회 장소</label>
            <div className="data-in-box readonly">{data.SM_Conference_Location}</div>
          </div>
        </div>
        <div className="data-box">
            <label>학회 개최일자</label>
            <div className="data-in-box readonly">{data.SM_Conference_Date}</div>
          </div>
    </div>
    <div className="form-inner form-section pages-type2-section download-wrap">
    {data.SM_Attachment_Source ? (
              <div className="data-box download">
            <span className="label">첨부파일</span>
            <div className="data-in-box download" onClick={handleDownload}>
                {data.SM_Attachment_Source}
            </div>
        </div>
    ) : (
      <div className="data-box file">
          <p className="file-name">첨부된 파일이 없습니다.</p>
      </div>
    )}
    </div>
      </div>
            <div className="button_wrap">
                <Link to={`/sm_edit/conference/${data.SM_Document_Id}`} className="btn wh">
                    수정하기
                </Link>
                <button onClick={handleDelete} className="btn wh">
                    삭제하기
                </button>
                <Link to="/sm_conference_list" className="btn blk">
                    목록보기
                </Link>
            </div>
        </div>
    );
};

export default SmconferenceView;

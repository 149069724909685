import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Useyn from '../../components/Useyn';

const EpisodeWrite = () => {
    const { id } = useParams();
    const [epiName, setEpiName] = useState('');
    const [epiShortname, setEpiShortname] = useState('');
    const [epiUse, setEpiUse] = useState('N'); // 카테고리 이름을 저장하는 state

    const [epiPublisher, setEpiPublisher] = useState('');
    const [epiAuthor, setEpiAuthor] = useState('');
    const [epiDate, setEpiDate] = useState('');

    // 수정할 때, 데이터를 불러올 함수
    useEffect(() => {
        if (id) {
            axios
                .get(`${process.env.REACT_APP_SERVER_URL}/bub/yj/episode/${id}`)
                .then((response) => {
                    const episodeData = response.data.yjEpisode[0]; // 첫 번째 요소에 있는 카테고리 정보
                    setEpiName(episodeData.episode);
                    setEpiShortname(episodeData.episode_shortName);
                    setEpiUse(episodeData.use_yn);
                    setEpiPublisher(episodeData.publisher);
                    setEpiAuthor(episodeData.author);
                    setEpiDate(episodeData.publication_date);
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        }
    }, [id]);

    // 등록, 수정버튼 클릭 시 실행되는 함수
    const handleSubmit = (e) => {
        e.preventDefault();

        const postData = {
            episode: epiName,
            episode_shortName: epiShortname,
            use_yn: epiUse,
        };

        if (id) {
            if (window.confirm('정말로 수정하시겠습니까?')) {
                axios
                    .patch(`${process.env.REACT_APP_SERVER_URL}/bub/yj/episode/${id}`, postData)
                    .then((response) => {
                        console.log('수정 성공:', response.data);
                        // 수정 성공 후, 상세보기 페이지로 이동
                        window.location.pathname = `/episode_view/${id}`;
                    })
                    .catch((error) => {
                        console.error('수정 실패:', error);
                        alert(error.response.data.message);
                    });
            }
        } else {
            // 새로운 카테고리 등록
            axios
                .post(`${process.env.REACT_APP_SERVER_URL}/bub/yj/episode`, postData)
                .then((response) => {
                    console.log('등록 성공:', response.data);
                    // 등록 성공 후, 목록 페이지로 이동
                    window.location.pathname = '/episode_list';
                })
                .catch((error) => {
                    console.error('등록 실패:', error);
                    alert(error.response.data.message);
                });
        }
    };

    return (
        <div className="content">
            <div className="logo-text">한마음과학원</div>
            <div className="category-titles">
                <span>법문관리</span>
                <span>한마음 요전</span>
                <span className="status-title">편 관리</span>
            </div>
            <div className="page-head">
                <span>{id ? '편 관리 수정' : '편 관리 등록'}</span>
            </div>
            <form onSubmit={handleSubmit} className="data-form">
                <div className="form-inner">
                    <div className="data-box">
                        <label>편 이름</label>
                        <input
                            type="text"
                            className="data-in-box"
                            value={epiName}
                            onChange={(e) => setEpiName(e.target.value)}
                        />
                    </div>
                    <div className="division">
                        <div className="data-box">
                            <label>편 단축이름</label>
                            <input
                                type="text"
                                className="data-in-box"
                                value={epiShortname}
                                onChange={(e) => setEpiShortname(e.target.value)}
                            />
                        </div>
                        <Useyn useyn={epiUse} onChange={(e) => setEpiUse(e.target.value)} />
                    </div>
                    <div className="data-box">
                            <label>출판사</label>
                            <input
                                type="text"
                                className="data-in-box"
                                value={epiPublisher}
                                onChange={(e) => setEpiPublisher(e.target.value)}
                            />
                        </div>
                        <div className="data-box">
                            <label>저자</label>
                            <input
                                type="text"
                                className="data-in-box"
                                value={epiAuthor}
                                onChange={(e) => setEpiAuthor(e.target.value)}
                            />
                        </div>
                        <div className="data-box">
                            <label>법문날짜</label>
                            <input
                                type="text"
                                className="data-in-box"
                                value={epiDate}
                                onChange={(e) => setEpiDate(e.target.value)}
                            />
                        </div>
                </div>
                <div className="button_wrap">
                    <Link to="/episode_list" className="btn wh">
                        취소
                    </Link>
                    <button type="submit" className="btn blk">
                        {id ? '수정' : '등록'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default EpisodeWrite;

import { useEffect, useState } from 'react';
import ScrapView from './scrap_view';
import axios from 'axios';

const ScrapItem = ({ allType, allId, csName, data, csId }) => {
  const [isDetailOpen, setIsDetailOpen] = useState(false);

  let body = document.querySelector('body');

  let title_span = '';

  if (allType === 'yj') {
    title_span = `${data[0].navigation}`;
  } else if (allType === 'hgg') {
    title_span = `[${data[0].category_name} Vol.${data[0].title_vol} P.${data[0].title_seq}]`;
  }

  const openDetail = () => {
    setIsDetailOpen(true);

    body.classList.add('on');
  };

  const closeDetail = () => {
    setIsDetailOpen(false);

    body.classList.remove('on');
  };

  const hadleDeleteScrap = () => {
    if (window.confirm('정말로 삭제하시겠습니까?')) {
    axios
      .delete(`${process.env.REACT_APP_SERVER_URL}/scrap/delete/${csId}`, {
        withCredentials: true,
      })
      .then((res) => {
        console.log(res);
        alert('삭제되었습니다.');
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
    }
  }

  return (
    <>
    <div className='bub_item'>
      <div className='bub_item__title'>
        {title_span && <span>{title_span}</span>}
        <h3>[{csName}] {data[0].title || data[0].subtitle || data[0].SM_Title}</h3>
      </div>
      <div className='bub_item__content'>
        {allType === 'yj' || allType === 'hgg' ? (
        <p>
          {data[0].paragraph && data[0].paragraph.length > 150
            ? `${data[0].paragraph.slice(0, 150)}...`
            : data[0].paragraph}
        </p>
        ) : (
          <>
          <p>
          {data[0].SM_Abstract && data[0].SM_Abstract.length > 150
            ? `${data[0].SM_Abstract.slice(0, 150)}...`
            : data[0].SM_Abstract}
        </p>
        <div>
                    <span>{data[0].SM_Author}</span>
                    <span>{data[0].SM_Publication_YY}. {data[0].SM_Publication_MM}</span>
                </div>
        </>
        )}
      </div>
      <div className='btn-options'>
            <button onClick={openDetail} className='opts-btn'>
              상세보기
            </button>
            <ScrapView
              item={data}
              openDetail={isDetailOpen}
              closeDetail={closeDetail}
              cate={allType}
            />
            <button  className='opts-btn' onClick={(e) => {
              hadleDeleteScrap();
              }}>
              삭제하기
            </button>
      </div>
    </div>
    </>
  );
};

export default ScrapItem;

import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Pagination from '../../components/Pagination';
import YjSearchComponent from '../../components/list/YjSearchComponent';

const YjparaList = () => {
  const [data, setData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [subData, setSubData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  // URL에서 페이지 번호 파라미터를 추출
  const queryParams = new URLSearchParams(window.location.search);
  const searchKeyword = queryParams.get('search') || '';
  const currentPage = Number(queryParams.get('page')) || 1;

  const episodeQuery = queryParams.get('episode_id')
    ? {
        value: queryParams.get('episode_id'),
        label: decodeURIComponent(queryParams.get('episode_label')),
      }
    : null;
  const chapterQuery = queryParams.get('chapter_id')
    ? {
        value: queryParams.get('chapter_id'),
        label: decodeURIComponent(queryParams.get('chapter_label')),
      }
    : null;
  const subtitleQuery = queryParams.get('subtitle_id')
    ? {
        value: queryParams.get('subtitle_id'),
        label: decodeURIComponent(queryParams.get('subtitle_label')),
      }
    : null;

  const itemsPerPage = 20;

  const [searchQuery, setSearchQuery] = useState({
    section: '요전단락',
    page: currentPage,
  });

  const [searchInput, setSearchInput] = useState({
    episode: {
      dataType: 'yj',
      name: '편',
      inputType: 'select',
      value: null,
    },
    chapter: {
      dataType: 'yj',
      name: '장',
      inputType: 'select',
      value: null,
    },
    subtitle: {
      dataType: 'yj',
      name: '소제목',
      inputType: 'select',
      value: null,
    },
    para: {
      dataType: 'yj',
      name: '단락',
      inputType: 'text',
      value: '',
    },
  });

  const queryProps = { searchQuery, setSearchQuery };
  const searchProps = { searchInput, setSearchInput };

  const [requestUrl, setRequestUrl] = useState(null);

  // 페이지가 변경되면 데이터를 다시 불러옴
  useEffect(() => {
    let url = `${process.env.REACT_APP_SERVER_URL}/bub`;
    let payload = '';
    setSearchInput({
      ...searchInput,
      episode: {
        ...searchInput.episode,
        value: episodeQuery,
      },
      chapter: {
        ...searchInput.chapter,
        value: chapterQuery,
      },
      subtitle: {
        ...searchInput.subtitle,
        value: subtitleQuery,
      },
      para: {
        ...searchInput.para,
        value: searchKeyword || '',
      },
    });

    if (subtitleQuery !== null && searchKeyword !== '') {
      payload += '/search/list';
      payload += `?section=${encodeURIComponent(searchQuery.section)}`;
      payload += `&episode_id=${episodeQuery.value}`;
      payload += `&episode_label=${episodeQuery.label}`;
      if (chapterQuery !== null) {
        payload += `&chapter_id=${chapterQuery.value}`;
        payload += `&chapter_label=${chapterQuery.label}`;
      }
      if (subtitleQuery !== null) {
        payload += `&subtitle_id=${subtitleQuery.value}`;
        payload += `&subtitle_label=${subtitleQuery.label}`;
      }
      payload += `&search=${encodeURIComponent(searchKeyword)}`;
    } else {
      payload += '/yj/para';
    }
    setRequestUrl(payload);
    payload +=
      (payload.includes('/yj/para') ? '?page=' : '&page=') + searchQuery.page;
    axios
      .get(`${url + payload}`) // API 엔드포인트 URL
      .then((response) => {
        const dataArray = response.data;
        const yjPara =
          subtitleQuery !== null
            ? dataArray.bubSearchList
            : dataArray.yjParaList;
        const dataCount = dataArray.count[0];
        setData(yjPara);
        setTotalItems(dataCount.count);
      })
      .catch((error) => {
        console.error('Error fetching data1:', error);
      });
  }, [currentPage]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // 페이지 변경 시 이벤트 처리
  const handlePageChange = (pageNumber) => {
    // URL을 업데이트하여 페이지 번호를 변경
    window.location.search = `${requestUrl}&page=${pageNumber}`;
  };

  // 페이지 번호 목록 생성
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  // 삭제하기 버튼 클릭 시
  const handleDeleteSelected = (pageNumber) => {
    const selectedIds = selectedItems.map((item) => item.id);
    if (window.confirm('정말로 삭제하시겠습니까?')) {
      Promise.all(
        selectedIds.map((id) =>
          axios.delete(`${process.env.REACT_APP_SERVER_URL}/bub/yj/para/${id}`),
        ),
      )
        .then((responses) => {
          console.log('선택 삭제 성공:', responses);

          setSelectedItems([]);
          window.location.reload();

          // 데이터를 다시 불러옴
          axios
            .get(`${requestUrl}&page=${pageNumber}`)
            .then((response) => {
              const dataArray = response.data;
              const yjPara = dataArray.yjParaList;
              const dataCount = dataArray.count[0];
              setData(yjPara);
              setTotalItems(dataCount.count);
            })
            .catch((error) => {
              console.error('Error fetching data:', error);
            });
        })
        .catch((error) => {
          console.error('선택 삭제 실패:', error);
        });
    }
  };

  // 선택된 항목 변경 시
  const handleCheckboxChange = (item) => {
    // 선택된 항목의 상태를 토글합니다.
    setSelectedItems((prevSelected) => {
      const isSelected = prevSelected.some(
        (selectedItem) => selectedItem.id === item.id,
      );

      if (isSelected) {
        // 이미 선택된 항목이라면 제거합니다.
        return prevSelected.filter(
          (selectedItem) => selectedItem.id !== item.id,
        );
      } else {
        // 선택되지 않은 항목이라면 추가합니다.
        return [...prevSelected, item];
      }
    });
  };

  const hadleReset = () => {
    window.location.search = `${location.pathname}`;
}

  return (
    <div className='content'>
      <div className='logo-text'>한마음과학원</div>
      <div className='category-titles'>
        <span>법문관리</span>
        <span>한마음요전</span>
        <span className='status-title'>단락 관리</span>
      </div>
      <YjSearchComponent queryProps={queryProps} props={searchProps} onReset={hadleReset} />
      <div className='table-wrap yjpara'>
        <div className='tbl thead'>
          <ul>
            <li className='chk-box'></li>
            {/* <li>ID</li> */}
            <li className='tb_title'>소제목 이름</li>
            <li className='tb_content'>단락</li>
            <li className='set'>관리</li>
          </ul>
        </div>
        <div className='tbl tbody'>
          {data.map((item) => (
            <ul key={item.id}>
              <li className='chk-box'>
                <label className='checkbox'>
                  <input
                    type='checkbox'
                    checked={selectedItems.some(
                      (selectedItem) => selectedItem.id === item.id,
                    )}
                    onChange={() => handleCheckboxChange(item)}
                  />
                  <i></i>
                </label>
              </li>
              {/* <li>
                    <span className="label">ID</span>
                    <span className="c">{item.id}</span>
                  </li> */}
              <li className='tb_title m-title'>
                <Link to={`/yjpara_view/${item.id}`}>
                  <span>{item.subtitle}</span>
                </Link>
              </li>
              <li className='tb_content m-content'>
                <p className='c'>
                  {item.paragraph.length > 50
                    ? item.paragraph.slice(0, 100) + '...'
                    : item.paragraph}
                </p>
              </li>
              <li className='set'>
                <Link to={`/yjpara_view/${item.id}`} className='detail-btn c'>
                  상세보기
                </Link>
              </li>
            </ul>
          ))}
        </div>
      </div>
      <div className='button_wrap'>
        <button onClick={handleDeleteSelected} className='delete-btn btn wh'>
          삭제하기
        </button>
        <Link to='/yjpara_write' className='btn create-btn m-c-c-b'>
          <span>등록하기</span>
        </Link>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default YjparaList;

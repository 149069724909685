import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Useyn from '../../components/Useyn';
import arrowImg from '../../imgs/icon/ico-arrow-select.png';

const YjparaWrite = () => {
    const { id } = useParams();
    const [subtitle, setSubtitle] = useState('');
    const [subtitleId, setSubtitleid] = useState('');
    const [paraName, setParaName] = useState('');
    const [paraUse, setParaUse] = useState('N'); // 카테고리 이름을 저장하는 state
    const [paraSeq, setparaSeq] = useState('');
    const [subData, setSubData] = useState([]);

    // 수정할 때, 데이터를 불러올 함수
    useEffect(() => {
        if (id) {
            axios
                .get(`${process.env.REACT_APP_SERVER_URL}/bub/yj/para/${id}`)
                .then((response) => {
                    const yjparaData = response.data.yjPara[0]; // 첫 번째 요소에 있는 카테고리 정보
                    setSubtitle(yjparaData.subtitle);
                    //setSubtitleid(yjparaData.subtitle_id);
                    setParaName(yjparaData.paragraph);
                    setparaSeq(yjparaData.paragraph_seq);
                    setParaUse(yjparaData.use_yn);
                    console.log(yjparaData);
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                    alert(error.response.data.message);
                });
        }
    }, [id]);

    // 등록할 때, 데이터를 불러올 함수
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_SERVER_URL}/bub/yj/subtitle`)
            .then((response) => {
                const subData = response.data.yjSubtitle.filter((sub) => sub.use_yn !== 'N'); // 첫 번째 요소에 있는 카테고리 정보
                setSubData(subData);
                if (!id) {
                    setSubtitleid(subData[0].id);
                }
                //setSubtitleid(subData)
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    }, []);

    // 수정할 때, 데이터를 불러올 함수
    useEffect(() => {
        if (id && subData.length > 0) {
            const matchedSubtitle = subData.find((sub) => sub.subtitle === subtitle);
            if (matchedSubtitle) {
                setSubtitleid(matchedSubtitle.id);
            }
        }
    }, [subData, id, subtitle]);

    // 소제목 셀렉트박스 변경 시
    const handleSelectChange = (e) => {

        let this_data = subData.find((sub) => sub.subtitle == e.target.value);
        setSubtitleid(this_data.id);
        setSubtitle(this_data.subtitle);
    };

    // 등록, 수정버튼 클릭 시 실행되는 함수
    const handleSubmit = (e) => {
        e.preventDefault();

        const postData = {
            subtitle_id: subtitleId,
            paragraph: paraName,
            paragraph_seq: paraSeq,
            use_yn: paraUse,
        };

        if (id) {
            if (window.confirm('정말로 수정하시겠습니까?')) {
                axios
                    .patch(`${process.env.REACT_APP_SERVER_URL}/bub/yj/para/${id}`, postData)
                    .then((response) => {
                        console.log('수정 성공:', response.data);
                        // 수정 성공 후, 상세보기 페이지로 이동
                        window.location.pathname = `/yjpara_view/${id}`;
                    })
                    .catch((error) => {
                        console.error('수정 실패:', error);
                    });
            }
        } else {
            // 새로운 카테고리 등록
            axios
                .post(`${process.env.REACT_APP_SERVER_URL}/bub/yj/para`, postData)
                .then((response) => {
                    console.log('등록 성공:', response.data);
                    // 등록 성공 후, 목록 페이지로 이동
                    window.location.pathname = '/yjpara_list';
                })
                .catch((error) => {
                    console.error('등록 실패:', error);
                });
        }
    };
    console.log(subData)

    return (
        <div className="content">
            <div className="logo-text">한마음과학원</div>
            <div className="category-titles">
                <span>법문관리</span>
                <span>한마음 요전</span>
                <span className="status-title">단락 관리</span>
            </div>
            <div className="page-head">
                <span>{id ? '단락 관리 수정' : '단락 관리 등록'}</span>
            </div>
            <form onSubmit={handleSubmit} className="data-form">
                <div className="form-inner">
                    <div className="division">
                        <div className="data-box">
                            <label>소제목 이름:</label>
                            <div className="select-box">
                                <select value={subtitle} onChange={handleSelectChange}>
                                    {subData.map((sub) => (
                                        <option key={sub.id} value={sub.subtitle}>
                                            {sub.subtitle}
                                        </option>
                                    ))}
                                </select>
                                <span className="iconArrow">
                                    <img src={arrowImg} alt="arrow image" />
                                </span>
                            </div>
                        </div>
                        <div className="data-box">
                            <label>단락 순서:</label>
                            <input
                                type="tel"
                                className="data-in-box"
                                value={paraSeq}
                                onChange={(e) => {
                                    const newValue = e.target.value.replace(/\D/g, ''); // 숫자가 아닌 문자를 제거
                                    setparaSeq(newValue)}}
                            />
                        </div>
                    </div>
                        <div className="data-box">
                            <label>단락</label>
                            <textarea 
                            value={paraName}
                            placeholder="내용을 입력해주세요."
                            onChange={(e) => setParaName(e.target.value)}
                        />
                        </div>
                        <Useyn value={paraUse} onChange={(e) => setParaUse(e.target.value)} />
                </div>
                <div className="button_wrap">
                    <Link to="/yjpara_list" className="btn wh">
                        취소하기
                    </Link>
                    <button type="submit" className="btn blk">
                        {id ? '수정하기' : '등록하기'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default YjparaWrite;

import Router from './router';
import Search_router from './search_router';

function App() {
  return (
    <>
      <Router />
      <Search_router />
    </>
  );
}

export default App;

import axios from 'axios';
import { useState, useEffect } from 'react';
import {
  Link,
  useLocation,
  useNavigate,
  useOutletContext,
} from 'react-router-dom';
import { useOutlet } from 'react-router-dom';

import BubsearchMenu from '../../components/search/menu';
import SutrasEdit from '../../components/search/sutras_edit';

const Sutras = () => {
  const user = useOutletContext();
  const [data, setData] = useState([]);
  const location = useLocation();
  const [checkLocation, setCheckLocation] = useState(false);
  const [isSutrasOpen, setIsSutrasOpen] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);

  let body = document.querySelector('body');

  useEffect(() => {
    const regex = /^\/search/;

    const match = location.pathname.match(regex);
    if (match) {
      setCheckLocation(true);
    } else {
      setCheckLocation(false);
    }
  }, [location]);

  useEffect(() => {
    // 스크롤 이벤트 리스너 등록
    const handleScroll = () => {
      const scrollY = window.scrollY;

      // 스크롤 위치를 부모 창으로 전달
      window.parent.postMessage({ type: 'scroll', scrollY }, '*');
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/bub/sutras/${user.mb_no}`)
      .then((res) => {
        const dataArray = res.data;
        const sutrasUser = dataArray.sutrasCopyings;
        setData(sutrasUser);
        console.log(sutrasUser);
      })
      .catch((err) => {
        console.log(err);
        setData(null);
      });
  }, [user.mb_no, openIndex]);
  // console.log(data)

  const openSutras = (index) => {
    setOpenIndex(index);
  };

  const closeSutras = () => {
    setOpenIndex(null);
  };

  return (
    <div className='search sutras'>
      <div className='search-inner'>
        {checkLocation ? null : (
          <div>
            <p className='page-title'>법문검색</p>
            <BubsearchMenu />
          </div>
        )}
        <div
          className={`sutras-table-wrap ${
            checkLocation ? 'delete-padding' : ''
          }`}
        >
          <div className='tbl thead'>
            <ul>
              <li>
                <span className='tb-s-para'>법문단락</span>
                <span className='tb-s-sutras'>사경후 올라온 맘</span>
                <span className='tb-s-date'>등록날짜</span>
              </li>
            </ul>
          </div>
          <div className='tbl tbody'>
            <ul>
              {data &&
                data.length > 0 &&
                data.map((item, index) => (
                  <li key={index}>
                    <span className='tb-s-para'>{item.contents}</span>
                    {/* <Link to={`/sutras_view/${item.id}`} className='tb-s-sutras'>
                    {item.feeling}
                  </Link> */}
                    <button
                      onClick={() => openSutras(index)}
                      className='tb-s-sutras'
                    >
                      {item.feeling}
                    </button>
                    <SutrasEdit
                      item={item}
                      openSutras={openIndex === index}
                      closeSutras={closeSutras}
                    />
                    {/* <span className="tb-s-sutras">{item.feeling}</span> */}
                    <span className='tb-s-date'>{item.createAt}</span>
                  </li>
                ))}
              {data === null && (
                <li className='no-data'>
                  <span>등록된 사경이 없습니다.</span>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sutras;

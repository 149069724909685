import React from 'react';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SmsearchMenu from '../../components/search/sm_menu';
import DetailModal from '../../components/search/DetailModal';
import SmModalBooks from '../../components/sm_modal/sm_modal_books';
import SmModalConference from '../../components/sm_modal/sm_modal_conference';
import SmModalJournal from '../../components/sm_modal/sm_modal_journal';
import SmModalThesis from '../../components/sm_modal/sm_modal_thesis';
import Loading from '../../components/default/Loading';

const SmdetailSearch = () => {
  const [selectedOption, setSelectedOption] = useState('단행본');
  const [selectedNumber, setSelectedNumber] = useState('10001');

  const [detailSearchList, setDetailSearchList] = useState([]);
  const [newSearch, setNewSearch] = useState([]);
  const [listLength, setListLength] = useState('');

  const [useSearch, setUseSearch] = useState(false);

  const [searchTextValue, setSearchTextValue] = useState('');

  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const location = useLocation();
  const [checkLocation, setCheckLocation] = useState(false);

  useEffect(() => {
    const regex = /^\/search/;

    const match = location.pathname.match(regex);
    if (match) {
      setCheckLocation(true);
    } else {
      setCheckLocation(false);
    }
  }, [location]);

  useEffect(() => {
    // 스크롤 이벤트 리스너 등록
    const handleScroll = () => {
      const scrollY = window.scrollY;
      
      // 스크롤 위치를 부모 창으로 전달
      window.parent.postMessage({ type: 'scroll', scrollY }, '*');
    };
    
    window.addEventListener('scroll', handleScroll);

    return () => {
      // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  let body = document.querySelector('body');

  // console.log("original:", detailSearchList, "new:", newSearch);

  // 카테고리 선택 옵션 모음
  const categoryArr = [
    {
      idx: 1,
      name: '단행본',
      number: '10001',
    },
    {
      idx: 2,
      name: '학술대회자료집',
      number: '10002',
    },
    {
      idx: 3,
      name: '학술지',
      number: '10003',
    },
    {
      idx: 4,
      name: '학위논문',
      number: '10004',
    },
  ];

  // 상세검색 내 검색어 입력시 상세 분류
  const searchSubmitHandle = (e) => {
    e.preventDefault();
    if (searchTextValue.length <= 1) {
      alert('두 글자 이상 입력해주세요.');
      return;
    }
    setUseSearch(true);
    const search = detailSearchList.filter((item) => {
      return (
        item.SM_Title.includes(searchTextValue) ||
        item.SM_Abstract.includes(searchTextValue)
      );
    });

    setNewSearch(search);

    setListLength(search.length);
  };

  // 상세검색 내 검색어 입력시 검색어 담기
  const searchTextChangeHandle = (e) => {
    e.preventDefault();
    setSearchTextValue(e.target.value);
  };

  // 모달창에서 카테고리 선택한 것만 담기
  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setSelectedOption(selectedValue);

    // categoryArr에서 선택된 category를 찾아 해당 number를 setSelectedNumber에 설정
    const selectedCategory = categoryArr.find(
      (category) => category.name === selectedValue,
    );
    if (selectedCategory) {
      setSelectedNumber(selectedCategory.number);
    }
  };

  // 상세보기 모달창
  const openDetailModal = (data) => {
    setSelectedData(data);
    setIsDetailModalOpen(true);
    body.classList.add('on');
  };

  const closeDetailModal = () => {
    setSelectedData(null);
    setIsDetailModalOpen(false);
    body.classList.remove('on');
  };

  const handleModalSubmit = (formData) => {
    console.log(formData);
    // formData에서 key 이름이 _opt로 끝나는 것들을 제외한 모든 key의 value가 빈 문자열이면 검색하지 않음
    const isSearch = Object.keys(formData).some((key) => {
      if (key.endsWith('_opt')) return false;
      return formData[key] !== '';
    });
    if (!isSearch) {
      alert('검색조건을 하나 이상 입력해주세요.');
      return;
    }

    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/sm/search/detail?cate=${selectedNumber}`,
        formData,
      )
      .then((response) => {
        const books_searchList = response.data.books_searchList;
        const conference_searchList = response.data.conference_searchList;
        const journal_searchList = response.data.journal_searchList;
        const thesis_searchList = response.data.thesis_searchList;

        if (books_searchList) {
          setDetailSearchList(books_searchList);
          setListLength(response.data.books_count[0].count);
        } else if (conference_searchList) {
          setDetailSearchList(conference_searchList);
          setListLength(response.data.conference_count[0].count);
        } else if (journal_searchList) {
          setDetailSearchList(journal_searchList);
          setListLength(response.data.journal_count[0].count);
        } else if (thesis_searchList) {
          setDetailSearchList(thesis_searchList);
          setListLength(response.data.thesis_count[0].count);
        } else {
          setDetailSearchList([]);
          setListLength(0);
        }

        console.log(books_searchList);
        console.log(conference_searchList);
      })
      .catch((error) => {
        console.log('등록 실패:', error);
        alert('검색 결과가 없습니다.');
      });

    // 모달 닫기
    let modal = document.querySelector('.modal');
    if (modal) modal.classList.remove('modal-view-active');
  };

  return (
    <div className='search'>
      <div className='search-inner'>
        {checkLocation ? null : (
          <div>
            <p className='page-title'>논문검색</p>
            <SmsearchMenu />
          </div>
        )}
        <div
          className={`search-box detail-search-box ${
            checkLocation ? 'delete-border' : ''
          }`}
        >
          <div className='search-form'>
            <div className='input-wrap'>
              <div className='search-box__input'>
                <input
                  type='text'
                  placeholder='상세검색입니다. 무엇을 찾고 계세요?'
                  onChange={searchTextChangeHandle}
                  value={searchTextValue}
                />
              </div>
              <div className='search-box__button'>
                <button type='submit' onClick={searchSubmitHandle}>
                  검색
                </button>
              </div>
            </div>
            <div className='detail_btn'>
              <button
                className='detail_btn___btn'
                onClick={() => {
                  let modal = document.querySelector('.modal');
                  let body = document.querySelector('body');
                  if (modal) modal.classList.add('modal-view-active');
                  body.classList.add('on');
                }}
              >
                <span>상세검색</span>
              </button>
            </div>
          </div>
          <div className='tree-pati'></div>
          <>
            {useSearch && newSearch.length > 0 ? (
              <div className='result-box'>
                <div className='moreview'>
                  <h3>
                    {selectedOption}
                    <span>{listLength}</span>
                  </h3>
                </div>
                <div className='item-result'>
                  {newSearch.map((item, index) => (
                    <div key={index} className='bub_item'>
                      <div className='bub_item__title'>
                        <h3>{item.SM_Title}</h3>
                      </div>
                      <div className='bub_item__content'>
                        <p>{item.SM_Abstract}</p>
                      </div>
                      <div className='btn-options'>
                        <button
                          className='opts-btn bg-bk'
                          onClick={() => openDetailModal(item)}
                        >
                          상세보기
                        </button>
                        <button className='opts-btn scrap'>스크랩</button>
                      </div>
                    </div>
                  ))}
                </div>
                <DetailModal
                  isOpen={isDetailModalOpen}
                  data={selectedData}
                  onClose={closeDetailModal}
                />
              </div>
            ) : newSearch.length === 0 && !useSearch && detailSearchList.length > 0 ? (
              <div className='result-box'>
              <div className="moreview">
                <h3>
                  {selectedOption}
                  <span>{listLength}</span>
                </h3>
              </div>
              <div className="item-result">
                {detailSearchList.map((item, index) => (
                  <div key={index} className="bub_item">
                    <div className="bub_item__title">
                      <h3>{item.SM_Title}</h3>
                    </div>
                    <div className="bub_item__content">
                      <p>{item.SM_Abstract}</p>
                      <div className="item-opts">
                        <span>{item.SM_Author}</span>
                        <span>
                          {item.SM_Publication_YY}.{item.SM_Publication_MM}
                        </span>
                      </div>
                    </div>
                    <div className="btn-options">
                      <button
                        className="opts-btn bg-bk"
                        onClick={() => openDetailModal(item)}
                      >
                        상세보기
                      </button>
                      <button className="opts-btn scrap">스크랩</button>
                    </div>
                  </div>
                ))}
              </div>
              <DetailModal
                isOpen={isDetailModalOpen}
                data={selectedData}
                onClose={closeDetailModal}
              />
            </div>
            ) : (
              <p className="comment bottom-search">※ 검색 단어로 여러 단어 입력이 가능합니다. 예: 단행</p>
            )}
          </>
          <div
            className={`modal modal-sm-detail-search modal-view-active ${
              checkLocation
                ? 'detail_view__overlay_search'
                : 'detail_view__overlay'
            }`}
          >
            <div className='detail_view'>
              <div className='detail_view__content'>
                <div className='detail_view__content__header'>
                  <div className='search-select-box'>
                    <select
                      value={selectedOption || ''}
                      onChange={handleSelectChange}
                    >
                      {categoryArr.map((category, index) => (
                        <option key={index} value={category.name}>
                          {category.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <span className='comment-text'>
                    ※ 검색 분류를 선택하신 후 검색어를 입력해주세요.
                  </span>
                  <button
                    //to={`${checkLocation ? '/search/sm_detail_search' : '/sm_detail_search'}`}
                    className='close-btn'
                    onClick={() => {
                      let sm_modal = document.querySelector('.modal-sm-detail-search');
                      sm_modal.classList.remove('modal-view-active');
                    }}
                  >
                    X
                  </button>
                </div>
                <div className='detail_view__content__body'>
                  <form className='sm-form'>
                    {(() => {
                      if (selectedOption === '단행본') {
                        return <SmModalBooks onSubmit={handleModalSubmit} />;
                      } else if (selectedOption === '학술대회자료집') {
                        return (
                          <SmModalConference onSubmit={handleModalSubmit} />
                        );
                      } else if (selectedOption === '학술지') {
                        return <SmModalJournal onSubmit={handleModalSubmit} />;
                      } else if (selectedOption === '학위논문') {
                        return <SmModalThesis onSubmit={handleModalSubmit} />;
                      } else {
                        return null; // 선택된 옵션이 없는 경우
                      }
                    })()}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmdetailSearch;

import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';

// Select 컴포넌트를 사용하기 위해 import
import Select from 'react-select';

const HggCateSelect = ({ cateList, category, handleCateChange }) => {
  const options = cateList.map((item) => {
    return { value: item.id, label: item.category_name };
  });

  const catevalue = options.filter((item) => {
    return item.value === Number(category);
  });

  return (
    <div className='search-select'>
      <label>카테고리</label>
      <Select
        className='bub-cate-select'
        placeholder='카테고리'
        options={options}
        value={catevalue[0]}
        onChange={handleCateChange}
      />
    </div>
  );
};

const HggTitleSelect = ({ titleOpts, title, handleTitleChange }) => {
  const options = titleOpts.map((item) => {
    return { value: item.id, label: item.title };
  });

  const titlevalue = options.filter((item) => {
    return item.value === Number(title);
  });

  console.log(titlevalue);

  if (!titleOpts) {
    return null;
  }
  return (
    // <select className='sfl' onChange={handleTitleChange} value={title}>
    //   <option value='' disabled>
    //     제목을 선택하세요
    //     </option>
    //   {titleOpts.map((item) => (
    //       <option key={item.id} id={item.id} value={item.title}>
    //           {item.title}
    //       </option>
    //   ))}
    // </select>
    <div className='search-select'>
      <label>제목</label>
      <Select
        className='bub-title-select'
        placeholder='제목'
        options={options}
        value={titlevalue[0]}
        onChange={handleTitleChange}
      />
    </div>
  );
};

const ListSearch = ({
  hgg,
  currentCategory,
  currentTitle,
  currentSearch,
  onSearch,
  onReset,
}) => {
  const location = useLocation();

  const [cateList, setCateList] = useState([]); // 카테고리 데이터를 저장하는 state
  const [titleOpts, setTitleOpts] = useState([]);
  const [cateId, setCateId] = useState(0);
  const [input, setInput] = useState(currentSearch ? currentSearch : '');
  const [titleSeq, setTitleSeq] = useState('');

  const [cate_Name, setCate_Name] = useState('');
  const [title_Name, setTitle_Name] = useState('');

  const cateName = cateList.filter(
    (item) => item.id === Number(currentCategory),
  );
  const titName = titleOpts.filter((item) => item.id === Number(currentTitle));

  const category = cateName[0] ? cateName[0].id : '';
  const title = titName[0] ? titName[0].titleSeq : '';

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/bub/cate`)
      .then((response) => {
        const dataArray = response.data;
        const bubCate = dataArray.bubCateList.filter(
          (item) => item.use_yn !== 'N',
        );
        setCateList(bubCate);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  useEffect(() => {
    if (category) {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/bub/title?category_id=${currentCategory}`,
        )
        .then((response) => {
          const dataArray = response.data;
          const bubTitle = dataArray.bubTitleList.filter(
            (item) => item.use_yn !== 'N',
          );
          console.log(bubTitle);
          setTitleOpts(bubTitle);
          if (currentTitle > 0) {
            const matchedTitleSeq = bubTitle.filter((item) => {
              return item.title_seq === Number(currentTitle);
            });
            console.log(matchedTitleSeq);
            setTitle_Name(matchedTitleSeq[0].id);
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [category]);

  const CateSelect = (e) => {
    //e.preventDefault();

    const selectedOption = e;
    const category_id = Number(selectedOption.value);

    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/bub/title?category_id=${category_id}`,
      )
      .then((response) => {
        const dataArray = response.data;
        const bubTitle = dataArray.bubTitleList.filter(
          (item) => item.use_yn !== 'N',
        );
        setTitleOpts(bubTitle);
        setCateId(category_id);
        setCate_Name(selectedOption.value);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const TitleSelect = (e) => {
    //e.preventDefault();

    const selectedOption = e;
    const title_id = Number(selectedOption.value);

    const matchedTitleSeq = titleOpts.filter((item) => {
      return item.id === title_id;
    });
    setTitleSeq(matchedTitleSeq[0].title_seq);
    setTitle_Name(selectedOption.value);
  };

  const listSearchBtn = (e) => {
    e.preventDefault();

    onSearch({
      cateId: cateId ? cateId : currentCategory,
      titleSeq: titleSeq,
      input: input ? input : currentSearch,
    });
  };

  const reset = (e) => {
    e.preventDefault();

    onSearch({
      cateId: '',
      titleSeq: '',
      input: '',
    });

    onReset();
  };

  console.log(title_Name);

  return (
    <div className='list-search'>
      <h2>검색</h2>
      <HggCateSelect
        cateList={cateList}
        category={cateId ? cateId : category}
        handleCateChange={CateSelect}
      />
      {hgg === 'para' ? (
        <HggTitleSelect
          titleOpts={titleOpts}
          title={title_Name ? title_Name : title}
          handleTitleChange={TitleSelect}
        />
      ) : null}
      <div className='search-input'>
        <label>{hgg === 'para' ? '단락' : '제목'}</label>
        <input
          type='text'
          className='list_stx'
          placeholder='검색어를 입력하세요'
          onChange={(e) => {
            if (currentSearch) {
              setInput(currentSearch);
            }
            setInput(e.target.value);
          }}
          value={input}
        />
      </div>
      <div className='search-submit'>
        <button className='submit-btns list_sbtn' onClick={listSearchBtn}>
          검색하기
        </button>
        <button className='submit-btns list_rbtn' onClick={reset}>
          초기화
        </button>
      </div>
    </div>
  );
};

export default ListSearch;

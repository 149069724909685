import React from 'react';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

function DetailModal({ isOpen, data, onClose }) {
  const [booksData, setBooksData] = useState(null);
  const [conferenceData, setConferenceData] = useState(null);
  const [journalData, setJournalData] = useState(null);
  const [thesisData, setThesisData] = useState(null);

  const [getData, setGetData] = useState({});

  const [loading, setLoading] = useState(true);

  const location = useLocation();
  const [checkLocation, setCheckLocation] = useState(false);

  useEffect(() => {
    const regex = /^\/search/;

    const match = location.pathname.match(regex);
    if (match) {
      setCheckLocation(true);
    } else {
      setCheckLocation(false);
    }
  }, [location]);

  useEffect(() => {
    if (isOpen) {
      setLoading(true);

      if (data?.SM_Books_Id) {
        let books_id = data.SM_Books_Id;

        fetch(`${process.env.REACT_APP_SERVER_URL}/sm/books/${books_id}`)
          .then((response) => response.json())
          .then((data) => {
            setBooksData(data.books[0]);
            setLoading(false);
          })
          .catch((error) => {
            console.log('등록 실패:', error);
            setLoading(false);
          });
      } else if (data?.SM_Conference_Id) {
        let conference_id = data.SM_Conference_Id;

        fetch(
          `${process.env.REACT_APP_SERVER_URL}/sm/conference/${conference_id}`,
        )
          .then((response) => response.json())
          .then((data) => {
            setConferenceData(data.conference[0]);
            setLoading(false);
          })
          .catch((error) => {
            console.log('등록 실패:', error);
            setLoading(false);
          });
      } else if (data?.SM_Journal_Id) {
        let journal_id = data.SM_Journal_Id;

        fetch(`${process.env.REACT_APP_SERVER_URL}/sm/journal/${journal_id}`)
          .then((response) => response.json())
          .then((data) => {
            setJournalData(data.journal[0]);
            setLoading(false);
          })
          .catch((error) => {
            console.log('등록 실패:', error);
            setLoading(false);
          });
      } else if (data?.SM_Thesis_Id) {
        let thesis_id = data.SM_Thesis_Id;

        fetch(`${process.env.REACT_APP_SERVER_URL}/sm/thesis/${thesis_id}`)
          .then((response) => response.json())
          .then((data) => {
            setThesisData(data.thesis[0]);
            setLoading(false);
          })
          .catch((error) => {
            console.log('등록 실패:', error);
            setLoading(false);
          });
      }
    }
  }, [isOpen, data]);

  useEffect(() => {
    if (booksData) {
      setGetData({
        ...booksData,
        Tags: booksData.Tags ? booksData.Tags.split(',') : [],
      });
    } else if (conferenceData) {
      setGetData({
        ...conferenceData,
        Tags: conferenceData.Tags ? conferenceData.Tags.split(',') : [],
      });
    } else if (journalData) {
      setGetData({
        ...journalData,
        Tags: journalData.Tags ? journalData.Tags.split(',') : [],
      });
    } else if (thesisData) {
      setGetData({
        ...thesisData,
        Tags: thesisData.Tags ? thesisData.Tags.split(',') : [],
      });
    }
  }, [booksData, conferenceData, journalData, thesisData]);

  const handleDownload = (e) => {
    e.preventDefault();

    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/sm/download/${getData.SM_Document_Id}`,
        {
          responseType: 'json',
        },
      )
      .then((response) => {
        const { fileData } = response.data;
        const dataUrl = 'data:application/pdf;base64,' + fileData;
        const a = document.createElement('a');
        a.href = dataUrl;
        a.download = getData.SM_Attachment_Source;
        a.click();
      })
      .catch((error) => {
        console.error('파일 다운로드 중 오류 발생:', error);
      });
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div
      className={`modal ${
        checkLocation ? 'detail_view__overlay_search' : 'detail_view__overlay'
      }`}
    >
      <div className='detail_view'>
        <div className='detail_view__content detail'>
          <div className='detail_view__content__header fordetail-header'>
            <h3 className='title-name'>{data.SM_Title}</h3>
            <ul className='header-info-list'>
              <li className='day'>
                <span>발행년월</span>
                <span>
                  {data.SM_Publication_YY}.{data.SM_Publication_MM}
                </span>
              </li>
              <li className='day'>
                <span>저자</span>
                <span>{data.SM_Author}</span>
              </li>
            </ul>
            {getData.SM_Cate_Name ? (
              <>
                {getData.SM_Cate_Name === '단행본' && (
                  <ul className='header-info-list'>
                    <li className='day'>
                      <span>출판사명</span>
                      <span>{getData.SM_Books_Publish}</span>
                    </li>
                    <li className='day'>
                      <span>단행본명</span>
                      <span>{getData.SM_Books_Name}</span>
                    </li>
                  </ul>
                )}
                {getData.SM_Cate_Name === '학술대회자료집' && (
                  <ul className='header-info-list'>
                    <li className='day'>
                      <span>학회</span>
                      <span>{getData.SM_Conference_Name}</span>
                    </li>
                    <li className='day'>
                      <span>장소</span>
                      <span>{getData.SM_Conference_Location}</span>
                    </li>
                    <li className='day'>
                      <span>개최일자</span>
                      <span>{getData.SM_Conference_Date}</span>
                    </li>
                  </ul>
                )}
                {getData.SM_Cate_Name === '학술지' && (
                  <ul className='header-info-list'>
                    <li className='day'>
                      <span>학술지명</span>
                      <span>{getData.SM_Journal_Name}</span>
                    </li>
                    <li className='day'>
                      <span>권</span>
                      <span>{getData.SM_Journal_Volume}</span>
                    </li>
                    <li className='day'>
                      <span>호</span>
                      <span>{getData.SM_Journal_Issue}</span>
                    </li>
                    <li className='day'>
                      <span>연구기관</span>
                      <span>{getData.SM_Journal_Research_Institution}</span>
                    </li>
                  </ul>
                )}
                {getData.SM_Cate_Name === '학위논문' && (
                  <ul className='header-info-list'>
                    <li className='day'>
                      <span>학위</span>
                      <span>{getData.SM_Thesis_Degree}</span>
                    </li>
                    <li className='day'>
                      <span>대학</span>
                      <span>{getData.SM_Thesis_University}</span>
                    </li>
                    <li className='day'>
                      <span>링크</span>
                      <a href={`//${getData.SM_1}`} target='_blank'>
                        {getData.SM_1}
                      </a>
                    </li>
                  </ul>
                )}
              </>
            ) : null}
            <button onClick={onClose} className='close-btn'>
              X
            </button>
          </div>
          <div className='detail_view__content__body fordetail-body'>
            <ul className='detail-text'>
              <li>
                <p>{data.SM_Abstract}</p>
              </li>
              <li className='label-content-wrap'>
                <span className='label'>주제어</span>
                <div className='in-data tags'>
                  {getData.Tags?.map((item, index) => {
                    return (
                      <span key={index} className='tag'>
                        {item}
                      </span>
                    );
                  })}
                </div>
              </li>
              <li className='label-content-wrap'>
                {getData.SM_Attachment_Source ? (
                  <>
                    (<span className='label preview'>미리보기</span>
                    <div className='in-data pdf' onClick={handleDownload}>
                      {getData.SM_Attachment_Source}
                    </div>
                    )
                  </>
                ) : null}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailModal;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const SutrasView = ({ item, openSutras, closeSutras, user_id, type }) => {
  const [content, setcontent] = useState('');
  const [feeling, setfeeling] = useState('');

  const location = useLocation();
  const [checkLocation, setCheckLocation] = useState(false);

  useEffect(() => {
    const regex = /^\/search/;

    const match = location.pathname.match(regex);
    if (match) {
      setCheckLocation(true);
    } else {
      setCheckLocation(false);
    }
  }, [location]);

  if (!openSutras) return null;

  const handleSubmit = (e) => {
    e.preventDefault();

    const postData = {
      user_id: user_id,
      paragraph_id: item.id,
      contents: content,
      feeling: feeling,
      type: type,
    };

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/bub/sutras`, postData)
      .then((response) => {
        alert('사경이 등록되었습니다.', response.data);
        setcontent('');
        setfeeling('');
        closeSutras();
      })
      .catch((error) => {
        console.error('등록 실패:', error);
      });
  };

  return (
    <div className='modal'>
      <div className='detail_view'>
        <div className='detail_view__content han'>
          <div className='detail_view__content__header fordetail-header'>
            <h3>온라인 사경</h3>
            <p className='fordetail-header-text'>{item.paragraph}</p>
            <button onClick={closeSutras} className='close-btn'>
              X
            </button>
          </div>
          <div className='detail_view__content__body'>
            <form onSubmit={handleSubmit}>
              <div className='form-wrap'>
                <textarea
                  defaultValue={content}
                  onChange={(e) => setcontent(e.target.value)}
                  className='textarea-191'
                ></textarea>
                <p className='comment'>
                사경 후 느낀점을 작성해주세요. ({feeling.length}/100자)
                  <span>총 6명이 위의 법문을 사경하였습니다.</span>
                </p>
                <textarea
                  maxLength='99'
                  defaultValue={feeling}
                  onChange={(e) => setfeeling(e.target.value)}
                  className='textarea-83'
                ></textarea>
              </div>
              <div className='box-wrap'>
                <a onClick={closeSutras} className='btns wh-border'>
                  취소하기
                </a>
                <button type='submit' className='btns search-orange'>
                  저장하기
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        className={`${
          checkLocation ? 'detail_view__overlay_search' : 'detail_view__overlay'
        }`}
        onClick={closeSutras}
      ></div>
    </div>
  );
};

export default SutrasView;

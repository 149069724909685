import Select from 'react-select';
import { useState, useEffect } from 'react';

const SelectChapter = ({ props }) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const output = [];
      const epiValue = props.searchInput.episode.value;

      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/bub/yj/chapter`,
      );
      const data = await response.json();
      let dataList = data.yjChapterList;

      if (epiValue !== null && epiValue.value) {
        dataList = dataList.filter((row) => row.episode_id === epiValue.value);

        dataList.forEach((row) => {
          output.push({
            value: row.id,
            label: row.chapter,
          });
        });

        setOptions(output);
      }
    };

    fetchData();
    setSelectedOption(null);
  }, [props.searchInput.episode.value]);

  const chapOnChange = (e) => {
    setSelectedOption(e);
    props.setSearchInput({
      ...props.searchInput,
      chapter: {
        ...props.searchInput.chapter,
        value: e,
      },
      subtitle: {
        ...props.searchInput.subtitle,
        value:
          props.searchInput.subtitle.inputType === 'select'
            ? null
            : props.searchInput.subtitle.value,
      },
    });
  };

  return (
    <div className='search-select'>
      <label>{props.searchInput.chapter.name}</label>
      <Select
        value={props.searchInput.chapter.value}
        className='bub-chapter-select'
        placeholder='화'
        options={options}
        onChange={chapOnChange}
      />
    </div>
  );
};

export default SelectChapter;

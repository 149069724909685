import React from 'react';
import Bubitem from './item';

const SearchResult = ({ searchList, currentPage, user_id, type, marker }) => {

  return (
    <div className="item-result">
        {currentPage === '' ? (
    searchList.list.slice(0, 3).map((searchData, index) => (
        <Bubitem key={index} bub={searchData} user_id={user_id} type={type} marker={marker} />
    ))
) : (
    searchList.list.map((searchData, index) => (
        <Bubitem key={index} bub={searchData} user_id={user_id} type={type} marker={marker} />
    ))
)}
    </div>
  );
};

export default SearchResult;

const InputSubtitle = ({ props }) => {
  return (
    <div className='search-input'>
      <label>{props.searchInput.subtitle.name}</label>
      <input
        type='text'
        className='bub-subtitle-input'
        placeholder='소제목'
        value={props.searchInput.subtitle.value}
        onChange={(e) => {
          props.setSearchInput({
            ...props.searchInput,
            subtitle: {
              ...props.searchInput.subtitle,
              value: e.target.value,
            },
          });
        }}
      />
    </div>
  );
};

export default InputSubtitle;

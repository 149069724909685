import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// 레이아웃
import MainLayout from './layout/MainLayout';

// 페이지
// import Home from "./components/Home";
// import Header from "./components/Header";

import CateList from './pages/hgg/cate_list';
import CateView from './pages/hgg/cate_view';
import CateWrite from './pages/hgg/cate_write';
import TitleList from './pages/hgg/title_list';
import TitleView from './pages/hgg/title_view';
import TitleWrite from './pages/hgg/title_write';
import ParaList from './pages/hgg/para_list';
import ParaView from './pages/hgg/para_view';
import ParaWrite from './pages/hgg/para_write';

import ChapterList from './pages/yj/chapter_list';
import ChapterView from './pages/yj/chapter_view';
import ChapterWrite from './pages/yj/chapter_write';
import EpisodeList from './pages/yj/episode_list';
import EpisodeView from './pages/yj/episode_view';
import EpisodeWrite from './pages/yj/episode_write';
import SubList from './pages/yj/subtitle_list';
import SubView from './pages/yj/subtitle_view';
import SubWrite from './pages/yj/subtitle_write';
import YjparaList from './pages/yj/yjpara_list';
import YjparaView from './pages/yj/yjpara_view';
import YjparaWrite from './pages/yj/yjpara_write';
import Bubsearch from './pages/search/bub_search';
import Bubdetailsearch from './pages/search/bub_detail_search';
import Sutras from './pages/search/sutras';
import SutrasListView from './pages/search/sutras_view';
import SutrasEdit from './pages/search/sutras_edit';
import ScrapList from './pages/search/scrap_list';

import Smsearch from './pages/search/sm_search';
import Smdetailsearch from './pages/search/sm_detail_search';
import SmbooksList from './pages/scholarlymaterials/sm_books_list';
import SmbooksView from './pages/scholarlymaterials/sm_books_view';
import SmconferenceList from './pages/scholarlymaterials/sm_conference_list';
import SmconferenceView from './pages/scholarlymaterials/sm_conference_view';
import SmjournalList from './pages/scholarlymaterials/sm_journal_list';
import SmjournalView from './pages/scholarlymaterials/sm_journal_view';
import SmthesisList from './pages/scholarlymaterials/sm_thesis_list';
import SmthesisView from './pages/scholarlymaterials/sm_thesis_view';
// import SmWrite from "./pages/scholarlymaterials/sm_write";

import SmWrite from './pages/scholarlymaterials/write/page';

import PrintTest from './pages/printTest';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<MainLayout />}>
          <Route exact path='/' element={<CateList />} />
          <Route path='/cate_list' element={<CateList />} />
          <Route path='/cate_view/:id' element={<CateView />} />
          <Route path='/cate_write' element={<CateWrite />} />
          <Route path='/cate_edit/:id' element={<CateWrite />} />
          <Route path='/title_list' element={<TitleList />} />
          <Route path='/title_view/:id' element={<TitleView />} />
          <Route path='/title_write' element={<TitleWrite />} />
          <Route path='/title_edit/:id' element={<TitleWrite />} />
          <Route path='/para_list' element={<ParaList />} />
          <Route path='/para_view/:id' element={<ParaView />} />
          <Route path='/para_write' element={<ParaWrite />} />
          <Route path='/para_edit/:id' element={<ParaWrite />} />
          <Route path='/chapter_list' element={<ChapterList />} />
          <Route path='/chapter_view/:id' element={<ChapterView />} />
          <Route path='/chapter_write' element={<ChapterWrite />} />
          <Route path='/chapter_edit/:id' element={<ChapterWrite />} />
          <Route path='/episode_list' element={<EpisodeList />} />
          <Route path='/episode_view/:id' element={<EpisodeView />} />
          <Route path='/episode_write' element={<EpisodeWrite />} />
          <Route path='/episode_edit/:id' element={<EpisodeWrite />} />
          <Route path='/subtitle_list' element={<SubList />} />
          <Route path='/subtitle_view/:id' element={<SubView />} />
          <Route path='/subtitle_write' element={<SubWrite />} />
          <Route path='/subtitle_edit/:id' element={<SubWrite />} />
          <Route path='/yjpara_list' element={<YjparaList />} />
          <Route path='/yjpara_view/:id' element={<YjparaView />} />
          <Route path='/yjpara_write' element={<YjparaWrite />} />
          <Route path='/yjpara_edit/:id' element={<YjparaWrite />} />

          <Route path='/bub_search' element={<Bubsearch />} />
          <Route path='/bub_detail_search' element={<Bubdetailsearch />} />
          <Route path='/sutras' element={<Sutras />} />
          <Route path='/sutras_view/:id' element={<SutrasListView />} />
          <Route path='/sutras_edit/:id' element={<SutrasEdit />} />
          <Route path='/scrap_list' element={<ScrapList />} />

          <Route path='/sm_search' element={<Smsearch />} />
          <Route path='/sm_detail_search' element={<Smdetailsearch />} />
          {/* <Route path="/sm_edit/:document_Id" element={<SmWrite />} /> */}
          <Route path='/sm_book_list' element={<SmbooksList />} />
          <Route path='/sm_book_view/:books_id' element={<SmbooksView />} />
          <Route path='/sm_conference_list' element={<SmconferenceList />} />
          <Route
            path='/sm_conference_view/:conference_id'
            element={<SmconferenceView />}
          />
          <Route path='/sm_journal_list' element={<SmjournalList />} />
          <Route
            path='/sm_journal_view/:journal_id'
            element={<SmjournalView />}
          />
          <Route path='/sm_thesis_list' element={<SmthesisList />} />
          <Route path='/sm_thesis_view/:thesis_id' element={<SmthesisView />} />
          <Route path='sm_write/:categoryUse' element={<SmWrite />} />
          <Route
            path='/sm_edit/:categoryUse/:documentId'
            element={<SmWrite />}
          />
          <Route path='/print' element={<PrintTest />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;

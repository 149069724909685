import { useLocation } from 'react-router-dom';

const SmsubMenu = ({
  onCateChange,
  onPageChange,
  handleCatereset,
  ontotalChange,
  dataArray,
  totalArray,
  currentCate,
}) => {
  const location = useLocation();
  //const cate = searchList[0]?.category_name || "한마음요전";

  // 카테고리 이름과 코드를 매핑한 객체 생성
  const categoryMapping = {
    학위논문: '10004',
    학술지: '10003',
    학술대회자료집: '10002',
    단행본: '10001',
  };

  const handleCateChange = (categoryCode, totalItems) => {
    onCateChange(categoryCode);
    onPageChange(1);
    const url = `${location.pathname}?cate=${categoryCode}&page=1`;
    window.history.pushState({ path: url }, '', url);
  };

  return (
    <div className='bubsubmenu'>
      <ul>
        <li>
          <button
            className={currentCate === '10000' ? 'active' : ''}
            onClick={handleCatereset}
          >
            전체
          </button>
        </li>
        {Object.keys(categoryMapping).map((categoryName) => (
          <li key={categoryName}>
            <button
              className={
                currentCate === categoryMapping[categoryName] ? 'active' : ''
              }
              onClick={() => handleCateChange(categoryMapping[categoryName])}
            >
              {categoryName}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SmsubMenu;

import React from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const User = ({ userName }) => {
  return (
    <div className='user'>
      <div className='home'>
        <Link to=''>
          <span>Home</span>
        </Link>
      </div>
      <div className='user-opts'>
        <div className='user-id'>{userName}님</div>
      </div>
    </div>
  );
};

export default User;

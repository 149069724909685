const Keyword = ({ keyword, keyword_count }) => {
    return (
        <div className="keyword">
        <span className="keyword-label">검색키워드</span>
        <span className="keyword__text">'{keyword}'</span>
        <span className="keyword__count">(검색결과 {keyword_count}건)</span>
        </div>
    );
};

export default Keyword;

import React from 'react';
import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';

const CateList = () => {
  const [data, setData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/bub/cate`) // API 엔드포인트 URL
      .then((response) => {
        const dataArray = response.data;
        const bubCate = dataArray.bubCateList;
        setData(bubCate);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  const handleCheckboxChange = (id) => {
    setSelectedItems((prevIds) => {
      // 현재 선택 상태 확인
      const isSelected = prevIds.includes(id);

      // 체크박스가 선택되었을 경우, id를 배열에 추가
      // 체크박스가 해제되었을 경우, id를 배열에서 제거
      return isSelected
        ? prevIds.filter((prevId) => prevId !== id)
        : [...prevIds, id];
    });
  };

  const handleDeleteSelected = () => {
    try {
      // 선택된 아이템들을 순회하며 서버에서 삭제하는 API 호출
      if (window.confirm('정말로 삭제하시겠습니까?')) {
        for (const id of selectedItems) {
          axios
            .delete(`${process.env.REACT_APP_SERVER_URL}/bub/cate/${id}`)
            .then((response) => {
              console.log('삭제 성공:', response.data);
            })
            .catch((error) => {
              console.error('삭제 실패:', error);
            });
        }

        // 선택된 아이템들을 화면에서도 제거
        setData((data) =>
          data.filter((item) => !selectedItems.includes(item.id)),
        );

        // 선택 상태 초기화
        setSelectedItems([]);
      } else {
        return;
      }
    } catch (error) {
      console.error('데이터 삭제 중 오류가 발생했습니다.', error);
    }
  };

  return (
    <div className='content'>
      <div className='logo-text'>한마음과학원</div>
      <div className='category-titles'>
        <span>법문관리</span>
        <span>허공을 걷는 길</span>
        <span className='status-title'>카테고리 관리</span>
      </div>
      <div className='table-wrap category'>
        <div className='tbl thead'>
          <ul>
            <li className='chk-box'></li>
            {/* <th>ID</th> */}
            <li className='tb_category'>카테고리 이름</li>
            <li className='set'>관리</li>
          </ul>
        </div>
        <div className='tbl tbody'>
          {data.map((item) => (
            <ul key={item.id}>
              <li className='chk-box'>
                <label className='checkbox'>
                  <input
                    type='checkbox'
                    checked={selectedItems.includes(item.id)}
                    onChange={() => handleCheckboxChange(item.id)}
                  />
                  <i></i>
                </label>
              </li>
              {/* <td>{item.id}</td> */}
              <li className='tb_category m-title'>
                <p>{item.category_name}</p>
              </li>
              <li className='tb_category_desc m-content'>
                <p>{item.category_shortName}</p>
              </li>
              <li className='set'>
                <Link to={`/cate_view/${item.id}`} className='detail-btn'>
                  상세보기
                </Link>
              </li>
            </ul>
          ))}
        </div>
      </div>
      <div className='button_wrap'>
        <button onClick={handleDeleteSelected} className='delete-btn btn wh'>
          삭제하기
        </button>
        <Link to='/cate_write' className='btn create-btn m-c-c-b'>
          <span>등록하기</span>
        </Link>
      </div>
    </div>
  );
};

export default CateList;

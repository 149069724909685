import { useState, useEffect, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Useyn from '../../components/Useyn';
import arrowImg from '../../imgs/icon/ico-arrow-select.png';

const SubWrite = () => {
  const { id } = useParams();
  const [episodeId, setEpisodeId] = useState('');
  const [chapterId, setChapterId] = useState('0');
  const [subName, setSubName] = useState('');
  const [subUse, setSubUse] = useState('N'); // 카테고리 이름을 저장하는 state
  const [episodeData, setEpisodeData] = useState([]);
  const [chapterData, setChapterData] = useState([]);
  const [nextOpts, setnextOpts] = useState([]); // 다음 카테고리 선택

  // 등록할 때, 데이터를 불러올 함수
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/bub/yj/episode`)
      .then((response) => {
        const episodeData = response.data.yjEpiList.filter(
          (episode) => episode.use_yn !== 'N',
        ); // 첫 번째 요소에 있는 카테고리 정보
        setEpisodeData(episodeData);
        if (!id) {
          setEpisodeId(episodeData[0].id);
        }
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        alert(error.response.data.message);
      });

    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/bub/yj/chapter`)
      .then((response) => {
        const chapterData = response.data.yjChapterList.filter(
          (chapter) => chapter.use_yn !== 'N',
        ); // 첫 번째 요소에 있는 카테고리 정보
        setChapterData(chapterData);
        console.log('res:', response);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // 수정할 때, 데이터를 불러올 함수
  useEffect(() => {
    if (id) {
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/bub/yj/subtitle/${id}`)
        .then((response) => {
          const subtitleData = response.data.yjSubtitle[0]; // 첫 번째 요소에 있는 카테고리 정보
          setEpisodeId(subtitleData.episode_id);
          setChapterId(subtitleData.chapter_id);
          setSubName(subtitleData.subtitle);
          setSubUse(subtitleData.use_yn);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          alert(error.response.data.message);
        });
    }
  }, [id]);

  // 편 이름이 변경되었을 때, 장 이름에서 선택할 수 있는 목록을 변경
  useEffect(() => {
    setnextOpts(chapterData.filter((cp) => cp.episode_id === episodeId));
  }, [chapterData]);

  const matchedEpisode = episodeData.find(
    (episode) => episode.id === episodeId,
  );
  // episode_id가 일치하는 편 이름을 찾아서 변수에 저장
  const episodeName = matchedEpisode
    ? matchedEpisode.episode_shortName
    : episodeData[0]?.episode_shortName;

  const matchedChapter = chapterData.find(
    (chapter) => chapter.id === chapterId,
  );
  // chapter_id가 일치하는 장 이름을 찾아서 변수에 저장
  const chapterName = matchedChapter ? matchedChapter.chapter : '';

  // 편 이름이 변경되었을 때
  const handleSelectChange = (e) => {
    const newEpisodeName = e.target.value;
    const matchedEpisode = episodeData.find(
      (episode) => episode.episode_shortName === newEpisodeName,
    );

    let this_data = episodeData.find(
      (ep) => ep.episode_shortName === e.target.value,
    );
    setnextOpts(chapterData.filter((cp) => cp.episode_id === this_data.id));
    setChapterId(
      chapterData.filter((cp) => cp.episode_id === this_data.id)[0]?.id,
    );

    if (matchedEpisode) {
      setEpisodeId(matchedEpisode.id);
      //console.log(matchedEpisode.id);
    } else {
      setEpisodeId(''); // 또는 적절한 기본값 설정
    }
  };

  // 장 이름이 변경되었을 때
  const handlechapterChange = (e) => {
    const newChapterName = e.target.value;
    const matchedChapter = chapterData.find(
      (chapter) =>
        chapter.chapter.replaceAll(' ', '') ===
        newChapterName.replaceAll(' ', ''),
    );
    if (matchedChapter) {
      setChapterId(matchedChapter.id);
    } else {
      setChapterId('0'); // 또는 적절한 기본값 설정
    }
  };

  // 등록 또는 수정 버튼을 눌렀을 때
  const handleSubmit = (e) => {
    e.preventDefault();

    const postData = {
      episode_id: episodeId,
      // chapter_id: chapterId,
      subtitle: subName,
      use_yn: subUse,
    };

    if (chapterId !== '0') {
      postData.chapter_id = chapterId;
    }

    if (id) {
      if (window.confirm('정말로 수정하시겠습니까?')) {
        axios
          .patch(
            `${process.env.REACT_APP_SERVER_URL}/bub/yj/subtitle/${id}`,
            postData,
          )
          .then((response) => {
            console.log('수정 성공:', response.data);
            // 수정 성공 후, 상세보기 페이지로 이동
            window.location.pathname = `/subtitle_view/${id}`;
          })
          .catch((error) => {
            console.error('수정 실패:', error);
          });
      }
    } else {
      // 새로운 카테고리 등록
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}/bub/yj/subtitle`, postData)
        .then((response) => {
          console.log('등록 성공:', response.data);
          // 등록 성공 후, 목록 페이지로 이동
          window.location.pathname = '/subtitle_list';
        })
        .catch((error) => {
          console.error('등록 실패:', error);
        });
    }
  };

  return (
    <div className='content'>
      <div className='logo-text'>한마음과학원</div>
      <div className='category-titles'>
        <span>법문관리</span>
        <span>한마음 요전</span>
        <span className='status-title'>소제목 관리</span>
      </div>
      <div className='page-head'>
        <span>{id ? '소제목 관리 수정' : '소제목 관리 등록'}</span>
      </div>
      <form onSubmit={handleSubmit} className='data-form'>
        <div className='form-inner'>
          <div className='division'>
            <div className='data-box'>
              <label>편 이름:</label>
              <div className='select-box'>
                <select
                  value={episodeName}
                  onChange={handleSelectChange}
                  required
                >
                  {episodeData.map((episode) => (
                    <option key={episode.id} value={episode.episode_shortName}>
                      {episode.episode_shortName}
                    </option>
                  ))}
                </select>
                <span className='iconArrow'>
                  <img src={arrowImg} alt='arrow image' />
                </span>
              </div>
            </div>
            <div className='data-box'>
              <label>장 이름:</label>
              <div className='select-box'>
                <select value={chapterName} onChange={handlechapterChange}>
                  {nextOpts.map((chapter) => (
                    <option key={chapter.id} value={chapter.chapter}>
                      {chapter.chapter}
                    </option>
                  ))}
                </select>
                <span className='iconArrow'>
                  <img src={arrowImg} alt='arrow image' />
                </span>
              </div>
            </div>
          </div>
          <div className='division'>
            <div className='data-box'>
              <label>소제목 이름:</label>
              <input
                type='text'
                className='data-in-box'
                value={subName}
                onChange={(e) => setSubName(e.target.value)}
                required
              />
            </div>
            <Useyn
              value={subUse}
              onChange={(e) => setSubUse(e.target.value)}
              required
            />
          </div>
        </div>
        <div className='button_wrap'>
          <Link to='/subtitle_list' className='btn wh'>
            취소하기
          </Link>
          <button type='submit' className='btn blk'>
            {id ? '수정하기' : '등록하기'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SubWrite;

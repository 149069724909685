import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const ScrapWrite = ({ openScrap, closeScrap, type, allId }) => {
  const location = useLocation();
  const [checkLocation, setCheckLocation] = useState(false);
  const [folders, setFolders] = useState([]);
  const [currentFolder, setCurrentFolder] = useState('');
  const lastInputRef = useRef(null);
  const [isEditing, setIsEditing] = useState(true);
  const [newFolder, setNewFolder] = useState(true);
  const [newFolderIndex, setNewFolderIndex] = useState(null);
  const [selectedItems, setSelectedItems] = useState('');
  const [csName, setCsName] = useState('');
  const [findscrap, setFindscrap] = useState([]);
  const [editingFolder, setEditingFolder] = useState(null);
  const [editFolderName, setEditFolderName] = useState('');
  const [isReEditing, setIsReEditing] = useState(true);

  useEffect(() => {
    const regex = /^\/search/;

    const match = location.pathname.match(regex);
    if (match) {
      setCheckLocation(true);
    } else {
      setCheckLocation(false);
    }
  }, [location]);

  useEffect(() => {
    // 마지막 아이템이 추가될 때 포커스를 설정
    if (lastInputRef.current) {
      lastInputRef.current.focus();
    }
  }, [folders]);

  const fetchData = () => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/scrap/folder/list`, {
        withCredentials: true,
      })
      .then((res) => {
        const folderlist = res.data.find_folder;
        setFolders(folderlist);
        if (folderlist.length === 0) {
          setNewFolder(true);
        } else {
          setNewFolder(false);
        }
      })
      .catch((err) => {
        //console.log(err);
        setNewFolder(true);
      });
  };

  useEffect(() => {
    fetchData();
    const newFolderInput = document.querySelector('.last_input input');
    if (newFolderInput) {
      newFolderInput.focus();
    }
  }, [openScrap]);

  useEffect(() => {
    if (isEditing) return;

    const postData = {
      sf_name: currentFolder,
    };

    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/scrap/folder/create`,
        postData,
        {
          withCredentials: true,
        },
      )
      .then((res) => {
        //console.log('등록 성공:', res.data);
        setNewFolder(false);
        fetchData();
      })
      .catch((err) => {
        //console.log(err);
        fetchData();
      });
  }, [isEditing]);

  useEffect(() => {
    if (isReEditing) return;

    const postData2 = {
      sf_name: editFolderName,
    };

    axios
      .patch(
        `${process.env.REACT_APP_SERVER_URL}/scrap/folder/update/${editingFolder}`,
        postData2,
        {
          withCredentials: true,
        },
      )
      .then((res) => {
        //console.log('등록 성공:', res.data);
        fetchData();
        setEditingFolder(null);
      })
      .catch((err) => {
        //console.log(err);
        fetchData();
        setEditingFolder(null);
      });
  }, [isReEditing]);

  const handleNewFolder = () => {
    setNewFolder(true);
    setFolders([...folders, '새폴더']); // 빈 문자열을 추가하거나 원하는 초기값으로 변경할 수 있습니다.
    setNewFolderIndex(folders.length); // 새로운 폴더의 index 설정
    setSelectedItems(''); // 새로운 폴더를 선택 상태로 설정

    // JavaScript 코드
    const checkedInput = document.querySelector('.scrap_list input:checked');

    if (checkedInput) {
      checkedInput.checked = false;
    }

    setFindscrap([]);
  };

  useEffect(() => {
    const lastInput = document.querySelector('.last_input');
    const lastInputRadio = document.querySelector('.scrap_list li:last-child');
    const newInput = document.querySelector('.last_input input');

    if (newFolder === true) {
      // .last_input 클래스를 가진 요소가 존재하는지 확인 후 처리
      if (lastInput && lastInput.previousElementSibling) {
        lastInput.previousElementSibling.style.display = 'none';
      }
      if (newInput) {
        newInput.focus();
      }
    } else {
      if (lastInputRadio) {
        lastInputRadio.style.display = 'flex';
      }
      setFindscrap([]);
    }
  }, [newFolder]);

  const handleCheckboxChange = (id) => {
    setSelectedItems((prevIds) => {
      // 현재 선택 상태 확인
      const isSelected = prevIds.includes(id);

      // 체크박스가 선택되었을 경우, id를 배열에 추가
      // 체크박스가 해제되었을 경우, id를 배열에서 제거
      return isSelected ? prevIds.filter((prevId) => prevId !== id) : [id];
    });

    const titleInput = document.querySelector('.input-value-box input');
    if (titleInput) {
      titleInput.focus();
    }

    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/scrap/list/${id}`, {
        withCredentials: true,
      })
      .then((res) => {
        const scraplist = res.data.find_scrap;
        setFindscrap(scraplist);
      })
      .catch((err) => {
        //console.log(err);
        setFindscrap([]);
      });
  };

  const handleEditClick = () => {
    for (const id of selectedItems) {
      setEditingFolder(id);
    }
    //console.log(editingFolder);
  };

  const deleteFolder = () => {
    try {
      // 선택된 아이템들을 순회하며 서버에서 삭제하는 API 호출
      if (window.confirm('정말로 삭제하시겠습니까?')) {
        for (const id of selectedItems) {
          axios
            .delete(
              `${process.env.REACT_APP_SERVER_URL}/scrap/folder/delete/${id}`,
              {
                withCredentials: true,
              },
            )
            .then((response) => {
              //console.log('삭제 성공:', response.data);
              // JavaScript 코드
              const checkedInput = document.querySelector(
                '.scrap_list input:checked',
              );

              if (checkedInput) {
                checkedInput.checked = false;
              }

              setFindscrap([]);
            })
            .catch((error) => {
              //console.error('삭제 실패:', error);
            });
        }

        // 선택된 아이템들을 화면에서도 제거
        setFolders((data) =>
          data.filter((item) => !selectedItems.includes(item.sf_id)),
        );

        // 선택 상태 초기화
        setSelectedItems([]);

        if (folders.length === 0) {
          setNewFolder(true);
        }
      } else {
        return;
      }
    } catch (error) {
      //console.error('데이터 삭제 중 오류가 발생했습니다.', error);
    }
  };

  const scrapSave = () => {
    let sfId = '';

    for (const id of selectedItems) {
      sfId = id;
    }

    const postData = {
      sf_id: sfId,
      all_id: allId,
      all_type: type,
      cs_name: csName,
    };

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/scrap/create`, postData, {
        withCredentials: true,
      })
      .then((res) => {
        //console.log('등록 성공:', res.data);
        alert('저장되었습니다.');
        closeScrap();
      })
      .catch((err) => {
        //console.log(err);
      });
  };

  useEffect(() => {
    if (closeScrap) {
      setSelectedItems([]);
    }
  }, [closeScrap]);

  if (!openScrap) return null;

  return (
    <div className='modal'>
      <div className='detail_view'>
        <div className='detail_view__content han'>
          <div className='detail_view__content__header fordetail-header'>
            <h3>스크랩</h3>
            <button onClick={closeScrap} className='close-btn'>
              X
            </button>
          </div>
          <div className='detail_view__content__body'>
            <div className='scrap_wrap'>
              {selectedItems.length !== 0 ? (
                <div className='scrap_wrap__content'>
                  <div className='input-value-box'>
                    <span className='label'>제목</span>
                    <div className='input-box'>
                      <input
                        type='text'
                        name=''
                        placeholder='제목을 입력해주세요.'
                        onChange={(e) => {
                          setCsName(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className='scrap_wrap__content'>
                  <div className='input-value-box'>
                    <span className='label'>제목</span>
                    <div className='input-box'>
                      <input
                        type='text'
                        name=''
                        placeholder='폴더명 선택 후 제목을 입력할 수 있습니다.'
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className='scrap_wrap__box'>
                <div className='scrap_list'>
                  <ul>
                    {folders &&
                      folders.map((folder, index) => (
                        <li key={index}>
                          {folder.sf_id === editingFolder ? (
                            <>
                              <input
                                type='text'
                                id={`radio_${index}`}
                                defaultValue={folder.sf_name}
                                onFocus={() => setIsReEditing(true)}
                                onKeyUp={(e) => {
                                  if (e.key === 'Enter') {
                                    e.preventDefault();
                                    setEditFolderName(e.target.value);
                                    setIsReEditing(false);
                                  }
                                }}
                                onBlur={(e) => {
                                  setEditFolderName(e.target.value);
                                  setIsReEditing(false);
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <input
                                type='radio'
                                id={`radio_${index}`}
                                name='folder'
                                onChange={() => {
                                  handleCheckboxChange(folder.sf_id);
                                  setSelectedItems([folder.sf_id]);
                                }}
                              />
                              <label htmlFor={`radio_${index}`}>
                                <span>{folder.sf_name}</span>
                              </label>
                            </>
                          )}
                        </li>
                      ))}
                    {newFolder && (
                      <li className='last_input'>
                        <input
                          type='text'
                          onChange={(e) => {
                            const newFolders = [...folders];
                            newFolders[newFolderIndex] = e.target.value;
                            setFolders(newFolders);
                          }}
                          onFocus={() => setIsEditing(true)}
                          onBlur={(e) => {
                            setCurrentFolder(e.target.value);
                            setIsEditing(false);
                            setNewFolder(false);
                          }}
                          placeholder='폴더명을 입력해주세요.'
                        />
                      </li>
                    )}
                  </ul>
                </div>
                <div className='scrap_list_child'>
                  <ul>
                    {findscrap && findscrap.length > 0
                      ? findscrap.map((scrap, index) => (
                          <li key={index}>{scrap.cs_name}</li>
                        ))
                      : null}
                  </ul>
                </div>
              </div>
              <div className='scrap-box box-wrap'>
                <div>
                  <button
                    className='btns wh-border'
                    onClick={(e) => {
                      setNewFolder(true);
                      handleNewFolder();
                    }}
                  >
                    새폴더
                  </button>
                  <button
                    className='btns wh-border'
                    onClick={(e) => {
                      handleEditClick();
                    }}
                  >
                    폴더명 수정
                  </button>
                  <button
                    className='btns wh-border'
                    onClick={(e) => {
                      deleteFolder();
                    }}
                  >
                    폴더 삭제
                  </button>
                </div>
                <div>
                  <a className='btns wh-border' onClick={closeScrap}>
                    취소
                  </a>
                  <button className='btns search-orange' onClick={scrapSave}>
                    저장
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          checkLocation ? 'detail_view__overlay_search' : 'detail_view__overlay'
        }`}
        onClick={closeScrap}
      ></div>
    </div>
  );
};

export default ScrapWrite;

/**
 * @title 논문관리 작성 페이지 - 논문기본정보 입력 컴포넌트
 * @author 김진석
 * @param info 논문기본정보 객체
 * @param setInfo 논문기본정보 객체 수정 함수
 * @returns 논문기본정보 입력 컴포넌트
 * @date 2023.09.12
 */
import { useState } from 'react';
import arrowImg from '../../../../imgs/icon/ico-arrow-select.png';

const SmInfoComponent = ({ info, setInfo }) => {
  return (
    <div className='form-inner'>
      <div className='division'>
        <div className='data-box'>
          <label>문서의 유형</label>
          <div className='select-box'>
            <select
              name='categoryUse'
              value={info.SM_Cate_Name}
              onChange={(e) =>
                setInfo({ ...info, SM_Cate_Name: e.target.value })
              }
            >
              <option value='학위논문'>학위논문</option>
              <option value='학술지'>학술지</option>
              <option value='학술대회자료집'>학술대회자료집</option>
              <option value='단행본'>단행본</option>
            </select>
            <span className='iconArrow'>
              <img src={arrowImg} alt='arrow' />
            </span>
          </div>
        </div>
        <div className='data-box'>
          <label>문서의 제목</label>
          <input
            type='text'
            className='data-in-box'
            value={info.SM_Title}
            onChange={(e) => setInfo({ ...info, SM_Title: e.target.value })}
          />
        </div>
      </div>
      <div className='division'>
        <div className='data-box'>
          <label>저자</label>
          <input
            type='text'
            className='data-in-box'
            value={info.SM_Author}
            onChange={(e) => setInfo({ ...info, SM_Author: e.target.value })}
          />
        </div>
        <div className='division'>
          <div className='data-box'>
            <label>발행일자</label>
            <input
              type='tel'
              className='data-in-box'
              value={info.SM_Publication_YY}
              onChange={(e) => {
                // 입력값이 숫자인지 확인하는 정규표현식을 사용하여 검증
                const newValue = e.target.value.replace(/\D/g, ''); // 숫자가 아닌 문자를 제거
                setInfo({ ...info, SM_Publication_YY: newValue });
              }}
              maxLength='4'
              placeholder='발행연도를 입력하세요'
            />
          </div>
          <div className='data-box'>
            <label className='hidden'>문서의 발행월</label>
            <input
              type='tel'
              className='data-in-box'
              value={info.SM_Publication_MM}
              onChange={(e) => {
                // 입력값이 숫자인지 확인하는 정규표현식을 사용하여 검증
                const newValue = e.target.value.replace(/\D/g, ''); // 숫자가 아닌 문자를 제거
                setInfo({ ...info, SM_Publication_MM: newValue });
              }}
              maxLength='2'
              placeholder='발행월을 입력하세요'
            />
          </div>
        </div>
      </div>
      <SmTagInput info={info} setInfo={setInfo} />
      <div className='data-box'>
        <label>초록</label>
        <textarea
          className='data-in-box'
          value={info.SM_Abstract}
          onChange={(e) => setInfo({ ...info, SM_Abstract: e.target.value })}
        />
      </div>

      <div className='data-box chked-box'>
        <label className='checkbox'>
          <input
            type='checkbox'
            className=''
            checked={info.SM_Use_Yn === 'Y'}
            onChange={(e) =>
              setInfo({ ...info, SM_Use_Yn: e.target.checked ? 'Y' : 'N' })
            }
          />
          <i></i>
          <span>사용여부</span>
        </label>
      </div>
    </div>
  );
};

// 태그 인풋은 별도 컴포넌트로 분리한다.
const SmTagInput = ({ info, setInfo }) => {
  const [tagInput, setTagInput] = useState('');

  // 태그 입력할때마다 태그 추가
  const handleInputChange = (e) => {
    // 콤마 입력시 콤마 제거
    e.target.value = e.target.value.replace(/,/g, '');

    if (e.code === 'Space' || e.code === 'Comma') {
      e.preventDefault();
      let tagInput = e.target.value.trim();
      //tagInput 공백제거
      tagInput = tagInput.replace(/ /g, '');

      const reg = /[^ㄱ-ㅎ|ㅏ-ㅣ|가-힣|a-z|A-Z|0-9|,| ]/gi;
      const newTag = tagInput.replace(reg, '');
      if (newTag.length < 2) {
        alert('태그는 2글자 이상 입력해주세요.');
        return;
      }

      if (newTag) {
        setInfo({
          ...info,
          SM_Tags: [...info.SM_Tags, newTag],
        });
        setTagInput('');
      }
    }
  };
  const removeTag = (idx) => {
    // info.smTags의 idx번째 인덱스를 제거
    setInfo({
      ...info,
      SM_Tags: info.SM_Tags.filter((tag, index) => index !== idx),
    });
  };

  return (
    <>
      <div className='data-box'>
        <label>태그/주제어</label>
        <input
          type='text'
          className='data-in-box'
          value={tagInput}
          onChange={(e) => setTagInput(e.target.value)}
          onKeyUp={handleInputChange}
        />
        <div className='tag-list'>
          {info.SM_Tags.map((tag, index) => (
            <span
              className='tag-item'
              key={index}
              onClick={() => removeTag(index)}
            >
              {tag}
              <div>X</div>
            </span>
          ))}
        </div>
      </div>
    </>
  );
};

export default SmInfoComponent;

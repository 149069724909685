import { useEffect, useState } from 'react';
import SmDetailView from './sm_view';
import ScrapWrite from './scrap_write';

const Smitem = ({ sm, categoryMapping }) => {
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [isScrapOpen, setIsScrapOpen] = useState(false);
  const [smType, setSMtype] = useState('');

  let body = document.querySelector('body');

  useEffect(() => {
    if (categoryMapping === '10001') {
      setSMtype('books');
    } else if (categoryMapping === '10003') {
      setSMtype('journal');
    } else if (categoryMapping === '10004') {
      setSMtype('thesis');
    } else if (categoryMapping === '10002') {
      setSMtype('conference');
    }
  }, []);

  const openDetail = () => {
    setIsDetailOpen(true);
  };

  const closeDetail = () => {
    setIsDetailOpen(false);
  };

  const openScrap = () => {
    setIsScrapOpen(true);

    body.classList.add('on');
  };

  const closeScrap = () => {
    setIsScrapOpen(false);

    body.classList.remove('on');
  };

  return (
    <div className='bub_item'>
      <div className='bub_item__title'>
        <h3>{sm.SM_Title}</h3>
      </div>
      <div className='bub_item__content'>
        <p>{sm.SM_Abstract}</p>
        <div>
          <span>{sm.SM_Author}</span>
          <span>
            {sm.SM_Publication_YY}. {sm.SM_Publication_MM}
          </span>
        </div>
      </div>
      <div className='btn-options'>
        <button onClick={openDetail} className='opts-btn bg-bk'>
          상세보기
        </button>
        <SmDetailView
          item={sm}
          openDetail={isDetailOpen}
          closeDetail={closeDetail}
        />
        <button
          className='opts-btn scrap'
          onClick={() => openScrap(sm.SM_Document_Id)}
        >
          스크랩
        </button>
        <ScrapWrite
          openScrap={isScrapOpen}
          closeScrap={closeScrap}
          type={smType}
          allId={sm.SM_Document_Id}
        />
      </div>
    </div>
  );
};

export default Smitem;

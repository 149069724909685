import Select from 'react-select';
import { useState, useEffect } from 'react';

const SelectSubtitle = ({ props }) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const output = [];
      const chapValue = props.searchInput.chapter.value;
      const epiValue = props.searchInput.episode.value;
      let dataList = {};

      let url = `${process.env.REACT_APP_SERVER_URL}/bub/yj/subtitle`;

      const response = await fetch(url);
      const data = await response.json();

      dataList = data.yjSubtitle;

      if (chapValue !== null && chapValue.value) {
        dataList = dataList.filter((row) => {
          return row.chapter_id === chapValue.value;
        });
      } else if (epiValue !== null && epiValue.value) {
        dataList = dataList.filter((row) => {
          return row.episode_id === epiValue.value;
        });
      }
      dataList.forEach((row) => {
        output.push({
          value: row.id,
          label: row.subtitle,
        });
      });

      setOptions(output);
    };

    fetchData();
    setSelectedOption(null);
  }, [props.searchInput.episode.value, props.searchInput.chapter.value]);

  return (
    <div className='search-select'>
      <label>{props.searchInput.subtitle.name}</label>
      <Select
        className='bub-subtitle-select'
        placeholder='소제목'
        options={options}
        value={props.searchInput.subtitle.value}
        onChange={(e) => {
          props.setSearchInput({
            ...props.searchInput,
            subtitle: {
              ...props.searchInput.subtitle,
              value: e,
            },
          });
        }}
      />
    </div>
  );
};

export default SelectSubtitle;

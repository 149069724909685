import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Useyn from '../../components/Useyn';

const CateWrite = () => {
    const { id } = useParams();
    const [categoryName, setCategoryName] = useState('');
    const [categoryShortname, setCategoryShortname] = useState('');
    const [categoryUse, setCategoryUse] = useState('N'); // 카테고리 이름을 저장하는 state
    const [categoryPublisher, setCategoryPublisher] = useState('');
    const [categoryAuthor, setCategoryAuthor] = useState('');
    const [categoryDate, setCategoryDate] = useState('');

    useEffect(() => {
        if (id) {
            axios
                .get(`${process.env.REACT_APP_SERVER_URL}/bub/cate/${id}`)
                .then((response) => {
                    const categoryData = response.data.bubCate[0]; // 첫 번째 요소에 있는 카테고리 정보
                    setCategoryName(categoryData.category_name);
                    setCategoryShortname(categoryData.category_shortName);
                    setCategoryUse(categoryData.use_yn);
                    setCategoryPublisher(categoryData.publisher);
                    setCategoryAuthor(categoryData.author);
                    setCategoryDate(categoryData.publication_date);
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        }
    }, [id]);

    const handleSelectChange = (e) => {
        setCategoryUse(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const postData = {
            category_name: categoryName,
            category_shortName: categoryShortname,
            use_yn: categoryUse,
        };

        if (id) {
            // 카테고리 수정일 때
            if (window.confirm('정말로 수정하시겠습니까?')) {
                axios
                    .patch(`${process.env.REACT_APP_SERVER_URL}/bub/cate/${id}`, postData)
                    .then((response) => {
                        console.log('수정 성공:', response.data);
                        // 수정 성공 후, 상세보기 페이지로 이동
                        window.location.pathname = `/cate_view/${id}`;
                    })
                    .catch((error) => {
                        console.error('수정 실패:', error);
                    });
            } else {
                return;
            }
        } else {
            // 새로운 카테고리 등록
            axios
                .post(`${process.env.REACT_APP_SERVER_URL}/bub/cate`, postData)
                .then((response) => {
                    console.log('등록 성공:', response.data);
                    // 등록 성공 후, 목록 페이지로 이동
                    window.location.pathname = '/cate_list';
                })
                .catch((error) => {
                    console.error('등록 실패:', error);
                });
        }
    };

    return (
        <div className="content">
            <div className="logo-text">한마음과학원</div>
            <div className="category-titles">
                <span>법문관리</span>
                <span>허공을 걷는 길</span>
                <span className="status-title">카테고리 관리</span>
            </div>
            <div className="page-head">
                <span>{id ? '카테고리 관리 수정' : '카테고리 관리 등록'}</span>
            </div>
            <form onSubmit={handleSubmit} className="data-form">
                <div className="form-inner">
                        <div className="data-box">
                            <label>카테고리 이름</label>
                            <input
                                type="text"
                                className="data-in-box"
                                value={categoryName}
                                onChange={(e) => setCategoryName(e.target.value)}
                            />
                        </div>
                        <div className="data-box">
                            <label>카테고리 설명</label>
                            <input
                                type="text"
                                className="data-in-box"
                                value={categoryShortname}
                                onChange={(e) => setCategoryShortname(e.target.value)}
                            />
                        </div>
                        <div className="data-box">
                            <label>출판사</label>
                            <input
                                type="text"
                                className="data-in-box"
                                value={categoryPublisher}
                                onChange={(e) => setCategoryPublisher(e.target.value)}
                            />
                        </div>
                        <div className="data-box">
                            <label>저자</label>
                            <input
                                type="text"
                                className="data-in-box"
                                value={categoryAuthor}
                                onChange={(e) => setCategoryAuthor(e.target.value)}
                            />
                        </div>
                        <div className="data-box">
                            <label>출판일</label>
                            <input
                                type="text"
                                className="data-in-box"
                                value={categoryDate}
                                onChange={(e) => setCategoryDate(e.target.value)}
                            />
                        </div>


                    <Useyn value={categoryUse} onChange={handleSelectChange} />
                </div>
                <div className="button_wrap">
                    <Link to="/cate_list" className="btn cancle-btn">
                        취소하기
                    </Link>
                    <button type="submit" className="btn blk">
                        {id ? '수정하기' : '등록하기'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default CateWrite;

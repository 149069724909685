import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Pagination from '../../components/Pagination';

//검색 import
import ListSearch from '../../components/list/HggSearchComponent';

const TitleList = () => {
  const [data, setData] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  // URL에서 페이지 번호 파라미터를 추출
  const queryParams = new URLSearchParams(window.location.search);
  const currentPage = Number(queryParams.get('page')) || 1;

  const currentSection = queryParams.get('section') || '';
  const currentCategory = queryParams.get('category_id') || '';
  const currentTitle = '';
  const currentSearch = queryParams.get('search') || '';

  let itemsPerPage = 20;

  // 페이지가 변경되면 데이터를 다시 불러옴
  useEffect(() => {
    if (!currentSection) {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/bub/title?page=${currentPage}`,
        )
        .then((response) => {
          const dataArray = response.data;
          const bubTitle = dataArray.bubTitleList;
          console.log(bubTitle);
          const dataCount = dataArray.count[0];
          setData(bubTitle);
          setTotalItems(dataCount.count);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    } else {
      axios
        .get(
          `${process.env.REACT_APP_SERVER_URL}/bub/search/list?section=${currentSection}&category_id=${currentCategory}&search=${currentSearch}&page=${currentPage}`,
        )
        .then((response) => {
          const dataArray = response.data;
          const bubTitle = dataArray.bubSearchList;
          const dataCount = dataArray.count[0];
          setData(bubTitle);
          setTotalItems(dataCount.count);
          console.log('검색결과:', dataArray);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [currentPage]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // 페이지 변경 시 이벤트 처리
  const handlePageChange = (pageNumber) => {
    // URL을 업데이트하여 페이지 번호를 변경
    if (!currentSection) {
      window.location.search = `?page=${pageNumber}`;
    } else {
      window.location.search = `?section=${currentSection}&category_id=${currentCategory}&search=${currentSearch}&page=${pageNumber}`;
    }
  };

  // 페이지 번호 목록 생성
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  // 체크박스 선택 시
  const handleCheckboxChange = (id) => {
    setSelectedItems((prevIds) => {
      // 현재 선택 상태 확인
      const isSelected = prevIds.includes(id);

      // 체크박스가 선택되었을 경우, id를 배열에 추가
      // 체크박스가 해제되었을 경우, id를 배열에서 제거
      return isSelected
        ? prevIds.filter((prevId) => prevId !== id)
        : [...prevIds, id];
    });
  };

  // 선택된 아이템들을 삭제하는 함수
  const handleDeleteSelected = async () => {
    try {
      // 선택된 아이템들을 순회하며 서버에서 삭제하는 API 호출
      if (window.confirm('정말로 삭제하시겠습니까?')) {
        for (const id of selectedItems) {
          await axios
            .delete(`${process.env.REACT_APP_SERVER_URL}/bub/title/${id}`)
            .then((response) => {
              console.log('삭제 성공:', response.data);
            })
            .catch((error) => {
              console.error('삭제 실패:', error);
            });
        }

        // 데이터를 다시 불러옴
        await axios
          .get(
            `${process.env.REACT_APP_SERVER_URL}/bub/title?page=${currentPage}`,
          )
          .then((response) => {
            const dataArray = response.data;
            const bubTitle = dataArray.bubTitleList;
            setData(bubTitle);
          })
          .catch((error) => {
            console.error('Error fetching data:', error);
          });

        // 선택 상태 초기화
        setSelectedItems([]);
      } else {
        return;
      }
    } catch (error) {
      console.error('데이터 삭제 중 오류가 발생했습니다.', error);
    }
  };

  const handleSearch = (data) => {
    const { cateId, input } = data;
    const searchSection = '제목';
    const searchWrap = {
      section: encodeURIComponent(searchSection),
      category_id: cateId,
      search: encodeURIComponent(input),
    };

    console.log('검색어:', searchWrap);

    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/bub/search/list?section=${
          searchWrap.section
        }&category_id=${searchWrap.category_id}&search=${
          searchWrap.search ? searchWrap.search : ''
        }&page=1`,
      )
      .then((response) => {
        const dataArray = response.data;
        const bubPara = dataArray.bubSearchList;
        const dataCount = dataArray.count[0];
        setData(bubPara);
        setTotalItems(dataCount.count);
        console.log('검색결과:', dataArray);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });

    //window.location.search = `${location.pathname}?search=${searchLink2}&page=${currentPage}`;
    const url = `${location.pathname}?section=${
      searchWrap.section
    }&category_id=${searchWrap.category_id}&search=${
      searchWrap.search ? searchWrap.search : ''
    }&page=1`;
    window.history.pushState({ path: url }, '', url);
  };

  const hadleReset = () => {
    window.location.search = `${location.pathname}`;
  };

  return (
    <div className='content'>
      <div className='logo-text'>한마음과학원</div>
      <div className='category-titles'>
        <span>법문관리</span>
        <span>허공을 걷는 길</span>
        <span className='status-title'>제목 관리</span>
      </div>
      <ListSearch
        hgg='title'
        currentCategory={currentCategory}
        currentTitle={currentTitle}
        currentSearch={currentSearch}
        onSearch={handleSearch}
        onReset={hadleReset}
      />
      <div className='table-wrap'>
        <div className='tbl thead'>
          <ul>
            <li className='chk-box'></li>
            {/* <li>ID</li> */}
            <li className='tb_category'>카테고리 이름</li>
            <li className='tb_title'>제목 이름</li>
            <li className='tb_vol'>Vol</li>
            <li className='set'>관리</li>
          </ul>
        </div>
        <div className='tbl tbody'>
          {data.map((item) => (
            <ul key={item.id}>
              <li className='chk-box'>
                <label className='checkbox'>
                  <input
                    type='checkbox'
                    checked={selectedItems.includes(item.id)}
                    onChange={() => handleCheckboxChange(item.id)}
                  />
                  <i></i>
                </label>
              </li>
              {/* <td>{item.id}</td> */}
              <li className='tb_category m-title'>
                <p>{item.category_name}</p>
              </li>
              <li className='tb_title m-content'>
                <p>{item.title}</p>
              </li>
              <li className='tb_vol'>
                <p>{item.title_vol}</p>
              </li>
              <li className='set'>
                <Link to={`/title_view/${item.id}`} className='detail-btn'>
                  상세보기
                </Link>
              </li>
            </ul>
          ))}
        </div>
      </div>
      <div className='button_wrap'>
        <button onClick={handleDeleteSelected} className='delete-btn btn wh'>
          삭제하기
        </button>
        <Link to='/title_write' className='btn create-btn m-c-c-b'>
          <span>등록하기</span>
        </Link>
      </div>

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default TitleList;

import { useLocation, useOutletContext } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Keyword from '../../components/search/keyword';
import SearchResult from '../../components/search/search_result';
import BubsubMenu from '../../components/search/submenu';
import BubsearchMenu from '../../components/search/menu';
import Pagination from '../../components/Pagination';

const Bubsearch = () => {
  const user = useOutletContext();
  const location = useLocation();
  const [checkLocation, setCheckLocation] = useState(false);

  useEffect(() => {
    const regex = /^\/search/;

    const match = location.pathname.match(regex);
    if (match) {
      setCheckLocation(true);
    } else {
      setCheckLocation(false);
    }
  }, [location]);

  useEffect(() => {
    // 스크롤 이벤트 리스너 등록
    const handleScroll = () => {
      const scrollY = window.scrollY;

      // 스크롤 위치를 부모 창으로 전달
      window.parent.postMessage({ type: 'scroll', scrollY }, '*');
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [currentCate, setCurrentCate] = useState('');
  const [currentPage, setCurrentPage] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [searchData, setSearchData] = useState([]);

  const itemsPerPage = 10;

  const [searchText, setSearchText] = useState('');
  const [data, setData] = useState(null); // 받아온 데이터를 저장할 상태
  const [totaldata, setTotaldata] = useState(null); // 받아온 데이터를 저장할 상태

  const [keyWord, setKeyWord] = useState(''); // 검색어를 저장할 상태

  const [randomKeyWord, setRandomKeyWord] = useState(false); // 랜덤 검색어를 저장할 상태

  const searchDataLists = [];
  const items = [];

  const inputRef = useRef(null);

  // 검색어가 입력되었을 때만 요청 보내도록 설정
  useEffect(() => {
    if (data) {
      if (data.yj_searchList) {
        searchDataLists.push({
          list: data.yj_searchList,
          listcount: data.yj_count,
          type: 'yj',
        });
        items.push(data.yj_count);
      }
      if (data.bub_searchList) {
        searchDataLists.push({
          list: data.bub_searchList,
          listcount: data.bub_count,
          type: 'bub',
        });
        items.push(data.bub_count);
      }
      if (data.gn_searchList) {
        searchDataLists.push({
          list: data.gn_searchList,
          listcount: data.gn_count,
          type: 'bub',
        });
        items.push(data.gn_count);
      }
      if (data.gw_searchList) {
        searchDataLists.push({
          list: data.gw_searchList,
          listcount: data.gw_count,
          type: 'bub',
        });
        items.push(data.gw_count);
      }
      if (data.jg_searchList) {
        searchDataLists.push({
          list: data.jg_searchList,
          listcount: data.jg_count,
          type: 'bub',
        });
        items.push(data.jg_count);
      }
      if (data.ib_searchList) {
        searchDataLists.push({
          list: data.ib_searchList,
          listcount: data.ib_count,
          type: 'bub',
        });
        items.push(data.ib_count);
      }
      if (data.hgg_searchList) {
        searchDataLists.push({
          list: data.hgg_searchList,
          listcount: data.hgg_count,
          type: 'bub',
        });
        items.push(data.hgg_count);
      }
    }

    setSearchData(searchDataLists);
  }, [data]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    if (!searchText) {
      const KeyWordArr = ['주인공', '스님', '회고', '공부'];

      const random = Math.floor(Math.random() * KeyWordArr.length);
      const randomKeyWord = KeyWordArr[random];

      setSearchText(randomKeyWord);

      setRandomKeyWord(true);

      // 전체 카테고리일 떄
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}/bub/search`, {
          search: randomKeyWord,
        })
        .then((res) => {
          setData(res.data);
          setTotaldata(res.data);
          setTotalItems(res.data.total_count);
          setKeyWord(randomKeyWord);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);
  // 데이터를 불러올 함수
  const handleSearch = () => {
    // 카테고리를 선택할 때

    setRandomKeyWord(false);

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/bub/search`, {
        search: searchText,
      })
      .then((res) => {
        setTotaldata(res.data);
        setKeyWord(searchText);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL}/bub/search?cate=${currentCate}&page=${currentPage}`,
        {
          search: searchText,
        },
      )
      .then((res) => {
        setData(res.data);
        const totaldata = res.data.total_count;
        setTotalItems(totaldata); // 전체 아이템 수
        setKeyWord(searchText);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // 검색어 입력 시 이벤트 처리
  const fetchCateData = (categoryCode, pageNumber) => {
    if (searchText) {
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}/bub/search`, {
          search: searchText,
        }) // API 엔드포인트 URL
        .then((res) => {
          setTotaldata(res.data);
          setKeyWord(searchText);
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/bub/search?cate=${categoryCode}&page=${pageNumber}`,
          {
            search: searchText,
          },
        ) // API 엔드포인트 URL
        .then((res) => {
          setData(res.data);
          // setTotaldata(res.data);
          setCurrentCate(categoryCode); // 현재 카테고리
          setCurrentPage(pageNumber); // 현재 페이지
          setKeyWord(searchText);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // 현재 카테고리에 따라 데이터 요청
  useEffect(() => {
    fetchCateData(currentCate, currentPage);
  }, [currentCate, currentPage]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  // 페이지 변경 시 이벤트 처리
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // 전체 아이템 수 변경 시 이벤트 처리
  const handleTotalChange = (totalItems) => {
    setTotalItems(totalItems);
  };

  // 카테고리 변경 시 이벤트 처리
  const handleCateChange = (categoryCode) => {
    setCurrentCate(categoryCode);
    // setSearchData([]);
  };

  // 페이지 번호 목록 생성
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i += 1) {
    pageNumbers.push(i);
  }

  // 전체페이지 이동시 이벤트 처리
  const searchhandleCatereset = () => {
    handleCateChange('');
    handlePageChange('');
    const url = `${location.pathname}?cate=&page=`;
    window.history.pushState({ path: url }, '', url);

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/bub/search`, {
        search: searchText,
      })
      .then((res) => {
        setData(res.data);
        setTotalItems(res.data.total_count);
      })
      .catch((err) => {
        console.log(err);
      });

    setSearchData([]);
  };

  // 카테고리 이름과 코드를 매핑한 객체 생성
  const categoryMapping = {
    한마음요전: '20000',
    법형제법회: '10001',
    정기법회: '10002',
    국내법회: '10003',
    국외법회: '10004',
    일반법회: '10005',
  };

  const CateChange = (categoryCode, totalItems) => {
    handleCateChange(categoryCode);
    handlePageChange(1);
    setTotalItems(totalItems);
    const url = `${location.pathname}?cate=${categoryCode}&page=1`;
    window.history.pushState({ path: url }, '', url);
    // window의 scroll을 최상단으로 부드럽게 이동
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className='search'>
      <div className='search-inner'>
        {checkLocation ? null : (
          <div>
            <p className='page-title'>법문검색</p>
            <BubsearchMenu />
          </div>
        )}
        <div className={`search-box ${checkLocation ? 'delete-border' : ''}`}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSearch(); // 검색 버튼 클릭 시 검색 요청 보내기
            }}
          >
            <div className='input-wrap'>
              <div className='search-box__input'>
                <input
                  type='text'
                  value={!randomKeyWord ? searchText : ''}
                  placeholder='무엇을 찾고 계세요?'
                  onChange={(e) => {
                    setRandomKeyWord(false);
                    setSearchText(e.target.value);
                  }}
                  ref={inputRef}
                />
              </div>
              <div className='search-box__button'>
                <button type='submit'>검색</button>
              </div>
            </div>
            <p className='comment bottom-search'>
              ※ 검색 단어로 여러 단어 입력이 가능합니다. 예: 법문 법회
            </p>
          </form>
        </div>
        {/* {data && (
                    <BubsubMenu
                        onCateChange={handleCateChange}
                        onPageChange={handlePageChange}
                        handleCatereset={searchhandleCatereset}
                        ontotalChange={handleTotalChange}
                        dataArray={searchData}
                        totalArray={totaldata}
                        currentCategory={currentCate}
                    />
                )}
                {data && <Keyword keyword={searchText} keyword_count={totalItems} />} */}
        {data && (
          <BubsubMenu
            onCateChange={handleCateChange}
            onPageChange={handlePageChange}
            handleCatereset={searchhandleCatereset}
            ontotalChange={handleTotalChange}
            dataArray={searchData}
            totalArray={totaldata}
            currentCategory={currentCate}
          />
        )}
        {data && <Keyword keyword={keyWord} keyword_count={totalItems} />}
        {data &&
          searchData.length > 0 &&
          searchData.map((searchList, index) => {
            if (searchList.list.length > 0) {
              return (
                <div className='result-box' key={`searchResult-${index}`}>
                  {data && !currentPage && (
                    <div className='moreview'>
                      {data &&
                        searchList &&
                        searchList.list &&
                        searchList.list[0] && (
                          <h3>
                            {searchList.list[0].category_name || '한마음요전'}{' '}
                            <span>{searchList.listcount[0]?.count}</span>
                          </h3>
                        )}
                      {data &&
                        searchList &&
                        searchList.list &&
                        searchList.list[0] && (
                          <button
                            type='button'
                            className='more-btn'
                            onClick={() =>
                              CateChange(
                                categoryMapping[
                                  searchList?.list[0].category_name ||
                                    '한마음요전'
                                ],
                                searchList?.listcount[0].count,
                              )
                            }
                          >
                            <span>더보기</span>
                          </button>
                        )}
                    </div>
                  )}
                  <SearchResult
                    searchList={searchList}
                    currentPage={currentPage}
                    currentCate={currentCate}
                    user_id={user.mb_no}
                    type={searchList.type}
                    marker={keyWord}
                  />

                  {totalItems > 0 && currentPage && (
                    <Pagination
                      currentPage={currentPage}
                      totalPages={totalPages}
                      onPageChange={handlePageChange}
                    />
                  )}
                </div>
              );
            }
            // const dataCount = searchList.listcount[0];
            // setTotalItems(dataCount.count);
          })}
        {data === null ? (
          <p></p>
        ) : totalItems === 0 && searchData.length === 0 ? (
          <p>검색어가 없습니다.</p>
        ) : null}
        {/* {totalItems === 0 && searchData.length === 0 && <p>검색어가 없습니다.</p>} */}
      </div>
    </div>
  );
};

export default Bubsearch;

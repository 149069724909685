import Select from 'react-select';
import { useState, useEffect } from 'react';

const SelectEpisode = ({ props }) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const output = [];
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/bub/yj/episode`,
      );
      const data = await response.json();
      let dataList = data.yjEpiList;

      dataList.forEach((row) => {
        output.push({
          value: row.id,
          label: row.episode,
        });
      });

      setOptions(output);
    };

    fetchData();
    setSelectedOption(props.searchInput.episode.value);
  }, []);

  const epiOnChange = (e) => {
    const subInputType = props.searchInput.subtitle.inputType;
    setSelectedOption(e);
    props.setSearchInput({
      ...props.searchInput,
      episode: {
        ...props.searchInput.episode,
        value: e,
      },
      chapter: {
        ...props.searchInput.chapter,
        value: null,
      },
      subtitle: {
        ...props.searchInput.subtitle,
        value:
          subInputType === 'select' ? null : props.searchInput.subtitle.value,
      },
    });
  };

  return (
    <div className='search-select'>
      <label>{props.searchInput.episode.name}</label>
      <Select
        value={props.searchInput.episode.value}
        className='bub-episode-select'
        placeholder='편'
        options={options}
        onChange={epiOnChange}
      />
    </div>
  );
};

export default SelectEpisode;

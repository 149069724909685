import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const BubsearchMenu = () => {
    // 현재 페이지 URL 가져오기
  const location = useLocation();
// 현재 URL과 해당 링크의 URL 비교 함수
const isActiveLink = (linkPath) => {
    return location.pathname === linkPath;
  };

    return (
        <div className='bubmenu'>
            <ul>
                <li><Link to="/bub_search"
                className={isActiveLink("/bub_search") ? "active" : ""}>법문 검색</Link></li>
                <li><Link to="/bub_detail_search"
                className={isActiveLink("/bub_detail_search") ? "active" : ""}>상세 검색</Link></li>
                <li><Link to="/scrap_list?page=1&type=bub"
                className={isActiveLink("/scrap_list") ? "active" : ""}>스크랩</Link></li>
                <li><Link to="/sutras"
                className={isActiveLink("/sutras") ? "active" : ""}>온라인 사경</Link></li>
            </ul>
        </div>
    );
};

export default BubsearchMenu;
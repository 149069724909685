import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Bubsearch from './pages/search/bub_search';
import Smsearch from './pages/search/sm_search';
import Bubdetailsearch from './pages/search/bub_detail_search';
import Sutras from './pages/search/sutras';
import SutrasListView from './pages/search/sutras_view';
import SutrasEdit from './pages/search/sutras_edit';
import SmdetailSearch from './pages/search/sm_detail_search';
import CheckUsers from './components/CheckUsers';
import ScrapList from './pages/search/scrap_list';

// 쿠키값을 확인하여, 유저 정보에 따라 분기처리 추가해야함.

const Search_router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<CheckUsers />}>
          <Route path='/search/bub_search' element={<Bubsearch />} />
          <Route
            path='/search/bub_detail_search'
            element={<Bubdetailsearch />}
          />
          <Route path='/search/sutras' element={<Sutras />} />
          <Route path='/search/sutras_view/:id' element={<SutrasListView />} />
          <Route path='/search/sutras_edit/:id' element={<SutrasEdit />} />
          <Route path='/search/sm_search' element={<Smsearch />} />
          <Route path='/search/sm_detail_search' element={<SmdetailSearch />} />
          <Route path='/search/scrap_list' element={<ScrapList />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Search_router;

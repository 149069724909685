import { useState } from 'react';
import DetailView from './detail_view';
import SutrasView from './sutras_view';
import ScrapWrite from './scrap_write';
import axios from 'axios';
import { useOutletContext } from 'react-router-dom';

const Detailitem = ({ bub, user_id, type, marker }) => {
  const user = useOutletContext();
  const [isDetailOpen, setIsDetailOpen] = useState(false);
  const [isSutrasOpen, setIsSutrasOpen] = useState(false);
  const [isScrapOpen, setIsScrapOpen] = useState(false);
  const [data, setData] = useState([]);

  let body = document.querySelector('body');

  const openDetail = () => {
    setIsDetailOpen(true);

    body.classList.add('on');

    if (bub?.category_name) {
      axios
        .post(
          `${process.env.REACT_APP_SERVER_URL}/bub/para/search_detail/${bub.id}/${bub.category_id}/${bub.title_seq}`,
        ) // API 엔드포인트 URL
        .then((res) => {
          const dataArray = res.data;
          let bubPara = dataArray.bubPara;
          if (user.mb_2 !== '연구') {
            bubPara = bubPara.slice(0, 1);
          }
          setData(bubPara);
          console.log(bubPara);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const closeDetail = () => {
    setIsDetailOpen(false);

    body.classList.remove('on');
  };

  const openSutras = () => {
    setIsSutrasOpen(true);
  };

  const closeSutras = () => {
    setIsSutrasOpen(false);
  };

  const openScrap = () => {
    setIsScrapOpen(true);

    body.classList.add('on');
  };

  const closeScrap = () => {
    setIsScrapOpen(false);

    body.classList.remove('on');
  };

  const title_span = bub?.category_name
    ? `[${bub.category_name} Vol.${bub.title_vol} P.${bub.paragraph_page}]`
    : `${bub.navigation}`;

    const bubsentence = bub?.paragraph;
    const bubregex = new RegExp(`(${marker})`, 'gi');
    const bubparts = bubsentence.split(bubregex);

    const handleCopy = (event) => {
      const copiedText = window.getSelection().toString();
  
      let sourceInfo = '';
  
      if (bub.category_name) {
      const bdYear = bub.bdate.substring(0, 4);
     sourceInfo = `[출처 : ${bub.publisher}, 『허공을 걷는 길 : ${bub.category_name} ${bub.title_vol}권』 ${bub.author}(${bdYear}), ${bub.paragraph_page}쪽]`;
      } else {
        const sourceDate = bub.publication_date.substring(0, 4);
        sourceInfo = `[출처 : ${bub.publisher}, 『한마음요전 개정판』 ${bub.author}(${sourceDate})]`;
      }
      
      // Combine the copied text and source information
      const textWithSource = `${copiedText}\n\n${sourceInfo}`;
  
      // Write the combined text to the clipboard
      event.clipboardData.setData('text/plain', textWithSource);
  
      // Prevent the normal copy behavior
      event.preventDefault();
    }

  return (
    <div className='bub_item'>
      <div className='bub_item__content'>
        <p onCopy={handleCopy}>
        {marker ? bubparts
  .slice(0, 100) // 최대 150자까지만 자름
  .map((part, index) =>
    bubregex.test(part) ? <b key={index}>{part}</b> : part
  ) : bub?.paragraph}
        </p>
      </div>
      <div className='btn-options'>
        {bub?.category_name ? (
          <>
            <button onClick={() => openDetail(bub.id)} className='opts-btn'>
              전체보기
            </button>
            <DetailView
              item={bub}
              openDetail={isDetailOpen}
              closeDetail={closeDetail}
              cate='bub'
              data={data}
              marker={marker}
            />
            {(user?.mb_2 === '연구' && user?.mb_level === '2') ||
            user?.mb_level > 6 ? (
              <>
                <button
                  className='opts-btn scrap'
                  onClick={() => openScrap(bub.id)}
                >
                  스크랩
                </button>
                <ScrapWrite
                  openScrap={isScrapOpen}
                  closeScrap={closeScrap}
                  type='hgg'
                  allId={bub.id}
                />
              </>
            ) : (
              <> </>
            )}
          </>
        ) : (
          <>
            <button onClick={openDetail} className='opts-btn'>
              상세보기
            </button>
            <DetailView
              item={bub}
              openDetail={isDetailOpen}
              closeDetail={closeDetail}
              cate='yj'
              marker={marker}
            />
            {(user?.mb_2 === '연구' && user?.mb_level === '2') ||
            user?.mb_level > 6 ? (
              <>
                <button
                  className='opts-btn scrap'
                  onClick={() => openScrap(bub.id)}
                >
                  스크랩
                </button>
                <ScrapWrite
                  openScrap={isScrapOpen}
                  closeScrap={closeScrap}
                  type='yj'
                  allId={bub.id}
                />
              </>
            ) : (
              <> </>
            )}
          </>
        )}
        {(user?.mb_2 === '연구' && user?.mb_level === '2') ||
        user?.mb_level > 6 ? (
          <>
            <button onClick={openSutras} className='opts-btn'>
              사경
            </button>
            <SutrasView
              item={bub}
              openSutras={isSutrasOpen}
              closeSutras={closeSutras}
              user_id={user_id}
              type={type}
            />
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Detailitem;

import React from 'react';
import Detailitem from './detail_item';

const DetailSearchResult = ({ searchList, user_id, type, marker }) => {

  return (
      <div className="item-result">
          {searchList.map((searchData, index) => (
          <Detailitem key={index} bub={searchData} user_id={user_id} type={type} marker={marker} />
      ))}
      </div>
  );
};

export default DetailSearchResult;

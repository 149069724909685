import { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Useyn from '../../components/Useyn';
import arrowImg from '../../imgs/icon/ico-arrow-select.png';

const TitleWrite = () => {
  const { id } = useParams();
  const [titleName, setTitleName] = useState('');
  const [cateId, setCateId] = useState('');
  const [titleVol, setTitleVol] = useState('');
  const [titleBdate, setTitleBdate] = useState('');
  const [categoryName, setCategoryName] = useState(''); // 카테고리 이름을 저장하는 state
  const [titleUse, setTitleUse] = useState('N'); // 카테고리 이름을 저장하는 state
  const [titleSeq, setTitleSeq] = useState(''); // 카테고리 이름을 저장하는 state
  const [categoryList, setCategoryList] = useState([]); // 카테고리 데이터를 저장하는 state

  // 등록할 때 실행
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SERVER_URL}/bub/cate`)
      .then((response) => {
        const dataArray = response.data;
        const bubCate = dataArray.bubCateList.filter(
          (category) => category.use_yn !== 'N',
        );
        setCategoryList(bubCate);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []);

  // 수정일 때만 실행
  useEffect(() => {
    if (id) {
      axios
        .get(`${process.env.REACT_APP_SERVER_URL}/bub/title/${id}`)
        .then((response) => {
          const titleData = response.data.bubTitle[0]; // 첫 번째 요소에 있는 카테고리 정보
          setCateId(titleData.category_id);
          setCategoryName(titleData.category_name);
          setTitleName(titleData.title);
          setTitleVol(titleData.title_vol);
          setTitleBdate(titleData.bdate);
          setTitleUse(titleData.use_yn);
          setTitleSeq(titleData.title_seq);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [id]);

  // 카테고리 셀렉트 박스 변경 시
  const handleCateChange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const category_id = Number(selectedOption.id);

    const cate = categoryList.filter((item) => {
      return item.id === category_id;
    });

    setCateId(cate[0].id);
    setCategoryName(e.target.value);
  };

  // 사용여부 셀렉트 박스 변경 시
  const handleUseChange = (e) => {
    setTitleUse(e.target.value);
  };

  // 등록 버튼 클릭 시
  const handleSubmit = (e) => {
    e.preventDefault();

    const postData = {
      category_id: cateId,
      category_name: categoryName,
      title: titleName,
      title_vol: titleVol,
      bdate: titleBdate,
      use_yn: titleUse,
      title_seq: titleSeq,
    };

    if (id) {
      if (window.confirm('정말로 수정하시겠습니까?')) {
        axios
          .patch(
            `${process.env.REACT_APP_SERVER_URL}/bub/title/${id}`,
            postData,
          )
          .then((response) => {
            console.log('수정 성공:', response.data);
            window.location.pathname = `/title_view/${id}`;
          })
          .catch((error) => {
            console.error('수정 실패:', error);
            alert(error.response.data.message);
          });
      }
    } else {
      axios
        .post(`${process.env.REACT_APP_SERVER_URL}/bub/title`, postData)
        .then((response) => {
          console.log('등록 성공:', response.data);
          window.location.pathname = '/title_list';
        })
        .catch((error) => {
          console.error('등록 실패:', error);
          alert(error.response.data.message);
        });
    }
  };

  return (
    <div className='content'>
      <div className='logo-text'>한마음과학원</div>
      <div className='category-titles'>
        <span>법문관리</span>
        <span>허공을 걷는 길</span>
        <span className='status-title'>제목 관리</span>
      </div>
      <div className='page-head'>
        <span>{id ? '제목 관리 수정' : '제목 관리 등록'}</span>
      </div>
      <form onSubmit={handleSubmit} className='data-form'>
        <div className='form-inner'>
          <div className='division'>
            <div className='data-box'>
              <label>카테고리 이름</label>
              <div className='select-box'>
                <select value={categoryName} onChange={handleCateChange}>
                  {categoryList.map((item) => (
                    <option
                      key={item.id}
                      id={item.id}
                      value={item.category_name}
                    >
                      {item.category_name}
                    </option>
                  ))}
                </select>
                <span className='iconArrow'>
                  <img src={arrowImg} alt='arrow image' />
                </span>
              </div>
            </div>
            <div className='data-box'>
              <label>제목 이름</label>
              <input
                type='text'
                className='data-in-box'
                value={titleName}
                onChange={(e) => setTitleName(e.target.value)}
              />
            </div>
          </div>
          <div className='division'>
            <div className='data-box'>
              <label>Vol</label>
              <input
                type='tel'
                className='data-in-box'
                value={titleVol}
                onChange={(e) => {
                  const newValue = e.target.value.replace(/\D/g, ''); // 숫자가 아닌 문자를 제거
                  setTitleVol(newValue);
                }}
              />
            </div>
            <div className='data-box'>
              <label>단락 순서</label>
              <input
                type='tel'
                className='data-in-box'
                value={titleSeq}
                onChange={(e) => {
                  const newValue = e.target.value.replace(/\D/g, ''); // 숫자가 아닌 문자를 제거
                  setTitleSeq(newValue);
                }}
              />
            </div>
          </div>
          <div className='division'>
            <div className='data-box'>
              <label>법문날짜</label>
              <input
                type='date'
                className='data-in-box'
                value={titleBdate}
                onChange={(e) => setTitleBdate(e.target.value)}
              />
            </div>
            <Useyn value={titleUse} onChange={handleUseChange} />
          </div>
        </div>
        <div className='button_wrap'>
          <Link to='/title_list' className='btn wh'>
            취소하기
          </Link>
          <button type='submit' className='btn blk'>
            {id ? '수정하기' : '등록하기'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default TitleWrite;

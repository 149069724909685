import { useLocation } from 'react-router-dom';

const BubsubMenu = ({
  onCateChange,
  onPageChange,
  handleCatereset,
  ontotalChange,
  dataArray,
  totalArray,
  currentCategory,
}) => {
  const location = useLocation();
  //const cate = searchList[0]?.category_name || "한마음요전";

  // 카테고리 이름과 코드를 매핑한 객체 생성
  const categoryMapping = {
    한마음요전: '20000',
    법형제법회: '10001',
    정기법회: '10002',
    국내법회: '10003',
    국외법회: '10004',
    일반법회: '10005',
  };

  const countMapping = {
    한마음요전: 'yj_count',
    법형제법회: 'bub_count',
    정기법회: 'jg_count',
    국내법회: 'gn_count',
    국외법회: 'gw_count',
    일반법회: 'ib_count',
  };

  const handleCateChange = (categoryCode, totalItems) => {
    onCateChange(categoryCode);
    onPageChange(1);
    ontotalChange(totalItems);
    const url = `${location.pathname}?cate=${categoryCode}&page=1`;
    window.history.pushState({ path: url }, '', url);

    // console.log(dataArray);
  };

  const getDataItemCount = (categoryName) => {
    const matchingNumber = totalArray[countMapping[categoryName]][0].count;

    return matchingNumber;
  };

  return (
    <div className='bubsubmenu'>
      <ul>
        <li>
          <button
            className={currentCategory === '' ? 'active' : ''}
            onClick={handleCatereset}
          >
            전체 ({totalArray.total_count}건)
          </button>
        </li>
        {Object.keys(categoryMapping).map((categoryName) => (
          <li key={categoryName}>
            <button
              className={
                currentCategory === categoryMapping[categoryName]
                  ? 'active'
                  : ''
              }
              onClick={(e) =>
                handleCateChange(
                  categoryMapping[categoryName],
                  getDataItemCount(categoryName),
                )
              }
            >
              {categoryName} ({getDataItemCount(categoryName)}건)
            </button>
          </li>
        ))}
        {/* {dataArray.map((dataItem, index) => (
  <li key={index}>
    <button onClick={() => handleCateChange(categoryMapping[dataItem.list[0].category_name || "한마음요전"], dataItem.listcount[0].count)}>
      {dataItem.list[0].category_name || "한마음요전"}
    </button>
  </li>
))} */}
      </ul>
    </div>
  );
};

export default BubsubMenu;

import { useState, useEffect } from 'react';

const SmRegComponent = ({ info, setInfo }) => {
  const [smRegStatus, setSmRegStatus] = useState({
    isSmSCI: 'N',
    isSmSSCI: 'N',
    isSmAHCI: 'N',
    isSmSCOPUS: 'N',
    isSmKCI: 'N',
  });

  // 등재정보 항목
  const regSiteObj = [
    { name: 'isSmSCI', label: 'SCI' },
    { name: 'isSmSSCI', label: 'SSCI' },
    { name: 'isSmAHCI', label: 'AHCI' },
    { name: 'isSmSCOPUS', label: 'SCOPUS' },
    { name: 'isSmKCI', label: 'KCI' },
  ];

  // 발행번호 항목
  const pubNoObj = [
    { name: 'SM_ISBN', label: 'ISBN' },
    { name: 'SM_ISSN', label: 'ISSN' },
  ];

  useEffect(() => {
    setSmRegStatus({
      isSmSCI: info.SM_SCI !== 'N' ? 'Y' : 'N',
      isSmSSCI: info.SM_SSCI !== 'N' ? 'Y' : 'N',
      isSmAHCI: info.SM_AHCI !== 'N' ? 'Y' : 'N',
      isSmSCOPUS: info.SM_SCOPUS !== 'N' ? 'Y' : 'N',
      isSmKCI: info.SM_KCI !== 'N' ? 'Y' : 'N',
    });
  }, [info]);

  return (
    <div className='form-inner'>
      <div className='data-box'>
        <label>등재정보</label>
        <div className='check-list-wrap'>
          {/* 등재정보 항목을 map 함수를 이용하여 반복 출력 */}
          {regSiteObj.map((item, index) => (
            <label key={index} className='item-box'>
              <input
                type='checkbox'
                checked={smRegStatus[item.name] === 'Y'}
                onChange={(e) => {
                  setSmRegStatus({
                    ...smRegStatus,
                    [item.name]: e.target.checked ? 'Y' : 'N',
                  });
                  setInfo({
                    ...info,
                    ['SM_' + item.label]: e.target.checked ? 'Y' : 'N',
                  });
                }}
              />
              <span className='item-title'>{item.label}</span>
            </label>
          ))}
          {/* 등재정보 반복 출력 끝 */}
          {/* 등재정보 항목 체크 여부에 따라 url 입력받는 창 추가 */}
          {regSiteObj.map((item, index) => (
            <div
              key={index}
              className={
                smRegStatus[item.name] === 'Y'
                  ? 'data-box'
                  : 'input-none data-box'
              }
            >
              <input
                type='text'
                className='data-in-box'
                value={
                  info['SM_' + item.label] === 'Y'
                    ? ''
                    : info['SM_' + item.label]
                }
                onChange={(e) => {
                  setInfo({
                    ...info,
                    ['SM_' + item.label]: e.target.value,
                  });
                }}
                placeholder={item.label + ' -  등재지 URL 주소를 입력해주세요.'}
              />
            </div>
          ))}
          {/* 등재정보 항목 체크 여부에 따라 url 입력받는 창 추가 끝 */}
          <div className='division'>
            {/* 발행번호 항목을 map 함수를 이용하여 반복 출력 */}
            {/* {pubNoObj.map((item, index) => (
              <div key={index} className='data-box'>
                <label>{item.label}</label>
                <input
                  type='text'
                  className='data-in-box'
                  value={info[item.name]}
                  onChange={(e) => {
                    setInfo({
                      ...info,
                      [item.name]: e.target.value,
                    });
                  }}
                />
              </div>
            ))} */}
            {/* 발행번호 반복 출력 끝 */}
          </div>
        </div>
      </div>
      {/* 등재정보 항목 체크 여부에 따라 url 입력받는 창 추가 끝 */}
      <div className='division' style={{ marginTop: '15px' }}>
        {/* 발행번호 항목을 map 함수를 이용하여 반복 출력 */}
        {pubNoObj.map((item, index) => (
          <div key={index} className='data-box'>
            <label>{item.label}</label>
            <input
              type='text'
              className='data-in-box'
              value={info[item.name]}
              onChange={(e) => {
                setInfo({
                  ...info,
                  [item.name]: e.target.value,
                });
              }}
            />
          </div>
        ))}
        {/* 발행번호 반복 출력 끝 */}
      </div>
    </div>
  );
};

export default SmRegComponent;

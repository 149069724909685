import SelectChapter from './yj/SelectChapter';
import SelectEpisode from './yj/SelectEpisode';
import SelectSubtitle from './yj/SelectSubtitle';
import InputSubtitle from './yj/InputSubtitle';
import InputParagraph from './yj/InputParagraph';

const searchFieldSubmit = (queryProps, props) => {
  let payload = '';

  payload += `?section=${encodeURIComponent(queryProps.searchQuery.section)}`;
  payload += `&page=${queryProps.searchQuery.page}`;
  if (props.searchInput.episode.value !== null) {
    payload += `&episode_id=${props.searchInput.episode.value.value}`;
    payload += `&episode_label=${encodeURIComponent(
      props.searchInput.episode.value.label,
    )}`;
  } else {
    alert('편을 선택해주세요.');
    return false;
  }

  if (props.searchInput.chapter.value !== null) {
    payload += `&chapter_id=${props.searchInput.chapter.value.value}`;
    payload += `&chapter_label=${encodeURIComponent(
      props.searchInput.chapter.value.label,
    )}`;
  }

  // 소제목 입력여부 확인
  if (
    props.searchInput.subtitle.inputType === 'select' &&
    props.searchInput.subtitle.value !== null
  ) {
    payload += `&subtitle_id=${props.searchInput.subtitle.value.value}`;
    payload += `&subtitle_label=${encodeURIComponent(
      props.searchInput.subtitle.value.label,
    )}`;
  } else if (
    props.searchInput.subtitle.inputType === 'text' &&
    props.searchInput.subtitle.value !== ''
  ) {
    payload += `&search=${encodeURIComponent(
      props.searchInput.subtitle.value,
    )}`;
  } else if (props.searchInput.subtitle.value === '') {
    alert('소제목을 입력해주세요.');
    return false;
  }

  if (props.searchInput.para) {
    if (props.searchInput.para.value !== '') {
      payload += `&search=${encodeURIComponent(props.searchInput.para.value)}`;
    } else {
      alert('단락을 입력해주세요.');
      return false;
    }
  }

  window.location.search = `${payload}`;
};

// 렌더링할 검색 컴포넌트
const YjSearchComponent = ({ queryProps, props, onReset }) => {
  const returnElement = [];

  Object.entries(props.searchInput).forEach(([key, value]) => {
    if (key === 'episode') {
      returnElement.push(<SelectEpisode key='episode' props={props} />);
    } else if (key === 'chapter') {
      returnElement.push(<SelectChapter key='chapter' props={props} />);
    } else if (key === 'subtitle') {
      if (value.inputType === 'text') {
        returnElement.push(<InputSubtitle key='subtitle' props={props} />);
      } else if (value.inputType === 'select') {
        returnElement.push(<SelectSubtitle key='subtitle' props={props} />);
      }
    } else if (key === 'para') {
      returnElement.push(<InputParagraph key='para' props={props} />);
    }
  });

  const reset = () => {
    onReset();
  };

  return (
    <div id='list' className='list-search'>
      <h2>검색</h2>
      {returnElement}
      <div className='search-submit'>
        <button
          type='button'
          onClick={() => searchFieldSubmit(queryProps, props)}
          className='submit-btns list_sbtn'
        >
          검색하기
        </button>
        <button className='submit-btns list_rbtn' onClick={reset}>
          초기화
        </button>
      </div>
    </div>
  );
};

export default YjSearchComponent;

const SmFileComponent = ({ fileState, fileDispatch }) => {
  // const [uploadProgress, setUploadProgress] = useState(0);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    fileDispatch({ type: "SET_FILE", smFile: selectedFile });
    fileDispatch({ type: "SET_FILENAME", smFileName: selectedFile.name });
  };
  return (
    <>
      <div className="form-inner">
        <div className="data-box file">
          <label htmlFor="input-file" className="input-file-button">
            파일선택
          </label>
          <input
            type="file"
            id="input-file"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
          <span className="file-name">
            {fileState.smFileName
              ? `${fileState.smFileName}`
              : "첨부된 파일이 없습니다."}
          </span>
        </div>
      </div>
    </>
  );
};

export default SmFileComponent;

import React from 'react';
import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';

const DetailSearch = ({ openSearch, closeSearch, onSearch }) => {
  const [uniqueEpisodes, setUniqueEpisodes] = useState([]);
  const [activeEpisode, setActiveEpisode] = useState(uniqueEpisodes[0]);

  const [uniqueTitles, setUniqueTitles] = useState([]);
  const [activeTitle, setActiveTitle] = useState(uniqueTitles[0]);

  const ulRef = useRef(null);
  const [selectedCategory, setSelectedCategory] = useState('20000');
  const [apiData, setApiData] = useState(null);
  const [subData, setSubData] = useState(null);
  const [HggData, setHggData] = useState(null);

  const [searchValue, setSearchValue] = useState('');

  const location = useLocation();
  const [checkLocation, setCheckLocation] = useState(false);

  useEffect(() => {
    const regex = /^\/search/;

    const match = location.pathname.match(regex);
    if (match) {
      setCheckLocation(true);
    } else {
      setCheckLocation(false);
    }
  }, [location]);

  const fetchApiData = async () => {
    if (selectedCategory) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/bub/search/detail/modal?cate=${selectedCategory}`,
        );
        const dataArray = response.data;
        setApiData(dataArray.yj_chapter_list); // Store the API response data in state
        setSubData(dataArray.yj_subtitle_list);
        setHggData(dataArray.hgg_detail_list); // Store the API response data in state
      } catch (error) {
        console.error('Error fetching API data:', error);
      }
    }
  };

  useEffect(() => {
    fetchApiData(); // Fetch API data when selectedCategory changes
  }, [selectedCategory]);

  useEffect(() => {
    if (apiData && subData) {
      const combinedData = apiData.concat(subData);
      const episodes = [...new Set(combinedData.map((item) => item.episode))];
      setUniqueEpisodes(episodes);
    }
  }, [apiData, subData]);

  useEffect(() => {
    if (HggData) {
      const combinedData = HggData;
      const titles = [...new Set(combinedData.map((item) => item.title_vol))];
      setUniqueTitles(titles);
    }
  }, [HggData]);

  const scrollToEpisode = (episode) => {
    const episodeHeading = document.getElementById(`episode-${episode}`);
    if (episodeHeading) {
      episodeHeading.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToTitle = (title) => {
    const titleHeading = document.getElementById(`title-${title}`);
    if (titleHeading) {
      titleHeading.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (activeEpisode) {
      scrollToEpisode(activeEpisode);
    }
  }, [activeEpisode]);

  useEffect(() => {
    if (uniqueEpisodes.length > 0) {
      setActiveEpisode(uniqueEpisodes[0]);
    }
  }, [uniqueEpisodes]);

  useEffect(() => {
    if (activeTitle) {
      scrollToTitle(activeTitle);
    }
  }, [activeTitle]);

  useEffect(() => {
    if (uniqueTitles.length > 0) {
      setActiveTitle(uniqueTitles[0]);
    }
  }, [uniqueTitles]);

  const ulElement = ulRef.current;
  let h3Elements = [];
  if (apiData && subData) {
    h3Elements = Array.from(document.querySelectorAll('h3[id^="episode-"]'));
  } else {
    h3Elements = Array.from(document.querySelectorAll('h3[id^="title-"]'));
  }

  if (ulElement) {
    ulElement.addEventListener('scroll', () => {
      const { scrollTop, scrollHeight, clientHeight } = ulElement;
      if (scrollTop === 0) {
        setActiveEpisode(uniqueEpisodes[0]);
        setActiveTitle(uniqueTitles[0]);
      } else if (Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
        setActiveEpisode(uniqueEpisodes[uniqueEpisodes.length - 1]);
        setActiveTitle(uniqueTitles[uniqueTitles.length - 1]);
      }
    });
  }

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
    scrollToEpisode();
    scrollToTitle();
  };

  const categoryMapping = {
    한마음요전: '20000',
    법형제법회: '10001',
    정기법회: '10002',
    국내법회: '10003',
    국외법회: '10004',
    일반법회: '10005',
  };

  if (!openSearch) return null;

  const handleSearch = (event) => {
    event.preventDefault();

    onSearch({
      cateData: selectedCategory,
      searchValue: searchValue,
    });

    closeSearch();
  };

  // 선택한 카테고리 값에 해당하는 이름을 찾아서 출력
  const titleName = Object.keys(categoryMapping).find(
    (key) => categoryMapping[key] === selectedCategory,
  );

  return (
    <>
      <div className='modal max-modal-size'>
        <div className='detail_view__content'>
          <div className='detail_view__content__header'>
            <div className='search-select-box'>
              <select onChange={handleCategoryChange} value={selectedCategory}>
                {Object.keys(categoryMapping).map((categoryName) => (
                  <option
                    key={categoryName}
                    value={categoryMapping[categoryName]}
                  >
                    {' '}
                    {categoryName}{' '}
                  </option>
                ))}
              </select>
            </div>
            <span className='comment-text'>※ 아래 항목을 선택하세요.</span>
            <button className='close-btn' onClick={closeSearch}>
              X
            </button>
          </div>
          <div className='detail_view__content__body'>
            {HggData ? (
              <ul className='active-list'>
                {uniqueTitles.map((title, index) => (
                  <li key={index}>
                    <button
                      onClick={() => {
                        setActiveTitle(title);
                      }}
                      className={activeTitle === title ? 'active' : ''}
                    >
                      {titleName} {title}
                    </button>
                  </li>
                ))}
              </ul>
            ) : (
              apiData &&
              subData && (
                <ul className='active-list'>
                  {uniqueEpisodes.map((episode, index) => (
                    <li key={index}>
                      <button
                        onClick={() => {
                          setActiveEpisode(episode);
                        }}
                        className={activeEpisode === episode ? 'active' : ''}
                      >
                        {episode}
                      </button>
                    </li>
                  ))}
                </ul>
              )
            )}

            <form onSubmit={handleSearch}>
              {HggData ? (
                <ul className='scrollable-list' ref={ulRef}>
                  {HggData.map((item, index, array) => (
                    <React.Fragment key={index}>
                      {index === 0 ||
                      array[index - 1].title_vol !== item.title_vol ? (
                        <li
                          className='item-label'
                          id={`title-${item.title_vol}`}
                        >
                          {titleName} {item.title_vol}
                        </li>
                      ) : null}
                      <li key={index}>
                        <label htmlFor={item.id}>
                          <input
                            type='radio'
                            name='hggdata'
                            id={item.id}
                            value={`title_id=${item.id}`}
                            onChange={(e) => {
                              setSearchValue(e.target.value);
                              console.log(e.target.value);
                            }}
                            checked={searchValue === `title_id=${item.id}`}
                          />
                          <span>
                            <b>
                              {item.category_shortName} {item.title_vol}:
                              {item.title_seq}
                            </b>{' '}
                            {item.title}
                          </span>
                        </label>
                      </li>
                    </React.Fragment>
                  ))}
                </ul>
              ) : (
                apiData &&
                subData && (
                  <ul className='scrollable-list han' ref={ulRef}>
                    {apiData.concat(subData).map((item, index, array) => (
                      <React.Fragment key={index}>
                        {index === 0 ||
                        array[index - 1].episode !== item.episode ? (
                          <li
                            className='item-label'
                            id={`episode-${item.episode}`}
                          >
                            {item.episode}
                          </li>
                        ) : null}
                        <li>
                          <label htmlFor={item.id}>
                            <input
                              type='radio'
                              name={`episode`}
                              id={item.id}
                              value={
                                item.chapter
                                  ? `chapter_id=${item.id}`
                                  : `subtitle_id=${item.id}`
                              }
                              onChange={(e) => {
                                setSearchValue(e.target.value);
                              }}
                              checked={
                                searchValue ===
                                (item.chapter
                                  ? `chapter_id=${item.id}`
                                  : `subtitle_id=${item.id}`)
                              }
                            />
                            <span>
                              {item.chapter ? item.chapter : item.subtitle}
                            </span>
                          </label>
                        </li>
                      </React.Fragment>
                    ))}
                  </ul>
                )
              )}
              <div className='box-wrap'>
                <a onClick={closeSearch} className='btns wh-border'>
                  취소하기
                </a>
                <button type='submit' className='btns search-orange'>
                  <span>검색하기</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        className={`${
          checkLocation ? 'detail_view__overlay_search' : 'detail_view__overlay'
        }`}
        onClick={closeSearch}
      ></div>
    </>
  );
};

export default DetailSearch;

import { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

const SmDetailView = ({ item, openDetail, closeDetail }) => {
  const [smData, setSMdata] = useState(null);
  // const [conferenceData, setConferenceData] = useState(null);
  // const [journalData, setJournalData] = useState(null);
  // const [thesisData, setThesisData] = useState(null);

  const [getData, setGetData] = useState({});
  const [cateName, setCateName] = useState('');

  const location = useLocation();
  const [checkLocation, setCheckLocation] = useState(false);

  useEffect(() => {
    const regex = /^\/search/;

    const match = location.pathname.match(regex);
    if (match) {
      setCheckLocation(true);
    } else {
      setCheckLocation(false);
    }
  }, [location]);

  useEffect(() => {
    if (item?.SM_Books_Id) {
      let books_id = item.SM_Books_Id;

      fetch(`${process.env.REACT_APP_SERVER_URL}/sm/books/${books_id}`)
        .then((response) => response.json())
        .then((data) => {
          setSMdata(data.books[0]);
        })
        .catch((error) => {
          console.log('등록 실패:', error);
        });
    } else if (item?.SM_Conference_Id) {
      let conference_id = item.SM_Conference_Id;

      fetch(
        `${process.env.REACT_APP_SERVER_URL}/sm/conference/${conference_id}`,
      )
        .then((response) => response.json())
        .then((data) => {
          setSMdata(data.conference[0]);
        })
        .catch((error) => {
          console.log('등록 실패:', error);
        });
    } else if (item?.SM_Journal_Id) {
      let journal_id = item.SM_Journal_Id;

      fetch(`${process.env.REACT_APP_SERVER_URL}/sm/journal/${journal_id}`)
        .then((response) => response.json())
        .then((data) => {
          setSMdata(data.journal[0]);
        })
        .catch((error) => {
          console.log('등록 실패:', error);
        });
    } else if (item?.SM_Thesis_Id) {
      let thesis_id = item.SM_Thesis_Id;

      fetch(`${process.env.REACT_APP_SERVER_URL}/sm/thesis/${thesis_id}`)
        .then((response) => response.json())
        .then((data) => {
          setSMdata(data.thesis[0]);
        })
        .catch((error) => {
          console.log('등록 실패:', error);
        });
    }
  }, [item]);

  console.log(smData);

  useEffect(() => {
    setGetData(() => {
      if (smData) {
        return {
          ...smData,
          Tags: smData.Tags ? smData.Tags.split(',') : [],
        };
      } else {
        return {}; // or whatever default value you want when smData is null or undefined
      }
    });
  }, [smData]);

  useEffect(() => {
    if (smData) {
      setCateName(smData.SM_Cate_Name);
    }
  }, [smData]);

  const handleDownload = (e) => {
    e.preventDefault();

    axios
      .get(
        `${process.env.REACT_APP_SERVER_URL}/sm/download/${smData.SM_Document_Id}`,
        {
          responseType: 'json',
        },
      )
      .then((response) => {
        const { fileData } = response.data;
        const dataUrl = 'data:application/pdf;base64,' + fileData;
        const a = document.createElement('a');
        a.href = dataUrl;
        a.download = getData.SM_Attachment_Source;
        a.click();
      })
      .catch((error) => {
        console.error('파일 다운로드 중 오류 발생:', error);
      });
  };

  const handleCopy = (event) => {
    const copiedText = window.getSelection().toString();
    const sourceInfo = `『${smData.SM_Title}』 ${smData.SM_Author}(${smData.SM_Publication_YY})`;

    // Combine the copied text and source information
    const textWithSource = `${copiedText}\n\n${sourceInfo}`;

    // Write the combined text to the clipboard
    event.clipboardData.setData('text/plain', textWithSource);

    // Prevent the normal copy behavior
    event.preventDefault();
  };

  if (!openDetail) return null;

  return (
    <div className='modal'>
      <div className='detail_view'>
        <div className='detail_view__content detail'>
          <div className='detail_view__content__header fordetail-header'>
            <h3 className='title-name'>{smData.SM_Title}</h3>
            <ul className='header-info-list'>
              <li className='day'>
                <span>발행년월</span>
                <span>
                  {smData.SM_Publication_YY}.{smData.SM_Publication_MM}
                </span>
              </li>
              <li className='day'>
                <span>저자</span>
                <span>{smData.SM_Author}</span>
              </li>
            </ul>
            {smData.SM_Cate_Name === '단행본' ? (
              <ul className='header-info-list'>
                <li className='day'>
                  <span>출판사명</span>
                  <span>{smData.SM_Books_Publish}</span>
                </li>
                <li className='day'>
                  <span>단행본명</span>
                  <span>{smData.SM_Books_Name}</span>
                </li>
              </ul>
            ) : smData.SM_Cate_Name === '학술대회자료집' ? (
              <ul className='header-info-list'>
                <li className='day'>
                  <span>학회</span>
                  <span>{smData.SM_Conference_Name}</span>
                </li>
                <li className='day'>
                  <span>장소</span>
                  <span>{smData.SM_Conference_Location}</span>
                </li>
                <li className='day'>
                  <span>개최일자</span>
                  <span>{smData.SM_Conference_Date}</span>
                </li>
              </ul>
            ) : smData.SM_Cate_Name === '학술지' ? (
              <ul className='header-info-list'>
                <li className='day'>
                  <span>학술지명</span>
                  <span>{smData.SM_Journal_Name}</span>
                </li>
                <li className='day'>
                  <span>권</span>
                  <span>{smData.SM_Journal_Volume}</span>
                </li>
                <li className='day'>
                  <span>호</span>
                  <span>{smData.SM_Journal_Issue}</span>
                </li>
                <li className='day'>
                  <span>연구기관</span>
                  <span>{smData.SM_Journal_Research_Institution}</span>
                </li>
              </ul>
            ) : smData.SM_Cate_Name === '학위논문' ? (
              <ul className='header-info-list'>
                <li className='day'>
                  <span>학위</span>
                  <span>{smData.SM_Thesis_Degree}</span>
                </li>
                <li className='day'>
                  <span>대학</span>
                  <span>{smData.SM_Thesis_University}</span>
                </li>
                <li className='day'>
                  <span>링크</span>
                  <span>
                    <a className='LinkIcon' href={`//${smData.SM_1}`} target='_blank'>
                      바로가기
                    </a>
                  </span>
                </li>
              </ul>
            ) : null}
            <button onClick={closeDetail} className='close-btn'>
              X
            </button>
          </div>
          <div className='detail_view__content__body fordetail-body'>
            <ul className='detail-text' onCopy={handleCopy}>
              <li>
                <p>{smData.SM_Abstract}</p>
              </li>
              <li className='label-content-wrap'>
                <span className='label'>주제어</span>
                <div className='in-data tags'>
                  {smData.Tags && Array.isArray(getData.Tags)
                    ? getData.Tags.map((item, index) => (
                        <span key={index} className='tag'>
                          {item}
                        </span>
                      ))
                    : null}
                </div>
              </li>
              {smData.SM_SCI || smData.SM_SSCI || smData.SM_AHCI || smData.SM_SCOPUS || smData.SM_KCI ? (
              <li className='label-content-wrap'>
                <span className='label'>등재정보</span>
                <div className='in-data links'>
                  {smData.SM_SCI !== '' ? (
                    <a
                      href={`//${smData.SM_SCI}`}
                      target='_blank'
                      className='sm-detail-btn'
                    >
                      <span>SCI</span>
                    </a>
                  ) : null}
                  {smData.SM_SSCI !== '' ? (
                    <a
                      href={`//${smData.SM_SSCI}`}
                      target='_blank'
                      className='sm-detail-btn'
                    >
                      <span>SSCI</span>
                    </a>
                  ) : null}
                  {smData.SM_AHCI !== '' ? (
                    <a
                      href={`//${smData.SM_AHCI}`}
                      target='_blank'
                      className='sm-detail-btn'
                    >
                      <span>AHCI</span>
                    </a>
                  ) : null}
                  {smData.SM_SCOPUS !== '' ? (
                    <a
                      href={`//${smData.SM_SCOPUS}`}
                      target='_blank'
                      className='sm-detail-btn'
                    >
                      <span>SCOPUS</span>
                    </a>
                  ) : null}
                  {smData.SM_KCI !== '' ? (
                    <a
                      href={`//${smData.SM_KCI}`}
                      target='_blank'
                      className='sm-detail-btn'
                    >
                      <span>KCI</span>
                    </a>
                  ) : null}
                </div>
              </li>
              ) : null}
              {smData.SM_Attachment_Source ? (
                <>
                  <li className='label-content-wrap'>
                    <span className='label preview'>미리보기</span>
                    <div className='in-data pdf' onClick={handleDownload}>
                      {smData?.SM_Attachment_Source}
                    </div>
                  </li>
                </>
              ) : null}
            </ul>
          </div>
        </div>
      </div>
      <div
        className={`${
          checkLocation ? 'detail_view__overlay_search' : 'detail_view__overlay'
        }`}
        onClick={closeDetail}
      ></div>
    </div>
  );
};

export default SmDetailView;

// Loading.js
import React from "react";
import Spinner from '../../imgs/icon/spinner.gif'

export default () => {
    return (
        <div className="loading">
            <p>잠시만 기다려주세요.</p>
            <img src={Spinner} alt="로딩중" width="5%"></img>
        </div>
    )
}